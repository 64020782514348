<!---Contanct Form-->
<div class="modal fade contact-form" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><span>Thanks for getting in touch with us!</span></h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="contactForm" id="Contact_form" (submit)="submitForm()">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" formControlName="Name" id="name" autocomplete="off" name="Name" placeholder="Name">
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="email" class="form-control" id="email" formControlName="Email" name="Email" autocomplete="off" placeholder="Email(*)" required>
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input type="tel" class="form-control" id="phone" formControlName="Phone" name="Phone" autocomplete="off" placeholder="Phone">
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input type="url" class="form-control" id="name" formControlName="Website" name="Website" autocomplete="off" placeholder="Website">
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <textarea class="form-control" formControlName="Message" name="Message" id="message" placeholder="Message(*)" rows="4" value="I am interested in..." required></textarea>
                <div class="help-block with-errors"></div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="submit" class="btn btn-common popup_btn">Submit</button>
            <button type="button" class="btn btn-secondary popup_btn" data-dismiss="modal">Cancel</button>
          </div>
        </form>

      </div>

    </div>
  </div>
</div>
