import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
declare const loadAllData:any;
@Component({
  selector: 'app-requestformcontact',
  templateUrl: './requestformcontact.component.html',
  styleUrls: ['./requestformcontact.component.css']
})
export class RequestformcontactComponent implements OnInit {
  @ViewChild('myDOMElement', { static: true }) MyDOMElement: ElementRef;
  @ViewChild('otpElement', { static: true }) otpElement: ElementRef;
  constructor() { }

  ngOnInit(): void {
    loadAllData();
  }

  DataSubmit(){
    //var oForm = document.getElementById("infosecenforcer_form");
    event.preventDefault();
    var oForm  = this.MyDOMElement.nativeElement;
    this.sendData(oForm);
  }

  sendData(data) {
    var customerUUID = '9b2640a6-0618-4d6e-8a97-d40b7b179c98',
    hostUrl = "https://app.infosecenforcer.com/infosec",
    enablesOTP =  false,
    sendOTPUrl = hostUrl + '/consumer/request/otp/' + customerUUID,
    verifyOTPUrl = hostUrl + '/consumer/request/otp/verify/' + customerUUID;

    var XHR = new XMLHttpRequest();
    var FD = new FormData();
    var userId = this.randomString(30, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    var cookieId = this.randomString(30, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

    var dsarRequest = { "dsarDetails" : {
      "userId": userId,
      "cookieId": cookieId,
      "requestType": 'DO_NOT_SELL',
      "firstName": data["firstName"].value,
      "lastName": data["lastName"].value,
      "email": data["email"].value,
      "requesterType": data["requesterType"].value,
      "address": data["addressStreet"].value + "::" + data["addressLine2"].value + "::" + data["addressCity"].value + "::" + data["addressState"].value + "::" + data["addressPostal"].value,
      "phoneNumber": data["phoneNumber1"].value + data["phoneNumber2"].value + data["phoneNumber3"].value,
      "note": data["infosec_note"].value
  },
  'customerUUID': customerUUID
};

 // Define what happens on successful data submission
 XHR.addEventListener('load', function (event) {
    
  if (XHR.readyState === 4) {
    var msg =  JSON.parse(this.response);
    if(msg.success==true) {
          document.getElementById('success_message').style.display = "block";
          document.getElementById('error_message').style.display = "none";
          document.getElementById('form_container').style.display = "none";
      } else {
          document.getElementById('error_message').innerHTML = msg.message;
          document.getElementById('success_message').style.display = "none";
          document.getElementById('error_message').style.display = "block";
          document.getElementById('form_container').style.display = "block";
      }
  }

//     if(event.target.readyState == 4) {
//         var msg =  JSON.parse(event.target.response);
//         if(msg.success) {
//             document.getElementById('success_message').style.display = "block";
//             document.getElementById('error_message').style.display = "none";
//             document.getElementById('form_container').style.display = "none";
//         } else {
//             document.getElementById('error_message').innerHTML = msg.message;
//             document.getElementById('success_message').style.display = "none";
//             document.getElementById('error_message').style.display = "block";
//             document.getElementById('form_container').style.display = "block";
//         }
//     }
});

// Define what happens in case of error
XHR.addEventListener('error', function (event) {
  alert('Failed to submit the request');
});


  // Set up our request
  XHR.open('POST', hostUrl + '/consumer/request/save');
  XHR.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  // Send our FormData object; HTTP headers are set automatically
  XHR.send(JSON.stringify(dsarRequest));

  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

showForm() {
  document.getElementById('request-form-ul').style.display = "block";
  this.getOTPStatus();
}


getOTPStatus() {
  var customerUUID = '9b2640a6-0618-4d6e-8a97-d40b7b179c98',
    hostUrl = "https://app.infosecenforcer.com/infosec",
    enablesOTP =  false,
    sendOTPUrl = hostUrl + '/consumer/request/otp/' + customerUUID,
    verifyOTPUrl = hostUrl + '/consumer/request/otp/verify/' + customerUUID;
  var XHR = new XMLHttpRequest();
  XHR.addEventListener('load', function (event) {
      try {
          enablesOTP = JSON.parse(this.response.success);
          if(enablesOTP) {
              document.getElementById('li_10').style.display = "block";
              document.getElementById('submitbuttonid').style.display = "none";

          } else {
              document.getElementById('submitbuttonid').style.display = "block";
          }
      } catch (e) {
      }

  });

  // Define what happens in case of error
  XHR.addEventListener('error', function (event) {
      //alert('Failed to save the request');
  });

  // Set up our request
  XHR.open('GET', hostUrl + '/consumer/request/otp/status/' + customerUUID);
  XHR.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  // Send our FormData object; HTTP headers are set automatically
  XHR.send();
}

sendtOTP(isVerify, method) {

  var customerUUID = '9b2640a6-0618-4d6e-8a97-d40b7b179c98',
  hostUrl = "https://app.infosecenforcer.com/infosec",
  enablesOTP =  false,
  sendOTPUrl = hostUrl + '/consumer/request/otp/' + customerUUID,
  verifyOTPUrl = hostUrl + '/consumer/request/otp/verify/' + customerUUID;

  if(!enablesOTP) {
      return;
  }
  var oForm = document.getElementById("infosecenforcer_form"),
      data = this.MyDOMElement.nativeElement,
      phoneNumber = data["phoneNumber1"].value + data["phoneNumber2"].value + data["phoneNumber3"].value,
      url = sendOTPUrl,
      otpcode = data["otpcode"].value;

  if(isVerify) {
      url = verifyOTPUrl;
  }

  var XHR = new XMLHttpRequest();
  var elem = this.otpElement.nativeElement;
  XHR.addEventListener('load', function (event) {
      try {
          var res = JSON.parse(this.response);
        
          document.getElementById("otpcodelabel").innerHTML = res.message;
          if(res.success) {
              document.getElementById("otpcodelabel").style.color='green';
              if(isVerify) {
                elem["otpcode"].disabled = true;
                elem["otpcode"].required = true;
                  document.getElementById('submitbuttonid').style.display = "block";
              }
          } else {
              document.getElementById("otpcodelabel").style.color='red';
          }

          return;
      } catch (e) {
      }

  });

  // Define what happens in case of error
  XHR.addEventListener('error', function (event) {
      document.getElementById("otpcodelabel").innerHTML = "Failed to process OTP request. Please click 'Send Verification Code' to send new code";
      document.getElementById("otpcodelabel").style.color='red';
  });

  // Set up our request
  XHR.open(method, url + "?phoneNumber=" + phoneNumber + "&otocode=" + otpcode );
  XHR.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  // Send our FormData object; HTTP headers are set automatically
  XHR.send();
}

}
