import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-testimonialslider',
  templateUrl: './testimonialslider.component.html',
  styleUrls: ['./testimonialslider.component.css']
})
export class TestimonialsliderComponent implements OnInit {
  testimonial:any = [];
  constructor() { }

  ngOnInit(): void {
    this.testimonial = [
      {
        id:'T-1',
        Name:'Anoop Tewari',
        Designation:' Co-founder & CTO, OnRule Inc.',
        para:`“Perfect blend of infrastructure solutions, support, and cost. Opsara solutions are exactly what businesses need for running their global operations on a highly available cloud infrastructure.”`,
        src:'../assets/img/testimonial/OnRule-Logo.png',
        person: '../assets/img/testimonial/Anoop Tewari.jpg'
    }
    ,{
      id:'T-2',
      Name:'Burton Hipp',
      Designation:'Co-Founder & VP of Engineering, Griddable.io',
      para:`“At Griddable, we built the next generation of technology to synchronize data across clouds.

Opsara was a core partner in building and managing our complex Hyper Converged Infrastructure platform. Also their help during the Salesforce acquisition due diligence was invaluable.”`,
      src:'../assets/img/testimonial/salesforce-sm.png',
      person: '../assets/img/testimonial/Burton Hipp.jpg'
  }
  , {
    id:'T-3',
    Name:'Amitava Mukherjee',
    Designation:'CEO & Co-Founder, Redshift Networks',
    para:`“Our operation is cross-continental. Our technology requirements are complex and ever changing. Plus, the contrasting time zones means someone has to stay alert and active 24x7x365. We found a trusted partner in Opsara.”`,
    src:'../assets/img/testimonial/c-l-1.png',
    person: '../assets/img/testimonial/Amitava Mukherjee.jpg'
}
// ,{
//   id:'T-4',
//   Name:'Lorem ipsum',
//   Designation:'CEO & Founder',
//   para:`“For an entrepreneur like me, the bottom line is financial health. For the past 4 years,
//   the guys at TaaS have continuously given me awesome solutions to save money on
//   technology requirements.”`,
//   src:'../assets/img/testimonial/c-l-3.png'
// },{
//   id:'T-5',
//   Name:'Lorem ipsum',
//   Designation:'CEO Optima Inc',
//   para:`“TaaS has not only empowered and enhanced our business performance – they have also transformed our organization’s work culture and our relationship with clients.”`,
//   src:'../assets/img/testimonial/c-l-4.png'
// },
// {
// id:'T-6',
// Name:'Lorem ipsum',
// Designation:'CEO & Founder',
// para:`“The TaaS team has helped us grow in an IT infrastructure environment without any technical headache. Their 24x7x365 availability to monitor real-time risks, makes us feel absolutely secure. In them, we have found a 100% reliable tech partner.”`,
// src:'../assets/img/testimonial/c-l-18.png'
// }

];

  }

  contentOptions: OwlOptions = {
    loop:true,
    margin:0,
    nav:false,
    dots: true,
    autoplay:true,
    // autoplayTimeout:5000,
    autoplayTimeout:6000,
    autoplayHoverPause:true,
    responsive:{
        0:{
            items:1
        },
       768:{
            items:1
        },
        1000:{
            items:2
        }
    }
  }

}
