import { Component, OnInit, Input } from '@angular/core';
import * as $ from 'jquery';
import { ResumeData } from '../../../shared/components/mock/mockresume';
import { ActivatedRoute, Router } from '@angular/router';
declare const loadAllData:any;
@Component({
  selector: 'app-jobdetails',
  templateUrl: './jobdetails.component.html',
  styleUrls: ['./jobdetails.component.css']
})
export class JobdetailsComponent implements OnInit {
  
  roleId;
  jobData;
  constructor( private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    loadAllData();

   
    $(window).scrollTop(0); 
    
this.roleId = this.route.snapshot.queryParamMap.get('Id');
this.jobData = ResumeData[this.roleId];

  // console.log(this.jobData);

  }

  

}
