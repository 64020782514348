import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-technologypartnersignup',
  templateUrl: './technologypartnersignup.component.html',
  styleUrls: ['./technologypartnersignup.component.css']
})
export class TechnologypartnersignupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(window).scrollTop(0);
    $(window).on('scroll', function() {
      if ($(window).scrollTop() > 100) {
          $('.scrolling-navbar').addClass('top-nav-collapse');
      } else {
          $('.scrolling-navbar').removeClass('top-nav-collapse');
      }
      $('#loader').fadeOut();

    });

    var offset = 200;
    var duration = 500;
    $(window).scroll(function() {
    if ($(this).scrollTop() > offset) {
      $('.back-to-top').fadeIn(400);
    } else {
      $('.back-to-top').fadeOut(400);
    }
    });
    
    $('.back-to-top').on('click',function(event) {
    event.preventDefault();
    $('html, body').animate({
      scrollTop: 0
    }, 600);
    return false;
    })
  }

}
