<header id="hero-area" class="hero-area" data-stellar-background-ratio="0.5">
  <app-navbar></app-navbar>
  <canvas id="waves" class="pos-fix"></canvas>
</header>

<!-- home page header -->
<div class="section" id="home-header">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 pl-5">
        <br>
        <h3 class="wow fadeInDown pt-5 has-text-blue" data-wow-duration="1000ms" data-wow-delay="0.3s">Hybrid Cloud
          Solutions</h3>
        <p class="lead  wow fadeIn has-text-blue pt-2" data-wow-duration="1000ms" data-wow-delay="400ms">High
          Performance | Secure | Lower Costs</p>
        <button type="button" name="button" class="btn btn-common mt-2" (click)="scrollBelow(vtab);">
          Learn more <i class="fa fa-chevron-right ml-1"></i>
        </button>
        <br> <br>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="touch-slider">
          <div  class="row testimonial-heading">
            <div  class="col-12">
              <h4 >Our Client Testimonials</h4>
            </div>
          </div>
          <app-testimonialslider></app-testimonialslider>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- horizontal tab section -->
<section class="section py-5 header Resources" #vtab>
  <div class="container py-4">
    <div class="row">
      <div class="col-md-4">
        <!-- Tabs nav -->
        <div class="nav flex-column nav-pills nav-pills-custom mt-0" id="v-pills-tab" role="tablist"
          aria-orientation="vertical">
          <a class="nav-link mb-3 p-3 shadow active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home"
            role="tab" aria-controls="v-pills-home" aria-selected="true">
            <h5 class="custom-heading">
              <i class="mdi mdi-database-lock mr-2"></i>
              Storage & Backup solutions - OpScale
            </h5>
            <p>Enterprise Scale Storage Solutions for Hybrid Cloud</p>
          </a>

          <a class="nav-link mb-3 p-3 shadow" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile"
            role="tab" aria-controls="v-pills-profile" aria-selected="false">
            <h5 class="custom-heading">
              <i class="mdi mdi-lan-connect mr-2"></i>
              Edge Compute - Moaxy
            </h5>
            <p>Edge Compute Appliance for Hybrid Cloud</p>
          </a>

          <a class="nav-link mb-3 p-3 shadow" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages"
            role="tab" aria-controls="v-pills-messages" aria-selected="false">
            <h5 class="custom-heading">
              <i class="mdi mdi-cloud-check mr-2"></i>
              Managed Cloud - TaaS
            </h5>
            <p>Managed Cloud Solutions for Startups to Enterprise </p>
          </a>
        </div>
      </div>

      <div class="col-md-8">
        <!-- Tabs content -->
        <div class="tab-content" id="v-pills-tabContent">
          <!-- tab-1 content -->
          <div class="tab-pane fade shadow rounded bg-white show active p-5" id="v-pills-home" role="tabpanel"
            aria-labelledby="v-pills-home-tab">

            <h5 class="has-text-blue custom-heading">OpScale Storage </h5>
            <div class="section-header mb-3">
              <hr class="lines ml-0">
            </div>
            <p class="section-subtitle">
              Petabyte scale hybrid storage solutions at half the cost of Public Cloud Providers and On-Premise
              Appliance providers. OpScale can be deployed as a managed appliance in your data center or in our cloud
              platform.
            </p>
            <p class="section-subtitle">
              OpScale can be deployed as a managed appliance in your data center or in our cloud platform.
            </p>
            <p class="section-subtitle">
              Low cost storage solution for your Content, IoT Data, Surveillance, AI/ ML platforms, Data Lakes and more.
            </p>
            <p class="section-subtitle pb-2"> Find out how much we can save for you.
            </p>
            <div class="text-right">
              <a routerLink="/opscalestorage" fragment="OpScaleStorageCalc" class="btn btn-common mb-1"> Storage Cost
                Calculator</a>
            </div>

            <h5 class="has-text-blue custom-heading pt-4">OpScale Backup & Recovery </h5>
            <div class="section-header mb-3">
              <hr class="lines ml-0">
            </div>
            <p class="section-subtitle pt-2">
              Backup and recovery solutions for your Data Center workloads and more. Our solutions are built using
              Unitrends, a leading provider included in Gartner's Magic Quadrant.
            </p>
            <p class="section-subtitle">
              Our solutions are built using Unitrends, a leading provider included in Gartner's Magic Quadrant.
            </p>
            <p class="section-subtitle">
              Our all-inclusive solution includes On premise appliance, Cloud back up, Management Software and Team to
              get it done for you.
            </p>
            <p class="section-subtitle pb-2">
              Get a quick estimate to power your Business Continuity Plans.
            </p>

            <div class="text-right">
              <a routerLink="/opscalebackup" fragment="OpscaleBackupCalc" class="btn btn-common mb-1"> Backup Cost
                Calculator</a>
            </div>

          </div>

          <!-- tab-2 content -->
          <div class="tab-pane fade shadow rounded bg-white p-5" id="v-pills-profile" role="tabpanel"
            aria-labelledby="v-pills-profile-tab">
            <h5 class="has-text-blue custom-heading">Moaxy - Edge Compute </h5>
            <div class="section-header mb-3">
              <hr class="lines ml-0">
            </div>
            <p class="section-subtitle">
              Edge Compute requirements are getting more complex across all organizations. Processing surveillance
              video, supporting IoT devices at the Edge have already gained traction and accelerating. These are in
              addition to the ongoing need to
              add new locations quickly and securely.
            </p>

            <p class="section-subtitle">
              Data Age 2025, a whitepaper from IDC, provides the following guidance.
              <br>
              <i class="italic-lg">
                “<b>Invest in the Edge.</b> Many times, data has no time to travel from an endpoint to the core and back
                when informing real-time decisions.
                The enterprise edge helps to bridge this gap. Whether taking on data analytics or simply storing
                analyzed and intelligent data, the edge will play an increasing
                role in enabling a real-time world.”
              </i>
            </p>

            <p class="section-subtitle">
              Our Moaxy appliance solution is designed to address these challenges. A custom-built appliance to handle
              your Edge compute, network, and security stack.
            </p>

            <p class="section-subtitle pb-2">
              Find out how we can simplify your Edge infrastructure and personnel challenges.
            </p>

            <div class="text-right">
              <button type="button" name="button" class="btn btn-common mb-1">Learn More</button>
            </div>

          </div>

          <!-- tab-3 content -->
          <div class="tab-pane fade shadow rounded bg-white p-5" id="v-pills-messages" role="tabpanel"
            aria-labelledby="v-pills-messages-tab">
            <h5 class="has-text-blue custom-heading">TaaS - Managed Cloud</h5>
            <div class="section-header mb-3">
              <hr class="lines ml-0">
            </div>
            <p class="section-subtitle">
              Public cloud providers like AWS make it easier for your startup to get off the ground. As your journey
              continues cost-effective scaling, SOC 2, PCI DSS compliance certifications become important
              considerations.
            </p>
            <p class="section-subtitle">
              We provide a range of managed hybrid cloud solutions to address your evolving startup needs. Secure
              network for your remote team, bare metal server hosting, managed hybrid cloud, vulnerability assessments
              and more.
            </p>
            <p class="section-subtitle">
              We also work with established larger enterprises as they shift from a traditional static infrastructure to
              a cloud environment with a dynamic pool of compute capacity.
            </p>
            <p class="section-subtitle">
              An approach which allows for speed and cloud scale optimization for new applications built to engage
              customers and users. While retaining control of their systems of record, like core business databases and
              internal applications, in managed data centers.
            </p>
            <p class="section-subtitle pb-2">
              No matter where you are in the journey, our managed cloud solutions can help.
            </p>
            <div class="text-right">
              <button type="button" name="button" class="btn btn-common mb-1" routerLink="/taas"
                fragment="taas_calc">Cloud Cost Calculator</button>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<!-- Our Services section -->

<div class="section black_c_wrap" id="our-services" #servicesSection>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center mb-4">
        <h5 class="has-text-blue custom-heading">Backed by Security & Operational Services</h5>
        <div class="section-header mb-3">
          <hr class="lines wow zoomIn" data-wow-delay="0.3s">
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-12">
        <div class="serv-section-2">
          <div class="serv-section-2-icon"> <i class="mdi mdi-security-network"></i> </div>
          <div class="serv-section-desc">
            <h4>DNS Security</h4>
            <h4>Service</h4>
          </div>
          <div class="section-heading-line-left"></div>
          <div class="section-content">
            <p class="section-subtitle">
              Reduce exposure to malware by 75%.
            </p>
            <p class="section-subtitle">
              We enforce security at the DNS and IP layers and block requests to malware, ransomware, phishing, and
              botnets before a connection is even established.
            </p>
            <p class="section-subtitle">
              We offer deeper inspection of URLs and files for risky domains powered by Cisco Umbrella.
            </p>
          </div>
          <div class="services-link">
            <a routerLink="/resources" fragment="DNS_Security"> Learn more <i class="mdi mdi-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-12">
        <div class="serv-section-2 serv-section-2-act">
          <div class="serv-section-2-icon serv-section-2-icon-act"> <i class="mdi mdi-fire"
              style="margin-right: -1rem;"></i> <i class="mdi mdi-wall"></i> </div>
          <div class="serv-section-desc">
            <h4>Web Application</h4>
            <h4>Firewall (WAF)</h4>
          </div>
          <div class="section-heading-line-left"></div>
          <div class="section-content">
            <p class="section-subtitle">
              Take control of your application, prevent web scrapping, DDoS attacks and more.
            </p>
            <p class="section-subtitle">
              We work with you to build unique per-app policies. Take complete control over every component and
              fine-tune specific policies for each application’s requirements.
            </p>
            <p class="section-subtitle">
              Our WAF services are powered by Barracuda product suite.
            </p>
          </div>
          <div class="services-link">
            <a routerLink="/resources" fragment="WAF"> Learn more <i class="mdi mdi-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-12">
        <div class="serv-section-2">
          <div class="serv-section-2-icon"> <i class="mdi mdi-shield-check"></i> </div>
          <div class="serv-section-desc">
            <h4>Penetration </h4>
            <h4>Testing</h4>
          </div>
          <div class="section-heading-line-left"></div>
          <div class="section-content">
            <p class="section-subtitle">
              We conduct blackbox and whitebox pen testing to identify your weaknesses, before hackers identify and
              exploit vulnerabilities.
            </p>
            <p class="section-subtitle">
              Our trained and independent team will conduct penetration testing. We can do it at scale over 100's of
              hosts or just a few to get started.
            </p>
            <p class="section-subtitle">
              We use industry renowned Rapid7 product suite to do the testing and share the insights with you.
            </p>
          </div>

        </div>
      </div>
    </div>
    <div class="row mt-60">
      <div class="col-md-4 col-sm-12 col-12">
        <div class="serv-section-2">
          <div class="serv-section-2-icon"> <i class="mdi mdi-bug-check"></i> </div>
          <div class="serv-section-desc">
            <h4>Vulnerability </h4>
            <h4>Assessment</h4>
          </div>
          <div class="section-heading-line-left"></div>
          <div class="section-content">
            <p class="section-subtitle">
              Our vulnerability assessment service ensures your environment is configured securely.
            </p>
            <p class="section-subtitle">
              It integrates with public cloud services as well as VMware servers. It provides a granular risk score to
              prioritize remediation plans.
            </p>
            <p class="section-subtitle">
              Environments with less than 5K endpoints the deployment takes less than a week.
            </p>
            <p class="section-subtitle">
              Our Vulnerability Assessment service is based on Rapid7's industry leading suite of solutions.
            </p>
          </div>

        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-12">
        <div class="serv-section-2 serv-section-2-act">
          <div class="serv-section-2-icon serv-section-2-icon-act"> <i class="mdi mdi-text-box-check-outline"></i>
          </div>
          <div class="serv-section-desc">
            <h4>Compliance </h4>
            <h4>Reporting </h4>
          </div>
          <div class="section-heading-line-left"></div>
          <div class="section-content">
            <p class="section-subtitle">
              We use Insight VM from Rapid 7 to run scans and provide you with compliance reports and vulnerabilities.
            </p>

            <p class="section-subtitle">
              We have templates in place to scan for compliance to HIPAA, PCI and more. It includes all network-based
              vulnerabilities and web application scanning.
            </p>

            <p class="section-subtitle">
              In addition we also can provide end point compliance for SMBs using Rapidfire products.
            </p>

          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-12">
        <div class="serv-section-2">
          <div class="serv-section-2-icon"> <i class="mdi mdi-domain"></i> </div>
          <div class="serv-section-desc">
            <h4> Infrastructure </h4>
            <h4> Monitoring & NoC</h4>
          </div>
          <div class="section-heading-line-left"></div>
          <div class="section-content">
            <p class="section-subtitle">
              We proactively monitor the infrastrucutre for all our private cloud clients, to maximize uptime.
            </p>
            <p class="section-subtitle">
              We use SNMP, IPMI monitoring agents as well as agentless network services checks.
            </p>
            <p class="section-subtitle">
              Our standard monitoring includes network, servers, virtual machines, selected applications & services.
            </p>
            <p class="section-subtitle">
              Our monitoring services are powered by Zabbix as well Kaseya with customizations done by our team.
            </p>
          </div>

        </div>
      </div>

    </div>
    <div class="row mt-60">
      <div class="col-md-4 col-sm-12 col-12">
        <div class="serv-section-2">
          <div class="serv-section-2-icon"> <i class="mdi mdi-cog-transfer"></i> </div>
          <div class="serv-section-desc">
            <h4>Patch </h4>
            <h4> Management </h4>
          </div>
          <div class="section-heading-line-left"></div>
          <div class="section-content">
            <p class="section-subtitle">
              Our private cloud clients can take it easy as we take on the responsibility of managing their servers.
            </p>
            <p class="section-subtitle">
              Optionally we provide addon services to manage client end point devices as well.
            </p>
            <p class="section-subtitle">
              Our services are enabled by Kaseya VSA RMM tool.
            </p>
          </div>

        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-12">
        <div class="serv-section-2 serv-section-2-act">
          <div class="serv-section-2-icon serv-section-2-icon-act"> <i class="mdi mdi-office-building-marker"></i>
          </div>
          <div class="serv-section-desc">
            <h4>Global</h4>
            <h4>PoPs</h4>
          </div>
          <div class="section-heading-line-left"></div>
          <div class="section-content">
            <p class="section-subtitle">
              We extend your reach with our Global PoPs.
            </p>
            <p class="section-subtitle">
              Our current locations include US-West, US-Southwest, US-East, Europe and South East Asia.
            </p>
            <p class="section-subtitle">
              Our PoPs are located in leading Tier-4 data center providers like Equinix and INAP, ensuring high
              availability and state of the art security.
            </p>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>


<app-footer></app-footer>
<!-- contact form new -->
<app-contact-modal></app-contact-modal>

<!---Contanct Form-->
<div class="modal fade contact-form" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><span>Contact Form</span></h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="contactForm" id="Contact_form" (submit)="submitForm()">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" formControlName="Name" id="name" autocomplete="off" name="Name"
                  placeholder="Name" required>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="email" class="form-control" id="email" formControlName="Email" name="Email"
                  autocomplete="off" placeholder="E-mail" required>
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input type="tel" class="form-control" id="phone" formControlName="Phone" name="Phone"
                  autocomplete="off" placeholder="Phone" required>
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input type="url" class="form-control" id="name" formControlName="Website" name="Website"
                  autocomplete="off" placeholder="Website" required>
                <div class="help-block with-errors"></div>website
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <textarea class="form-control" formControlName="Message" name="Message" id="message"
                  placeholder="message" rows="4" required></textarea>
                <div class="help-block with-errors"></div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="submit" class="btn btn-common popup_btn">Submit</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          </div>
        </form>

      </div>

    </div>
  </div>
</div>

<!---DataSheet Form-->
<div class="modal fade contact-form" id="Datasheet_form" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><span>Get your datasheet Now</span></h5>
      </div>
      <div class="modal-body">
        <form id="Contact_form">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="Name" />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="Company Name" />
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="Phone" />
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="Business Email" />
                <div class="help-block with-errors"></div>
              </div>
            </div>

          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-common popup_btn">Submit</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<!---White Papers Form-->
<div class="modal fade contact-form" id="Whitepapers_form" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><span>White Papers</span></h5>
      </div>
      <div class="modal-body">
        <form id="Contact_form">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="First Name">
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="Last Name">
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="Company Name">
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="Email">
                <div class="help-block with-errors"></div>
              </div>
            </div>

          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-common popup_btn">Submit</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<!---Case Studies Form-->
<div class="modal fade contact-form" id="Casestudies_form" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><span>Case Studies</span></h5>
      </div>
      <div class="modal-body">
        <form id="Contact_form">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="First Name">
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="Last Name">
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="Company Name">
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" id="name" name="name" placeholder="Email">
                <div class="help-block with-errors"></div>
              </div>
            </div>

          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-common popup_btn">Submit</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>