<owl-carousel-o [options]="contentOptions">
  <ng-container *ngFor="let item of testimonial">
    <ng-template carouselSlide [id]="item.id">
      <div class="content--slider owl-carousel owl-loaded owl-drag owl-theme ng-star-inserted">
        <div class="item testimonial-item">
          <!-- <div class="testimonial-profile row">
            <div class="col-12">
              <img [src]="item.person" alt="Opsara Client" />
            </div>
            <div class="col-12">
              <h5 class="has-text-blue">{{item.Name}}</h5>
              <span class="text-dark">{{item.Designation}}</span>
            </div>
          </div> -->
          <div class="item testimonial-item row">
            <div class="testimonial-text col-8">
              <p>{{item.para}} </p>
            </div>
            <div class="testimonial-profile row col-4">
              <div class="col-12"> <img [src]="item.person" alt="Opsara Client" /></div>
              <div class="col-12">
                <h5 class="has-text-blue">{{item.Name}}</h5><span class="text-dark">{{item.Designation}}</span>
              </div>
            </div>
          </div>
          <!-- <div class="testimonial-text">
            <p></p>
          </div> -->

        </div>
      </div>
    </ng-template>
  </ng-container>

</owl-carousel-o>