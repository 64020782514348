<owl-carousel-o [options]="customOptions">
   <ng-container *ngFor="let slide of slidesStore">
      <ng-template carouselSlide [id]="slide.id">
         <div class="banner--slider owl-carousel owl-loaded owl-theme">
            <div class="item">
               <div class="banner--item bg--overlay bg--img" [ngStyle]="{'height': '100%','width':'100%',
                  'background-size': 'cover'}" [style.background]="'url(' + slide.src + ')'">
                  <div class="vc--parent">
                     <div class="vc--child">
                        <div class="container">
                           <div class="row">
                              <div class="col-md-6">
                                 <div class="contents text-left  slide-1">
                                    <h1 class="head_txt wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="0.3s"
                                       [innerHTML]="slide.heading"></h1>
                                    <p class="lead  wow fadeIn" data-wow-duration="1000ms" data-wow-delay="400ms"
                                       [innerHTML]='slide.subheading'></p>
                                 </div>
                              </div>

                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>

      </ng-template>
   </ng-container>

</owl-carousel-o>