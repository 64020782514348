import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

declare const loadAllData:any;
declare const loadLineWaves: any;
declare const loadAllslider: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    loadAllData();
    loadLineWaves();
    loadAllslider();

    }
  
     
  	
	
          

}
