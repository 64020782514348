import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-contentslider',
  templateUrl: './contentslider.component.html',
  styleUrls: ['./contentslider.component.css']
})
export class ContentsliderComponent implements OnInit {
  carouselData:any = [];
  constructor() { }

  ngOnInit(): void {

    this.carouselData = [
      {
        id:'tech',
        heading:'Tech IT easy',
        para:`In today’s fast-paced business landscape, every future-focused enterprise, like yours,
        needs to make swift, sure-footed moves without getting tied down by the ever-growing demands of technology. You need a dependable technology partner that can support
        your ambition with agility and accuracy to make technology work for you seamlessly.`,

    },
    {
      id:'Taas_sets',
      heading:'Opsara sets you free',
      para:`As upgrading for the future poses complex challenges for businesses globally, our technology services deliver smart solutions that perfectly suit you. So you can focus
      on your core business without any commitment to invest heavily and constantly
      upgrade your technology infrastructure.`,

  }];
  }


  contentOptions: OwlOptions = {
    loop:true,
    margin:10,
    nav:true,
    dots: false,
    navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
  }


}
