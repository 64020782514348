import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
declare const loadAllData:any;
@Component({
  selector: 'app-offerings',
  templateUrl: './offerings.component.html',
  styleUrls: ['./offerings.component.css']
})
export class OfferingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    loadAllData();
    $(window).scrollTop(0); 
  }

}
