export const ResumeData = {

  1: {

    role: 'Windows Administrator',
    id: '1',
    job_desc: `We are a boutique IT services & solutions firm. Our offering includes custom-configured hardware
      solutions backed by our managed services. We combine best in class DevOps and IT infrastructure
      management practices, so customers can have a secure, reliable platform built to scale with their
      growing requirements at an optimal cost. We provide a global footprint of highly reliable enterprise-
      class data centres in 200+ locations worldwide, so customers can design and deploy your
      infrastructure for productivity and innovation. A few of the solutions managed for our clients include
      Hybrid & Private cloud, cloud storage, back up, disaster recovery, virtual desktop, Data Science
      platform, SDN, Cloud Migration, Infrastructure as a Services, Managed Cloud Services , Application
      Hosting , GPU Servers for AI, Big Data and IoT, Media Streaming , Innovation Labs & NPD
      Infrastructure, eCommerce, Big Data and BI, Security.`,
    rolelist: [{
      point: ["MCSA or MCSE certifications (Windows 2012 or higher)",
        "Must have strong communication skills and the ability to articulate task management, priorities, and issues.",
        "Technologies: Windows Server 2012/2016/2019, Active Directory design and management, Group Policy customization, WSUS / WDS / MDT design, deployment and operations, Hyper-V/VMWare virtualization, VDI / VDA, MS-Exchange Server, Certificate Authority, etc",
        "Proven expertise in powershell scripting and automation",
        "Communicate with peers and managers regarding system issues & participate in 24x7 on-call.",
        "Capable of handling projects & good in customer communications",
        "Proactive self-starter, delivery focused, results orientated, team player, high professional standards and work ethic, strong-willed but flexible, and capable of making independent decisions",
        "Has a passion for innovation and working in a start-up environment with Good communication skills",
      ]
    }],
    jobresponse: [
      "We are looking for game-changers who are confident to use their talent to make a difference.",
      "Proven work experience as a Windows System Administrator.",
      // "Proven expertise in powershell scripting, automation",
      "Strict adherence to the use of the ticketing system for ALL incidents, changes and problems",
      "Ability to correlate incident trends into problem management and execute permanent fixes.",
      "Timely generation of customer facing incident reports",
      "Execute monthly patch management research, reports and implementation on system infrastructure.",
      "Provide lab simulation and interoperability and compatibility testing for new builds",
      "Analyse troubleshooting techniques output, diagnose problems remotely",
      "Must maintain the highest level of industry certification and knowledge.",
      "Ability to architect, build, maintain, and enhance our current managed service offerings and infrastructure."
    ],

    education: ["Degree or master’s in computer science or related discipline"],

    expirence: ["6 – 9 Years"],

    industrytype: ["IT"],

    compensation: ["Best in the Industry"],

    jobloaction: ["India"]

  },
  2: {

    role: 'Cloud Operations Administrator',
    id: '1',
    rolelist: [{
      point: ["Provision Infrastructure as a service cloud computing for platforms such as AWS, Azure or any other cloud environment.",
        "Remote Monitoring & Management along with Server / Router / FW installation, maintenance, monitoring in cloud/IaaS.",
        "Responsible for Linux / Windows server installation, maintenance, monitoring, data backup and recovery, security and administration.",
        "Responsible for managing networked storage (NFS / SMB / iSCSI / Storage GW / Windows Offline)",
        "Disaster Recovery and Incident Response Planning.",
        "Installing, configuring and supporting primarily Linux and Microsoft Windows Operating system based Services on IaaS.",
        "Communicate with peers and managers regarding system issues & participate in 24x7 on-call.",
        "Professional Exposure to DevOps automation scripts. Experience with tools like Git, bash scripting, PowerShell, AWS Cloud Formation, Ansible, Chef or Puppet",
        "Architect / Practitioner certification from OEM with hands-on capabilities",
        "Proactive self-starter, delivery focused, results orientated, team player, high professional standards, and work ethic, strong-willed but flexible, and capable of making independent decisions",
        "Has a passion for innovation and working in a start-up environment with Good communication skills."
      ]
    }],
    jobresponse: [
      "We are looking for game-changers who are confident to use their talent to make a difference.",
      "Experience in administrating cloud platforms like AWS, Azure or any other cloud environment",
      "Experience in installing, configuring and supporting Linux and Microsoft Windows Servers",
      "Experience in implementing, troubleshooting, and supporting the Unix / Linux / Windows operating system, Internet-based applications, and web servers",
      "Experience with Disaster Recovery and Business Continuity",
      "Professional Exposure to container platform tools like OpenShift, Docker, Kubernetes, installing and managing Database, Big Data or NoSQL deployments.",
      "Excellent verbal and written communication skills to be able to interact with technical and business counterparts.",
      "Being available for working outside of normal business hours, holidays, and some weekends when needed for on-call periods.",
      "Working knowledge of ITIL and/or agile methodologies, configuring and monitoring firewalls, routers, switches and other network devices, TCP/IP protocol & configuration and Internet technologies.",
      "Experience with LAN/WAN networking/distributed computing environment (client/server).",
      "Strong team, interpersonal, analytical, problem identification and resolution skills."
    ],

    education: ["Degree or master’s in computer science or related discipline"],

    expirence: ["4 – 6 Years"],

    industrytype: ["IT"],

    compensation: ["Best in the Industry"],

    jobloaction: ["India"]

  },
  3: {

    role: 'Lead - DevOps',
    id: '3',
    rolelist: [{
      point: ["We are looking for a technical systems administrator to create new functions in systems and data monitoring, QA, and Support. You will collaborate across multiple teams within and clients to improve efficacy in enterprise-level tech stack operations, and create new frameworks, processes, and infrastructures from the ground up.",
        "Linux / Windows administration and shell scripting strongly desired",
        "Understanding and experience in Cloud delivery models, Cloud Security, Compliance and Cloud Native Solutioning Design.",
        "Experience with Public Cloud solution architecture, IaaS, PaaS and SaaS services, DevOps, AKS, automation and best practices.",
        "L3 Proficiency in any one of the streams AWS / Azure / GCP / OpenStack",
        "Strong technical knowledge and experience with Terraform, Azure ARM, Azure / AWS CLI, PowerShell, YAML, Docker, Containers, and Serverless Technologies.",
        "Experience with relational, graph and/or unstructured data technologies such as MS-SQL, MySQL, No SQL, Data Lakes, Hadoop, Cloudera, MongoDB, etc",
        "Proactive self-starter, delivery focused, results orientated, team player, high professional standards, and work ethic, strong-willed but flexible, and capable of making independent decisions",
        "Has a passion for innovation and working in a start-up environment with Good communication expertise."
      ]
    }, ],
    jobresponse: [
      "Monitor systems and data, identifying gaps and areas of opportunity",
      "Develop infrastructure and framework for Quality Assurance, Troubleshooting, and Support",
      "Participate in Release Readiness reviews, Milestone Reviews, and Operability reviews",
      "Lead discussions and serve as the primary contact with vendors who provide tools and services that make up parts of the tech stack.",
      "Represent as a consultant to partners for ensuring legal and privacy policy compliance.",
      "Administer self-service tools, including operation, performance, adoption management, feedback into product roadmap, etc.",
      "You will be tasked with generating innovative technical Solutions."
    ],

    education: ["Degree or master’s in computer science or related discipline"],

    expirence: ["6 – 10 Years"],

    industrytype: ["IT"],

    compensation: ["Best in the Industry"],

    jobloaction: ["India"]

  },
  4: {
    role: 'NOC / Customer Support Engineer',
    id: '1',
    job_desc: `We are a boutique IT services & solutions firm headquartered in the Bay Area with offices in India. Our offering
includes custom-configured hybrid cloud solutions backed by our managed services. We combine best in class
DevOps and IT infrastructure management practices, to manage our clients Hybrid Cloud Environments.
In addition, we build and deploy our private cloud solutions using Open stack to provide our clients with a
secure, cost effective and scale able Hybrid Cloud solution. We work with start-ups as well as enterprise
clients.
This is an exciting opportunity for an experienced Cloud Engineer to work on exciting projects and have an
opportunity to expand their knowledge working on adjacent technologies as well.`,
    rolelist: [{
      point: [
        "Proven experience with various Network / System Infrastructure",
        "Competence with troubleshooting tools.",
        "Extensive application support experience with network monitoring and analysis software,",
        "Well versed with daily health check monitoring of Network / System Infrastructure",
        "Competence in following helpdesk ticketing process and ensuring uptime of the infrastructure",
        "Proactive self-starter, delivery focused, results orientated, team player and work ethic, strong-willed but flexible, and capable of making independent decisions",
        "Has a passion for innovation and working in a start-up environment with Good communication skills"
      ]
    }],
    jobresponse: [
      "Create and maintain documentation of network configuration, network mapping, processes, and service records.",
      "Implement and maintain procedures, and associated plans for network administration, usage, and monitoring.",
      "Monitoring LANs, WANs and wireless networks, including servers, routers, switches, UPSs, and other hardware and software upgrades.",
      "Monitoring firewalls, site-to-site VPNs, and remote-access VPNs Conduct research on network products, services, protocols, and standards to remain abreast of developments in the networking industry.",
      "Monitor network performance and troubleshoot problem areas as needed.",
      "Practice network asset management, including maintenance of network component inventory and related documentation and technical specifications information.",
      "Monitor and test network performance and provide network performance statistics and reports.",
      "Work with the Internal / External teams to ensure the maximum availability of services to the customer.",
      "Work in rotating shifts in a 24X7 environment."
    ],

    education: ["Degree or master’s in computer science or related discipline"],

    expirence: ["1 – 3 Years"],

    industrytype: ["IT"],

    compensation: ["Best in the Industry"],

    jobloaction: ["Vadodara, Gujarat, India"]

  },
  5: {
    role: 'Linux Administrator',
    id: '1',
    job_desc: `We are a boutique IT services & solutions firm. Our offering includes custom-configured hardware solutions
      backed by our managed services. We combine best-in-class DevOps and IT infrastructure management
      practices, so customers can have a secure, reliable platform built to scale with their growing requirements at
      an optimal cost. We provide a global footprint of highly reliable enterprise-class data centers in 200+ locations
      worldwide, so customers can design and deploy your infrastructure for productivity and innovation. A few of
      the solutions managed for our clients include Hybrid & Private cloud, cloud storage, back up, disaster recovery,
      virtual desktop, Data Science platform, SDN, Cloud Migration, Infrastructure as a Services, Managed Cloud
      Services, Application Hosting, GPU Servers for AI, Big Data and IoT, Media Streaming, Innovation Labs & NPD
      Infrastructure, eCommerce, Big Data and BI, Security.`,
    rolelist: [{
      point: [
        "Previous working experience as a Linux Administrator for 7 years",
        "In-depth knowledge of Linux: RedHat, CentOS, Debian, etc.",
        "Hands-on experience with MySQL and/or MariaDB",
        "Exposure to opensource platforms like Openstack, Citrix Xen, KVM, OpenNAS, FreeNAS, CryptoNAS, PFSense, OPNSense, etc",
        "Exposure to Shell, Perl, and/or Python scripting",
        "Working knowledge of RAID (Hardware / Software), ZFS, NFS and LVM",
        "Solid knowledge of protocols such as DNS, Web Services, LDAP, eMail Services and SNMP",
        "Additional Skills: AWS, Linux Certification Administration experience in PostgreSQL, OEM Certifications (RHCE) is an advantage",
        "Strong team, interpersonal, analytical, problem identification, and resolution skills.",
        "Has a passion for innovation and working in a start-up environment with Good communication skills"
      ]
    }],
    jobresponse: [
      "We are looking for game-changers who are confident to use their talent to make a difference.",
      "Proactively maintain and develop all Linux infrastructure technology to maintain a 24x7x365 uptime service",
      "Engineering of systems administration-related solutions for various project and operational needs",
      "Maintain best practices on managing systems and services across all environments",
      "Fault finding, analysis, and logging information for reporting of performance exceptions",
      "Proactively monitoring system performance and capacity planning",
      "Manage, coordinate, and implement software upgrades, patches, hotfixes on servers, workstations, and network hardware",
      "Create and modify automation scripts or applications to perform tasks",
      "Provide input on ways to improve the stability, security, efficiency, and scalability of the environment",
      "Collaborate with other teams and team members to develop automation strategies and deployment processes"
    ],

    education: ["Degree or master’s in computer science or related discipline"],

    expirence: ["6 – 9 Years"],

    industrytype: ["IT"],

    compensation: ["Best in the Industry"],

    jobloaction: ["India"]

  },
  6: {
    role: 'Cloud Engineer',
    id: '1',
    job_desc: `We are a boutique IT services & solutions firm headquartered in the Bay Area with offices in India. Our offering
      includes custom-configured hybrid cloud solutions backed by our managed services. We combine best in class
      DevOps and IT infrastructure management practices, to manage our clients Hybrid Cloud Environments.
      In addition, we build and deploy our private cloud solutions using Open stack to provide our clients with a
      secure, cost effective and scale able Hybrid Cloud solution. We work with start-ups as well as enterprise
      clients.
      This is an exciting opportunity for an experienced Cloud Engineer to work on exciting projects and have an
      opportunity to expand their knowledge working on adjacent technologies as well.`,
    rolelist: [{
      point: [
        "Provisioning skills on IaaS cloud computing for platforms such as AWS, Azure, GCP.",
        "Strong working experience in AWS space with various AWS services and implementations (i.e. VPCs, SES, EC2, S3, Route 53, Cloud Front, etc.)",
        "Ability to design solutions based on client requirements.",
        "Some experience with various network LAN/WAN appliances like (Cisco routers and ASA systems, Barracuda, Meraki, SilverPeak, Palo Alto, Fortinet, etc.)",
        "Understanding of networked storage like (NFS / SMB / iSCSI / Storage GW / Windows Offline)",
        "Linux / Windows server installation, maintenance, monitoring, data backup and recovery, security, and administration.",
        "Good knowledge of TCP/IP protocol & internet technologies.",
        "Passion for innovation and problem solving, in a start-up environment.",
        "Good communication skills."
      ]
    }],
    jobresponse: [
      "Trouble shoot and handle L2/ L3 tickets.",
      "Design and architect Enterprise Cloud systems and services.",
      "Design, Build and Maintain environments primarily in AWS using EC2, S3/Storage, CloudFront, VPC, ELB, Auto Scaling, Direct Connect, Route53, Firewall, etc.",
      "Build and deploy in GCP/ Azure as needed.",
      "Architect cloud solutions keeping performance, cost and BCP considerations in mind.",
      "Plan cloud migration projects as needed.",
      "Collaborate & work as part of a cohesive team.",
      "Help build our private cloud offering on Open stack."
    ],

    goodto: [
      "Remote Monitoring & Management.",
      "Familiarity with Kubernetes and Containers.",
      "Exposure to DevOps automation scripts & experience with tools like Git, bash scripting, PowerShell, AWS Cloud Formation, Ansible, Chef or Puppet will be a plus.",
      "Architect / Practitioner certification from OEM with hands-on capabilities."
    ],

    education: ["Bachelor’s/ Master’s in computer science or related discipline"],

    expirence: ["7+ Years"],

    industrytype: ["IT"],

    compensation: ["Market competitive"],

    jobloaction: ["India"]

  },
  7: {
    role: 'Network Engineer',
    id: '1',
    job_desc: `We are a boutique IT services & solutions firm headquartered in the Bay Area with offices in India. Our offering includes
custom-configured hybrid cloud solutions backed by our managed services. We combine best in class DevOps and IT
infrastructure management practices, to manage our clients Hybrid Cloud Environments.
In addition, we build and deploy our private cloud solutions using Open stack to provide our clients with a secure, cost
effective and scale able Hybrid Cloud solution. We work with start-ups as well as enterprise clients.
This is an exciting opportunity for an experienced Cloud Engineer to work on exciting projects and have an opportunity to
expand their knowledge working on adjacent technologies as well.`,
    rolelist: [{
      point: [
        "Network Performance: Tuning; VPN, LAN, WAN and DMZ Knowledge; Network Design and Implementation",
        "Problem Solving: Multi-tasking; Quality Focus; Coordination and Teamwork; Independent and Self Starter; Quick Study; Technical Zeal",
        "Other technical skills: Active Directory, Exchange, Patch Management, Image Deployment, iSCSI, VMWare and Windows 2012 Administration Experience; Exceptional Analytical Skills",
        "Communication: Professional and Effective Communication"
      ]
    }],
    jobresponse: [
      "Trouble shoot and handle L2/ L3 tickets.",
      "Work on projects, create network design.",
      "Establishes VPN, LAN, WAN and DMZ specifications by conferring with users; analysing workflow, access, information, and security requirements; designing router administration, including interface configuration and routing protocols.",
      "Continually improves the networks by monitoring and evaluating network performance issues including availability, utilization, throughput, and latency; planning and executing the selection, installation, configuration, and testing of equipment; defining network policies and procedures; establishing connections and firewalls.",
      "Secures the networks by developing and implementing network access, monitoring, control, and evaluation systems, maintaining documentation.",
      "Administers servers, virtual servers, NAS, SAN, desktop computers, printers, routers, switches, firewalls, phones, personal digital assistants, smartphones, software deployment, security updates and patches.",
      "Maintains network facilities in all machines, such as drivers and settings of personal devices and printers.",
      "Maintains network servers such as file servers, VPN Gateways, and intrusion detection systems.",
      "Maintains inventory of equipment and parts as well as documentation of configurations, standards, and vendor activities.",
      "Accomplishes organization goals by accepting ownership of tasks and requests; exploring opportunities to add value to job accomplishments."
    ],

    goodto: [
      "Remote Monitoring & Management.",
      "MCSE, MCSA or CCNA",
      "Passion for innovation and problem solving, in a start-up environment.",
      "Good communication skills."
    ],

    education: ["Bachelor’s/ Master’s in computer science or related discipline"],

    expirence: ["7+ Years"],

    industrytype: ["IT"],

    compensation: ["Market competitive"],

    jobloaction: ["India"]

  },
  8: {
    role: 'Desktop Support Engineer',
    id: '1',
    job_desc: `We are a boutique IT services & solutions firm headquartered in the Bay Area with offices in India. Our offering includes
custom-configured hybrid cloud solutions backed by our managed services. We combine best in class DevOps and IT
infrastructure management practices, to manage our clients Hybrid Cloud Environments.
In addition, we build and deploy our private cloud solutions using Open stack to provide our clients with a secure, cost
effective and scale able Hybrid Cloud solution. We work with start-ups as well as enterprise clients.
This is an exciting opportunity for an experienced Cloud Engineer to work on exciting projects and have an opportunity to
expand their knowledge working on adjacent technologies as well.`,
    rolelist: [{
      point: [
        "Desktop support experience of 1 to 5 years",
        "Experience Supporting end-users and office IT",
        "Experience in working with Windows 10, printers, laptops etc",
        "Good communication skills and ability to work with multiple client teams.",
        "Needs to work out of client office in Salt Lake, in Kolkata.",
        "Responding to client support requests.",
        "Contacting clients to find out the nature of the problem.",
        "Traveling to the client’s location or connecting via a remote link.",
        "Troubleshooting hardware and software issues.",
        "Installing and maintaining hardware and computer peripherals.",
        "Installing and upgrading operating systems and computer software.",
        "Troubleshooting networking and connection issues.",
        "Advising on software or hardware upgrades.",
        "Providing basic training in computer operation and management."
      ]
    }],
    jobresponse: [
      "Troubleshoot technology issues.",
      "Install and maintain equipment and software.",
      "Ask questions to pinpoint the problem.",
      "Perform remote troubleshooting.",
      "Help new employees set up their workstations.",
      "Maintain and upgrade equipment as needed.",
      "Train new employees to use a company's software and apps.",
      "Work with existing vendors to evaluate new technology.",
      "Respond to ad hoc and urgent requests.",
      "Advise executives on the best technological solutions for an organization.",
      "Monitor the performance of a company's desktop infrastructure and provide suggestions to improve efficiency.",
      "Test, install and set up application programs on user workstations.",
      "Test network connections.",
      "Train end users when new software or IT regulations arrive at a company."
    ],

    goodto: [
      "Remote Monitoring & Management.",
      "MCSE, MCSA or CCNA",
      "Passion for innovation and problem solving, in a start-up environment."
    ],

    education: ["Bachelor’s in computer science or related discipline"],

    expirence: ["1 to 5 Years"],

    industrytype: ["IT"],

    compensation: ["Market competitive"],

    jobloaction: ["India"]

  }
}
