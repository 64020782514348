<header class="hero-area inner-page-header locations ptb-c-100 main--header" data-stellar-background-ratio="0.5">
  <app-navbar></app-navbar>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-6">
        <div class="page-header-content text-white pt-sm-5 pt-md-5 pt-lg-0">
          <br><br><br><br>
          <h1 class="text-white mb-0 ml-0">Location</h1>
          <br>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="clearfix"></div>

<!-- Our Global PoPs and Office  -->
<div class="section black_c_wrap">
  <div class="container">
    <div class="section-header">
      <h2 class="section-title has-text-blue custom-heading">Our Global PoPs and Office</h2>
      <hr class="lines wow zoomIn" data-wow-delay="0.3s">
    </div>
    <div class="row">
      <div class="col-md-8 col-xs-12">
        <img src="../assets/img/Opsara Global PoPs - new.png" class="img-fluid" />
      </div>
      <div class="col-md-4 col-xs-12">
        <h5 class="topic_heading">Opsara global infrastructure platform consists of: </h5>
        <div class="service-sec-1 content-banner pt-0">
          <div class="PoPs-list">
            <ul>
              <li><span class="mr-2 has-text-red list-icon"><i class="mdi mdi-office-building-marker"></i></span> 10+ PoPs with nearly 100 racks.</li>
              <li><span class="mr-2 has-text-red list-icon"><i class="mdi mdi-cloud-print"></i></span> Located in Data Centers like Equinix & INAP.</li>
              <li><span class="mr-2 has-text-red list-icon"><i class="mdi mdi-speedometer"></i></span> 600 Gbps of bandwidth.</li>
              <li><span class="mr-2 has-text-red list-icon"><i class="mdi mdi-cloud-check"></i></span> 100 Gbps public cloud connectivty options.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="clearfix"></div>

<div class="map_pos pt-0">
  <div class="img-fluid">
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe width="100%" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=Milpitas&t=&z=11&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </div>
    </div>
  </div>

</div>
<app-footer></app-footer>
