import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
declare const loadAllData: any;
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-moaxy',
  templateUrl: './moaxy.component.html',
  styleUrls: ['./moaxy.component.css']
})
export class MoaxyComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit(): void {
    loadAllData();
    $(window).scrollTop(0);
  }

  ngAfterContentInit(): void {
    var scrollref = this.router.url;
    console.log(scrollref.indexOf("#"))
    if(scrollref.indexOf("#") > -1){

      var myString = scrollref.split('#').pop()
      var scrollPoint = $("#"+myString).offset().top-100
      console.log(scrollPoint)
      $(window).scrollTop(scrollPoint);

    }

  }

}
