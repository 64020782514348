import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
declare const loadAllData: any;
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-opscale',
  templateUrl: './opscale.component.html',
  styleUrls: ['./opscale.component.css']
})
export class OpscaleComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit(): void {
    loadAllData();
    $(window).scrollTop(0);
  }

  ngAfterContentInit(): void {
    this.storageCalculator();
    var scrollref = this.router.url;
    if(scrollref.indexOf("#") > -1){
      var myString = scrollref.split('#').pop()
      var scrollPoint = $("#"+myString).offset().top-100
      $(window).scrollTop(scrollPoint);
    }
  }

  isExpanded: boolean = false;

  //
  // Calculator 1: OpScale Storage calculator variables
  //
  tb: number = 50;
  wrtAWS = 0;
  wrtTraditional = 0;

  //Storage Costs
  storageCostOpscale = 0;
  storageCostOpscale_string = '0'
  storageCostAWS = 0;
  storageCostAWS_string = '0'
  storageCostTraditional = 0;
  storageCostTraditional_string = '0'

  // Equipment Depreciation
  equipTraditional = 0;

  // Maintenance Charges
  maintenTraditional = 0;

  // Recurring Storage Costs
  recurrOpscale = 0;
  recurrAWS = 0;
  recurrTraditional = 0;

  //Calculator 1: functions
  public storageCalculate(e, flag) {
    e.preventDefault()
    // if (e.type === 'input' && isNaN(this.tb) && this.tb === 0) {
    //   this.tb = 25
    // } else {
    //   return
    // }
    let key = e.type === 'keypress' ? e.key : '';
    this.tb = Number(`${this.tb || ''}${key}`)

    if (isNaN(this.tb)) {
      this.tb = 50;
    }

    if (this.tb < 50) {
      this.recurrOpscale = 0
      this.recurrAWS = 0
      this.equipTraditional = 0
      this.maintenTraditional = 0
      this.recurrTraditional = 0
      this.storageCostOpscale = 0
      this.storageCostOpscale_string = '0'
      this.storageCostAWS = 0
      this.storageCostAWS_string = '0'
      this.storageCostTraditional = 0
      this.storageCostTraditional_string = '0'
      this.wrtAWS = 0
      this.wrtTraditional = 0
      if (e.type === 'keypress') {
        return;
      }
      this.tb = 50;
    }
    if (this.tb > 10000) {
      this.tb = 10000;
    }
    // let remainder = this.tb % 25
    // if (remainder && e.type === 'change') {
    //   this.tb = (this.tb - remainder) + 25
    // }
    this.storageCalculator();
  }

  public storageCalculator() {
    // console.log('Opscale storage calculator function');
    if (this.tb) {
      this.recurrOpscale = Math.ceil(Number(this.tb) * 16 * 12)
      this.recurrAWS = Math.ceil(Number(this.tb) * 23.55 * 12)
      this.equipTraditional = Math.ceil(65000 * (Number(this.tb)/50) * 0.2)
      this.maintenTraditional = Math.ceil(65000 * ((Number(this.tb)/50) * 0.18))
      this.recurrTraditional = this.maintenTraditional

      this.storageCostOpscale = this.recurrOpscale;
      this.storageCostAWS = this.recurrAWS;
      // this.storageCostTraditional = this.equipTraditional + this.maintenTraditional + this.recurrTraditional;
      this.storageCostTraditional = this.equipTraditional + this.maintenTraditional;

      this.wrtAWS = Math.ceil(((this.storageCostAWS - this.storageCostOpscale) / this.storageCostAWS) * 100);
      this.wrtTraditional = Math.ceil(((this.storageCostTraditional - this.storageCostOpscale) / this.storageCostTraditional) * 100);
      // $ Values
      this.storageCostOpscale_string = this.storageCostOpscale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      this.storageCostAWS_string = this.storageCostAWS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      this.storageCostTraditional_string = this.storageCostTraditional.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      this.recurrOpscale = 0
      this.recurrAWS = 0
      this.equipTraditional = 0
      this.maintenTraditional = 0
      this.recurrTraditional = 0
      this.storageCostOpscale = 0
      this.storageCostOpscale_string = '0'
      this.storageCostAWS = 0
      this.storageCostAWS_string = '0'
      this.storageCostTraditional = 0
      this.storageCostTraditional_string = '0'
      this.wrtAWS = 0
      this.wrtTraditional = 0
    }
  }

  //
  // Calculator 2: OpScale backup & recovery calculator variables
  //
   premBackupTb = 0;
   premBackupCost = 0;
   premBackupCost_string = '0'

   cloudBackupTb = 0;
   cloudDuration = 5; //fixed;
   cloudCostOpscale = 0;
   cloudCostOpscale_string = '0'
   cloudCostAWS = 0;
   cloudCostAWS_string = '0'

   cloudSavingPerc = 0;

  public backupCalculate(e) {
    if (this.premBackupTb) {
      if (this.premBackupTb > 0 && this.premBackupTb < 7) {
        this.premBackupCost = this.premBackupTb * 58 * 12
      } else if (this.premBackupTb > 6 && this.premBackupTb < 14) {
        this.premBackupCost = this.premBackupTb * 55 * 12
      } else if (this.premBackupTb > 15 && this.premBackupTb < 28) {
        this.premBackupCost = this.premBackupTb * 50 * 12
      } else if (this.premBackupTb > 40 && this.premBackupTb < 83) {
        this.premBackupCost = this.premBackupTb * 50 * 12
      }
      this.premBackupCost_string = this.premBackupCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      this.premBackupCost = 0
      this.premBackupCost_string = '0'
      this.premBackupTb = 0
      this.cloudBackupTb = 0;
      this.cloudCostOpscale = 0;
      this.cloudCostOpscale_string = '0'
      this.cloudCostAWS = 0;
      this.cloudCostAWS_string = '0'
      this.cloudSavingPerc = 0;
    }
    this.cloudBackupTb = 0;
    this.cloudCostOpscale = 0;
    this.cloudCostOpscale_string = '0'
    this.cloudCostAWS = 0;
    this.cloudCostAWS_string = '0'
    this.cloudSavingPerc = 0;
  }

  public cloudBackupCalculate(e) {
    e.preventDefault()
    let key = e.type === 'keypress' ? e.key : '';
    this.cloudBackupTb = Number(`${this.cloudBackupTb || ''}${key}`);
    if (isNaN(this.cloudBackupTb)) {
      this.cloudBackupTb = 0;
    }
    if (this.cloudBackupTb < 0) {
      this.cloudBackupTb = 0;
    }
    // if (this.cloudBackupTb >= this.premBackupTb) {
    //   this.cloudBackupTb = this.premBackupTb;
    // }
    if (this.cloudBackupTb) {
      this.cloudCostOpscale = this.cloudBackupTb * 125 * 12;
      this.cloudCostAWS = this.cloudBackupTb * 153 * 12 * 3;
      this.cloudSavingPerc = Math.ceil(((this.cloudCostAWS - this.cloudCostOpscale) / this.cloudCostAWS) * 100);
      this.cloudCostOpscale_string = this.cloudCostOpscale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      this.cloudCostAWS_string = this.cloudCostAWS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      // this.cloudBackupTb = 0;
      this.cloudCostOpscale = 0;
      this.cloudCostOpscale_string = '0'
      this.cloudCostAWS = 0;
      this.cloudCostAWS_string = '0'
      this.cloudSavingPerc = 0;
    }
  }

}
