import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as $ from 'jquery';
declare const loadAllData:any;

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit(): void {

    loadAllData();
    $(window).scrollTop(0);

    // this.href = this.router.url;
    // console.log(this.router.url);
    // var scrollref = this.router.url;
    // // var myString = $("this.href").val().split("#").pop();
    // var myString = scrollref.split('#').pop()
    // console.log("scrollTop", $("#"+myString).offset().top);
    //
    // $('html, body').animate({
    //     scrollTop: $("#"+myString).offset().top-200
    // }, 2000)
  }
  ngAfterContentInit(): void {
    var scrollref = this.router.url;
    console.log(scrollref.indexOf("#"))
    if(scrollref.indexOf("#") > -1){

      var myString = scrollref.split('#').pop()
      var scrollPoint = $("#"+myString).offset().top-100
      console.log(scrollPoint)
      $(window).scrollTop(scrollPoint);
      
    }

  }
}
