<header class="hero-area inner-page-header resources ptb-c-100 main--header" data-stellar-background-ratio="0.5">
  <app-navbar></app-navbar>
  <div class="container">
    <div class="row justify-content-left">
      <div class="col-md-10 col-lg-10">
        <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
          <br><br><br>
          <h1 class="text-white mb-0 pt-3">Resources</h1>
          <br><br>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="clearfix"></div>
<!---- Content Section ---->
<section class="inner__section" id="resources">
  <!-- testimonial Section Start -->
  <div id="testimonial" class="section Resources" data-stellar-background-ratio="0.1">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-12">
          <div class="touch-slider">
            <app-testimonialslider></app-testimonialslider>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- PDF section Start -->
  <div id="pdfs">
    <!-- case studies -->
    <div class="section bg-grad-coral pt-5">
      <div class="container text-center">
        <h5 class="has-text-blue custom-heading">Case Studies Documents</h5>
        <div class="section-header mb-3">
          <hr class="lines">
        </div>

        <div class="row mt-5">
          <div class="col-md-6 col-xs-12">
            <div class="pdf-card">
              <h5 class="has-text-blue "> Griddable CaseStudy</h5>
              <div class="section-header mb-3">
                <hr class="lines ml-0">
              </div>
              <object data='../assets/pdfs/Ops_CaseStudy-Griddable_Dec2020.pdf' type='application/pdf' width='100%' height='100%'></object>
            </div>

          </div>
          <div class="col-md-6 col-xs-12">
            <div class="pdf-card">
              <h5 class="has-text-blue ">Moaxy CaseStudy </h5>
              <div class="section-header mb-3">
                <hr class="lines ml-0">
              </div>
              <object data='../assets/pdfs/Ops_CaseStudy-Moaxy-Griddable Dec2020.pdf' type='application/pdf' width='100%' height='100%'></object>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-6 col-xs-12">
            <div class="pdf-card">
              <h5 class="has-text-blue ">OnRule CaseStudy </h5>
              <div class="section-header mb-3">
                <hr class="lines ml-0">
              </div>
              <object data='../assets/pdfs/Ops_CaseStudy-OnRule-Dec2020.pdf' type='application/pdf' width='100%' height='100%'></object>
            </div>
          </div>
          <div class="col-md-6 col-xs-12" id="SlashnextCaseStudy">
            <div class="pdf-card">
              <h5 class="has-text-blue ">Slashnext CaseStudy </h5>
              <div class="section-header mb-3">
                <hr class="lines ml-0">
              </div>
              <object data='../assets/pdfs/Ops_CaseStudy-Slashnext_Dec2020.pdf' type='application/pdf' width='100%' height='100%'></object>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- flyers -->
    <div class="section Resources pt-5">
      <div class="container text-center">
        <h5 class="has-text-blue custom-heading">Data Sheet</h5>
        <div class="section-header mb-3">
          <hr class="lines">
        </div>

        <div class="row mt-5">
          <div class="col-md-6 col-xs-12">
            <div class="pdf-card">
              <h5 class="has-text-blue"> Edge-PaaS</h5>
              <div class="section-header mb-3">
                <hr class="lines ml-0">
              </div>
              <object data='../assets/pdfs/Ops_Flyer Edge-PaaS Dec2020.pdf' type='application/pdf' width='100%' height='100%'></object>
            </div>

          </div>
          <div class="col-md-6 col-xs-12" id="Infrastructure_solutions">
            <div class="pdf-card">
              <h5 class="has-text-blue">Delivering custom IT Infrastructure solutions </h5>
              <div class="section-header mb-3">
                <hr class="lines ml-0">
              </div>
              <object data='../assets/pdfs/Ops_Flyer Intro-Delivering custom IT Infrastructure solutions-Dec2020.pdf' type='application/pdf' width='100%' height='100%'></object>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-6 col-xs-12" id="VSA">
            <div class="pdf-card">
              <h5 class="has-text-blue ">Kaseya VSA </h5>
              <div class="section-header mb-3">
                <hr class="lines ml-0">
              </div>
              <object data='../assets/pdfs/Ops_Flyer Kaseya VSA.pdf' type='application/pdf' width='100%' height='100%'></object>
            </div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="pdf-card">
              <h5 class="has-text-blue">Dark Web ID </h5>
              <div class="section-header mb-3">
                <hr class="lines ml-0">
              </div>
              <object data='../assets/pdfs/Ops_Flyer_ Dark Web ID.pdf' type='application/pdf' width='100%' height='100%'></object>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-6 col-xs-12">
            <div class="pdf-card">
              <h5 class="has-text-blue ">Acronis Backup Cloud Solution</h5>
              <div class="section-header mb-3">
                <hr class="lines ml-0">
              </div>
              <object data='../assets/pdfs/Ops_Flyer_Acronis Backup Cloud Solution.pdf' type='application/pdf' width='100%' height='100%'></object>
            </div>
          </div>
          <div class="col-md-6 col-xs-12" id="WAF">
            <div class="pdf-card">
              <h5 class="has-text-blue">Barracuda WAF-as-a-Service </h5>
              <div class="section-header mb-3">
                <hr class="lines ml-0">
              </div>
              <object data='../assets/pdfs/Ops_Flyer_Barracuda-WAF-as-a-Service-Dec2020.pdf' type='application/pdf' width='100%' height='100%'></object>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-6 col-xs-12" id="DNS_Security">
            <div class="pdf-card">
              <h5 class="has-text-blue ">DNS Security Advantages</h5>
              <div class="section-header mb-3">
                <hr class="lines ml-0">
              </div>
              <object data='../assets/pdfs/Ops_Flyer_DNS Security Advantages_2 Page.pdf' type='application/pdf' width='100%' height='100%'></object>
            </div>
          </div>


          <div class="col-md-6 col-xs-12" id="DNS_Security">
            <div class="pdf-card">
              <h5 class="has-text-blue ">VDI-as-a-Service</h5>
              <div class="section-header mb-3">
                <hr class="lines ml-0">
              </div>
              <object data='../assets/pdfs/Ops_CaseStudy_VDI as a service_Flyer.pdf' type='application/pdf' width='100%' height='100%'></object>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>


  <div class="clearfix"></div>
</section>



<!---- Footer Section---->
<div class="clearfix"></div>
<app-footer></app-footer>
