<header class="hero-area inner-page-header ptb-c-100" data-stellar-background-ratio="0.5">  
    <app-navbar></app-navbar>
    <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-7">
                    <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                        <h1 class="text-white mb-0">CCPA Privacy Request Form</h1>
                        <p>Your privacy requests processed as part of the California Consumer Privacy Act.</p>
                    </div>
                </div>
            </div>
        </div>      
    </header>

    <div class="clearfix"></div>

    <section class="inner__section">
            <div class="container">
                <div class="row">

<div id="success_message" style="display: none; color:#000;  min-height: 320px; width: 100%;">
    <p class="succes_msg_form">The request has been submitted successfully.</p>
</div>
<div id="form_container">
    <form #myDOMElement   class="appnitro"  method="post" action="" (submit)="DataSubmit()">
        <!-- <div class="form_description">
            <h2>CCPA Privacy Request Form</h2>
            <p>Your privacy requests processed as part of the California Consumer Privacy Act.</p>
        </div> -->
        <ul>

            <li id="li_1" >
                <label class="description" for="requesterType">I am a (an)* </label>
                <div>
                    <select  class="element select medium form-control" id="requesterType" name="requesterType" required="">
                        <option value="" >Select Role</option>
                        <option value="CUSTOMER">Customer</option>
                        <option value="VISITOR" >Visitor</option>
                        <option value="JOB_CANDIDATE" >Job Candidate</option>
                        <option value="EMPLOYEE" >Employee</option>
                        <option value="PARTNER" >Partner</option>
                        <option value="OTHER" >Other</option>
                    </select>
                </div>
                <p class="guidelines" id="guide_1"><small>Select your role with the company</small></p>
            </li>
            <li id="li_2" >
                <label class="description" for="requestType">Privacy Request Type* </label>
                <div>
                    <select class="element select medium form-control" id="requestType" name="requestType" required>
                        <option value="" selected>Select Request Type</option>
                        <option value="DELETE_DATA" >Delete My Personal Information</option>
                        <option value="DO_NOT_SELL" >Do Not Sell My Personal Information</option>
                        <option value="DATA_ACCESS" >Provide All My Personal Information</option>
                        <option value="DATA_SOLD_SHARED" >Provide Access to My Data Sold</option>
                        <option value="DATA_CATEGORIES" >List All Type(s) of My Data Collected</option>
                        <option value="DATA_SOURCES" >List All Sources of My Data</option>
                        <option value="COLLECTION_PURPOSE" >What is the Purpose of Collecting My Data?</option>
                        <option value="NOTICE_OF_ACTION" >Give Us a Notice of Action</option>
                    </select>
                </div>
                <p class="guidelines" id="guide_2"><small>Please select one of the privacy request types.</small></p>
            </li>
            <li id="li_3" >
                <label class="description" for="email">Your Email Address* </label>
                <div>
                    <input id="email" name="email" class="element text medium form-control" type="email" required  maxlength="255" value=""/>
                </div>
                <p class="guidelines" id="guide_3"><small>Please provide your email address to communicate with you.</small></p>
            </li>
            <li id="li_4" >
                <label class="description" for="firstName">Name </label>
                <span>
			<input id="firstName" name= "firstName" class="element text form-control" maxlength="255" size="20" value=""/>
			<label>First</label>
		</span>
                <span>
			<input id="lastName" name= "lastName" class="element text form-control" maxlength="255" size="20" value=""/>
			<label>Last</label>
		</span>
            </li>		<li id="li_7" >
            <label class="description" for="phoneNumber1">Phone </label>
            <span>
			<input id="phoneNumber1" name="phoneNumber1" class="element text form-control" size="3" minlength="3" maxlength="3" value="" type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"> -
			<label for="phoneNumber1">(###)</label>
		</span>
            <span>
			<input id="phoneNumber2" name="phoneNumber2" class="element text form-control" size="3" minlength="3"  maxlength="3" value="" type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"> -
			<label for="phoneNumber2">###</label>
		</span>
            <span>
	 		<input id="phoneNumber3" name="phoneNumber3" class="element text form-control" size="4"  minlength="4"  maxlength="4" value="" type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
			<label for="phoneNumber3">####</label>
		</span>
        </li>
        <li id="li_10" style="display: none">
                <label class="description" for="otpcode">Verification Code </label>
                <div>
                    <input id="otpcode" name="otpcode" class="element text form-control" type="text" maxlength="6" value=""/>
                    <input id="sendOtp" class="button_text form-control" type="button" name="sendOtp" value="Send Verification Code" onclick="sendtOTP(false, 'POST')" />
                    <input id="verifyOtp" class="button_text form-control" type="button" name="verifyOtp" value="Verify" onclick="sendtOTP(true, 'GET')" />
                    <label id="otpcodelabel" for="otpcode"></label>
                </div>
                <p class="guidelines" id="guide_10"><small>Enter verification code</small></p>
            </li>
            <li id="li_5" >
                <label class="description" for="addressStreet">Address </label>

                <div>
                    <input id="addressStreet" name="addressStreet" class="element text large form-control" value="" type="text">
                    <label for="addressStreet">Street Address</label>
                </div>

                <div>
                    <input id="addressLine2" name="addressLine2" class="element text large form-control" value="" type="text">
                    <label for="addressLine2">Address Line 2</label>
                </div>

                <div class="left">
                    <input id="addressCity" name="addressCity" class="element text medium form-control" value="" type="text">
                    <label for="addressCity">City</label>
                </div>

                <div class="right">
                    <input id="addressState" name="addressState" class="element text medium form-control" value="" type="text">
                    <label for="addressState">State</label>
                </div>

                <div class="left">
                    <input id="addressPostal" name="addressPostal" class="element text medium form-control" maxlength="15" value="" type="text">
                    <label for="addressPostal">Zip Code</label>
                </div>
            </li>
            <li id="li_6" >
                <label class="description" for="infosec_note">Note </label>
                <div>
                    <textarea id="infosec_note" name="infosec_note" class="element textarea medium form-control"></textarea>
                </div>
            </li>
            <li class="buttons">
                <div id="error_message" style="display: none; color: crimson;">
                    Error
                </div>
            </li>
            <li class="buttons" id="submitbuttonid">
                <input id="saveForm" class="btn btn-common" type="submit" name="submit" value="Submit" />
            </li>

        </ul>
        <div align="left">
            <a href="https://www.infosecenforcer.com">
                <img src="https://app.infosecenforcer.com/assets/images/ccpa-stamp.png" width="75" height="75"/>
            </a>
        </div>
        </form>
    </div> 
    
</div>
</div>
</section>