<header class="hero-area inner-page-header ptb-c-100 main--header" data-stellar-background-ratio="0.5">
  <app-navbar></app-navbar>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-6">
        <div class="page-header-content text-white pt-sm-5 pt-md-5 pt-lg-0">
          <br><br><br><br>
          <h1 class="text-white mb-0">Careers</h1>
          <br>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="clearfix"></div>

<section class="inner__section">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="service-topic job_detail_h">
          <h4 class="mb-4 has-text-blue custom-heading pl-0">Job Details: {{jobData.role}}</h4>

          <div class="section-header mb-3">
            <hr class="lines ml-0">
          </div>

          <ng-container>
            <div class="col-md-12 job_detail_box">
              <h3 class="topic_heading">Summary</h3>
              <div class="service-sec-1 content-banner pt-0">
                <div class="desc">
                  <ul class="summ_list">
                    <li><b>Experience</b> {{jobData.expirence}} </li>
                    <li><b>Location </b> {{jobData.jobloaction}} </li>
                    <li><b>Compensation</b> {{jobData.compensation}} </li>
                    <li><b>Education</b> {{jobData.education}} </li>
                    <li><b>Industry Type</b> {{jobData.industrytype}} </li>
                    <li><b>Role </b> {{jobData.role}} </li>
                  </ul>
                </div>
              </div>

              <h3 class="topic_heading">What we do?</h3>
              <div class="service-sec-1 content-banner pt-0">
                <div class="desc">
                  <p>
                    {{jobData.job_desc}}
                  </p>
                </div>
              </div>

              <h3 class="topic_heading">What are we looking for?</h3>
              <div class="service-sec-1 content-banner pt-0">
                <div class="desc" *ngFor="let item of jobData.rolelist; let i=index;">
                  <table>
                    <tr *ngFor="let list of jobData.rolelist[i].point;">
                      <td>
                        <ul class="summ_list">
                          <li> </li>
                        </ul>
                      </td>
                      <td class="pl-1">{{list}}</td>
                    </tr>
                  </table>
                </div>
              </div>

              <h3 class="topic_heading" *ngIf="jobData.goodto">Good to have</h3>
              <div class="service-sec-1 content-banner pt-0" *ngIf="jobData.goodto">
                <div class="desc">
                  <table>
                    <tr *ngFor="let list of jobData.goodto;">
                      <td>
                        <ul class="summ_list">
                          <li> </li>
                        </ul>
                      </td>
                      <td class="pl-1">{{list}}</td>
                    </tr>
                  </table>
                </div>
              </div>

              <h3 class="topic_heading">What will you be doing!</h3>
              <div class="service-sec-1 content-banner pt-0">
                <div class="desc">
                  <table>
                    <tr *ngFor="let list of jobData.jobresponse">
                      <td>
                        <ul class="summ_list">
                          <li> </li>
                        </ul>
                      </td>
                      <td class="pl-1">{{list}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <a href="mailto:careers@opsara.com?subject={{jobData.role}}" class="btn btn-common mb-5">Apply Now</a>
          </ng-container>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</section>

<div class="clearfix"></div>
<app-footer></app-footer>
