<owl-carousel-o [options]="contentOptions">
    <ng-container *ngFor="let contenttxt of carouselData">         
      <ng-template carouselSlide [id]="contenttxt.id">
          <div  class="content--slider owl-carousel owl-loaded owl-drag owl-theme">
            <div class="item">
                    <h3>{{contenttxt.heading}}</h3>
                    <p class="section-subtitle wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s">
                        {{contenttxt.para}}
                    </p>
                </div>
      </div>
      </ng-template>
    </ng-container>

  </owl-carousel-o>

