<header class="hero-area inner-page-header ptb-100" data-stellar-background-ratio="0.5">  
        <app-navbar></app-navbar>
        <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-9">
                        <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                            <h1 class="text-white mb-0"><span>Welcome to Channel Partner</span></h1>
                            </div>
                    </div>
                </div>
            </div>          
        </header>
        <div class="clearfix"></div>

        <section class="inner__section">
	
                <div class="container">
                  <div class="row">
                          
                            <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="tservice-pills-home-tab">
                               
                               <!---Tab One--->
                                <div class="col-md-12">
                         <div  class="form-topic-heading">
                            <h4>Self Registration</h4>
        <div class="clearfix"></div>
                                <form id="Contact_form">
                        <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <input type="text" class="form-control" id="name" name="name" placeholder="First Name" >
                                <div class="help-block with-errors"></div>
                              </div>  
                              </div>
                               <div class="col-md-4">
                                <div class="form-group">
                                <input type="text" class="form-control" id="name" name="name" placeholder="Last Name" >
                                <div class="help-block with-errors"></div>
                                </div>	
                            </div>
                            
                             <div class="col-md-4">
                                <div class="form-group">
                                <input type="text" class="form-control" id="name" name="name" placeholder="Job Title" >
                                <div class="help-block with-errors"></div>
                                </div>	
                            </div>
        
        <div class="col-md-4">
                                <div class="form-group">
                                <input type="text" class="form-control" id="name" name="name" placeholder="Business Email" >
                                <div class="help-block with-errors"></div>
                                </div>	
                            </div>
        
                            <div class="col-md-4">
                                <div class="form-group">
                                <div class="row">
                                <div class="col-md-4">
                                <select class="select_dropdown form-control">
                                    <option>Phone type</option>
                                    <option value="+1">United States (+1) </option>
                                    <option value="+93">Afghanistan ( +93 ) </option>
                                    <option value="+358">Aland Islands ( +358 ) </option>
                                    <option value="+355">Albania ( +355 ) </option>
                                    <option value="+213">Algeria ( +213 ) </option>
                                    <option value="+1-684">American Samoa ( +1-684 ) </option>
                                    <option value="+376">Andorra ( +376 ) </option>
                                    <option value="+244">Angola ( +244 ) </option>
                                    <option value="+1-264">Anguilla ( +1-264 ) </option>
                                    <option value="+672">Antarctica ( +672 ) </option>
                                    <option value="+1-268">Antigua And Barbuda ( +1-268 ) </option>
                                    <option value="+54">Argentina ( +54 ) </option>
                                    <option value="+374">Armenia ( +374 ) </option>
                                    <option value="+297">Aruba ( +297 ) </option>
                                    <option value="+61">Australia ( +61 ) </option>
                                    <option value="+43">Austria ( +43 ) </option>
                                    <option value="+994">Azerbaijan ( +994 ) </option>
                                    <option value="+1-242">Bahamas ( +1-242 ) </option>
                                    <option value="+973">Bahrain ( +973 ) </option>
                                    <option value="+880">Bangladesh ( +880 ) </option>
                                    <option value="+1-246">Barbados ( +1-246 ) </option>
                                    <option value="+375">Belarus ( +375 ) </option>
                                    <option value="+32">Belgium ( +32 ) </option>
                                    <option value="+501">Belize ( +501 ) </option>
                                    <option value="+229">Benin ( +229 ) </option>
                                    <option value="+1-441">Bermuda ( +1-441 ) </option>
                                    <option value="+975">Bhutan ( +975 ) </option>
                                    <option value="+591">Bolivia ( +591 ) </option>
                                    <option value="+1-721">Bonaire,Sint Eustatius and Saba ( +1-721 ) </option>
                                    <option value="+387">Bosnia and Herzegovina ( +387 ) </option>
                                    <option value="+267">Botswana ( +267 ) </option>
                                    <option value="+47">Bouvet Island ( +47 ) </option>
                                    <option value="+55">Brazil ( +55 ) </option>
                                    <option value="+246">British Indian Ocean Territory ( +246 ) </option>
                                    <option value="+673">Brunei Darussalam ( +673 ) </option>
                                    <option value="+359">Bulgaria ( +359 ) </option>
                                    <option value="+226">Burkina Faso ( +226 ) </option>
                                    <option value="+257">Burundi ( +257 ) </option>
                                    <option value="+855">Cambodia ( +855 ) </option>
                                    <option value="+237">Cameroon ( +237 ) </option>
                                    <option value="+1">Canada ( +1 ) </option>
                                    <option value="+238">Cape Verde ( +238 ) </option>
                                    <option value="+1-345">Cayman Islands ( +1-345 ) </option>
                                    <option value="+236">Central African Republic ( +236 ) </option>
                                    <option value="+235">Chad ( +235 ) </option>
                                    <option value="+56">Chile ( +56 ) </option>
                                    <option value="+86">China ( +86 ) </option>
                                    <option value="+61">Christmas Island ( +61 ) </option>
                                    <option value="+891">Cocos (Keeling) Islands ( +891 ) </option>
                                    <option value="+57">Colombia ( +57 ) </option>
                                    <option value="+269">Comoros ( +269 ) </option>
                                    <option value="+242">Congo ( +242 ) </option>
                                    <option value="+682">Cook Islands ( +682 ) </option>
                                    <option value="+506">Costa Rica ( +506 ) </option>
                                    <option value="+225">Cote d'Ivoire ( +225 ) </option>
                                    <option value="+385">Croatia ( +385 ) </option>
                                    <option value="+53">Cuba ( +53 ) </option>
                                    <option value="+599">Curacao ( +599 ) </option>
                                    <option value="+357">Cyprus ( +357 ) </option>
                                    <option value="+420">Czech Republic ( +420 ) </option>
                                    <option value="+45">Denmark ( +45 ) </option>
                                    <option value="+253">Djibouti ( +253 ) </option>
                                    <option value="+1-767">Dominica ( +1-767 ) </option>
                                    <option value="+1-809">Dominican Republic ( +1-809 ) </option>
                                    <option value="+593">Ecuador ( +593 ) </option>
                                    <option value="+20">Egypt ( +20 ) </option>
                                    <option value="+503">El Salvador ( +503 ) </option>
                                    <option value="+240">Equatorial Guinea ( +240 ) </option>
                                    <option value="+291">Eritrea ( +291 ) </option>
                                    <option value="+372">Estonia ( +372 ) </option>
                                    <option value="+251">Ethiopia ( +251 ) </option>
                                    <option value="+500">Falkland Islands (Malvinas) ( +500 ) </option>
                                    <option value="+298">Faroe Islands ( +298 ) </option>
                                    <option value="+679">Fiji ( +679 ) </option>
                                    <option value="+358">Finland ( +358 ) </option>
                                    <option value="+33">France ( +33 ) </option>
                                    <option value="+594">French Guiana ( +594 ) </option>
                                    <option value="+689">French Polynesia ( +689 ) </option>
                                    <option value="+262">French Southern Territories ( +262 ) </option>
                                    <option value="+241">Gabon ( +241 ) </option>
                                    <option value="+220">Gambia ( +220 ) </option>
                                    <option value="+995">Georgia ( +995 ) </option>
                                    <option value="+49">Germany ( +49 ) </option>
                                    <option value="+233">Ghana ( +233 ) </option>
                                    <option value="+350">Gibraltar ( +350 ) </option>
                                    <option value="+44">Great Britain ( +44 ) </option>
                                    <option value="+30">Greece ( +30 ) </option>
                                    <option value="+299">Greenland ( +299 ) </option>
                                    <option value="+1-473">Grenada ( +1-473 ) </option>
                                    <option value="+590">Guadeloupe ( +590 ) </option>
                                    <option value="+1-671">Guam ( +1-671 ) </option>
                                    <option value="+502">Guatemala ( +502 ) </option>
                                    <option value="+44-1481">Guernsey ( +44-1481 ) </option>
                                    <option value="+224">Guinea ( +224 ) </option>
                                    <option value="+245">Guinea-Bissau ( +245 ) </option>
                                    <option value="+592">Guyana ( +592 ) </option>
                                    <option value="+509">Haiti ( +509 ) </option>
                                    <option value="+0">Heard Island and McDonald Islands ( +0 ) </option>
                                    <option value="+379">Holy See (Vatican City State) ( +379 ) </option>
                                    <option value="+504">Honduras ( +504 ) </option>
                                    <option value="+852">Hong Kong ( +852 ) </option>
                                    <option value="+36">Hungary ( +36 ) </option>
                                    <option value="+354">Iceland ( +354 ) </option>
                                    <option value="+91">India ( +91 ) </option>
                                    <option value="+62">Indonesia ( +62 ) </option>
                                    <option value="+964">Iraq ( +964 ) </option>
                                    <option value="+353">Ireland ( +353 ) </option>
                                    <option value="+44-1624">Isle of Man ( +44-1624 ) </option>
                                    <option value="+972">Israel ( +972 ) </option>
                                    <option value="+39">Italy ( +39 ) </option>
                                    <option value="+1-876">Jamaica ( +1-876 ) </option>
                                    <option value="+81">Japan ( +81 ) </option>
                                    <option value="+44-1534">Jersey ( +44-1534 ) </option>
                                    <option value="+962">Jordan ( +962 ) </option>
                                    <option value="+7">Kazakhstan ( +7 ) </option>
                                    <option value="+254">Kenya ( +254 ) </option>
                                    <option value="+686">Kiribati ( +686 ) </option>
                                    <option value="+82">Korea ( +82 ) </option>
                                    <option value="+965">Kuwait ( +965 ) </option>
                                    <option value="+996">Kyrgyzstan ( +996 ) </option>
                                    <option value="+856">Lao People's Democratic Republic ( +856 ) </option>
                                    <option value="+371">Latvia ( +371 ) </option>
                                    <option value="+961">Lebanon ( +961 ) </option>
                                    <option value="+266">Lesotho ( +266 ) </option>
                                    <option value="+231">Liberia ( +231 ) </option>
                                    <option value="+218">Libyan Arab Jamahiriya ( +218 ) </option>
                                    <option value="+423">Liechtenstein ( +423 ) </option>
                                    <option value="+370">Lithuania ( +370 ) </option>
                                    <option value="+352">Luxembourg ( +352 ) </option>
                                    <option value="+853">MACAO ( +853 ) </option>
                                    <option value="+389">Macedonia, The former Yugoslav Republic Of ( +389 ) </option>
                                    <option value="+261">Madagascar ( +261 ) </option>
                                    <option value="+265">Malawi ( +265 ) </option>
                                    <option value="+60">Malaysia ( +60 ) </option>
                                    <option value="+960">Maldives ( +960 ) </option>
                                    <option value="+223">Mali ( +223 ) </option>
                                    <option value="+356">Malta ( +356 ) </option>
                                    <option value="+692">Marshall Islands ( +692 ) </option>
                                    <option value="+596">Martinique ( +596 ) </option>
                                    <option value="+222">Mauritania ( +222 ) </option>
                                    <option value="+230">Mauritius ( +230 ) </option>
                                    <option value="+262">Mayotte ( +262 ) </option>
                                    <option value="+52">Mexico ( +52 ) </option>
                                    <option value="+691">Micronesia, Federated States of ( +691 ) </option>
                                    <option value="+373">Moldova, Republic of ( +373 ) </option>
                                    <option value="+377">Monaco ( +377 ) </option>
                                    <option value="+976">Mongolia ( +976 ) </option>
                                    <option value="+382">Montenegro ( +382 ) </option>
                                    <option value="+1-664">Montserrat ( +1-664 ) </option>
                                    <option value="+212">Morocco ( +212 ) </option>
                                    <option value="+258">Mozambique ( +258 ) </option>
                                    <option value="+95">Myanmar ( +95 ) </option>
                                    <option value="+264">Namibia ( +264 ) </option>
                                    <option value="+674">Nauru ( +674 ) </option>
                                    <option value="+977">Nepal ( +977 ) </option>
                                    <option value="+31">Netherlands ( +31 ) </option>
                                    <option value="+599">Netherlands Antilles ( +599 ) </option>
                                    <option value="+687">New Caledonia ( +687 ) </option>
                                    <option value="+64">New Zealand ( +64 ) </option>
                                    <option value="+505">Nicaragua ( +505 ) </option>
                                    <option value="+227">Niger ( +227 ) </option>
                                    <option value="+234">Nigeria ( +234 ) </option>
                                    <option value="+683">Niue ( +683 ) </option>
                                    <option value="+672">Norfolk Island ( +672 ) </option>
                                    <option value="+1-670">Northern Mariana Islands ( +1-670 ) </option>
                                    <option value="+47">Norway ( +47 ) </option>
                                    <option value="+968">Oman ( +968 ) </option>
                                    <option value="+92">Pakistan ( +92 ) </option>
                                    <option value="+680">Palau ( +680 ) </option>
                                    <option value="+970">Palestinian Territory, Occupied ( +970 ) </option>
                                    <option value="+507">Panama ( +507 ) </option>
                                    <option value="+675">Papua New Guinea ( +675 ) </option>
                                    <option value="+595">Paraguay ( +595 ) </option>
                                    <option value="+51">Peru ( +51 ) </option>
                                    <option value="+63">Philippines ( +63 ) </option>
                                    <option value="+64">Pitcairn ( +64 ) </option>
                                    <option value="+48">Poland ( +48 ) </option>
                                    <option value="+351">Portugal ( +351 ) </option>
                                    <option value="+1-787">Puerto Rico ( +1-787 ) </option>
                                    <option value="+974">Qatar ( +974 ) </option>
                                    <option value="+262">Reunion ( +262 ) </option>
                                    <option value="+40">Romania ( +40 ) </option>
                                    <option value="+7">Russia ( +7 ) </option>
                                    <option value="+250">Rwanda ( +250 ) </option>
                                    <option value="+590">Saint Barthelemy ( +590 ) </option>
                                    <option value="+290">Saint Helena, Ascension and  Tristan Da Cunha ( +290 ) </option>
                                    <option value="+1-869">Saint Kitts and Nevis ( +1-869 ) </option>
                                    <option value="+1-758">Saint Lucia ( +1-758 ) </option>
                                    <option value="+1-590">Saint Martin (French Part ) ( +1-590 ) </option>
                                    <option value="+508">Saint Pierre and Miquelon ( +508 ) </option>
                                    <option value="+1-784">Saint Vincent and the Grenadines ( +1-784 ) </option>
                                    <option value="+685">Samoa ( +685 ) </option>
                                    <option value="+378">San Marino ( +378 ) </option>
                                    <option value="+239">Sao Tome and Principe ( +239 ) </option>
                                    <option value="+966">Saudi Arabia ( +966 ) </option>
                                    <option value="+221">Senegal ( +221 ) </option>
                                    <option value="+381">Serbia ( +381 ) </option>
                                    <option value="+381">Serbia and Montenegro ( +381 ) </option>
                                    <option value="+248">Seychelles ( +248 ) </option>
                                    <option value="+232">Sierra Leone ( +232 ) </option>
                                    <option value="+65">Singapore ( +65 ) </option>
                                    <option value="+1-721">Sint Maarten (Dutch Part) ( +1-721 ) </option>
                                    <option value="+421">Slovakia ( +421 ) </option>
                                    <option value="+386">Slovenia ( +386 ) </option>
                                    <option value="+677">Solomon Islands ( +677 ) </option>
                                    <option value="+252">Somalia ( +252 ) </option>
                                    <option value="+27">South Africa ( +27 ) </option>
                                    <option value="+995">South Georgia and the South Sandwich Islands ( +995 ) </option>
                                    <option value="+211">South Sudan ( +211 ) </option>
                                    <option value="+34">Spain ( +34 ) </option>
                                    <option value="+94">Sri Lanka ( +94 ) </option>
                                    <option value="+249">Sudan ( +249 ) </option>
                                    <option value="+597">Suriname ( +597 ) </option>
                                    <option value="+47">Svalbard and Jan Mayen ( +47 ) </option>
                                    <option value="+268">Swaziland ( +268 ) </option>
                                    <option value="+46">Sweden ( +46 ) </option>
                                    <option value="+41">Switzerland ( +41 ) </option>
                                    <option value="+963">Syrian Arab Republic ( +963 ) </option>
                                    <option value="+886">Taiwan ( +886 ) </option>
                                    <option value="+992">Tajikistan ( +992 ) </option>
                                    <option value="+255">Tanzania ( +255 ) </option>
                                    <option value="+66">Thailand ( +66 ) </option>
                                    <option value="+670">Timor-Leste ( +670 ) </option>
                                    <option value="+228">Togo ( +228 ) </option>
                                    <option value="+690">Tokelau ( +690 ) </option>
                                    <option value="+676">Tonga ( +676 ) </option>
                                    <option value="+1-868">Trinidad and Tobago ( +1-868 ) </option>
                                    <option value="+216">Tunisia ( +216 ) </option>
                                    <option value="+90">Turkey ( +90 ) </option>
                                    <option value="+993">Turkmenistan ( +993 ) </option>
                                    <option value="+1-649">Turks and Caicos Islands ( +1-649 ) </option>
                                    <option value="+688">Tuvalu ( +688 ) </option>
                                    <option value="+256">Uganda ( +256 ) </option>
                                    <option value="+380">Ukraine ( +380 ) </option>
                                    <option value="+971">United Arab Emirates ( +971 ) </option>
                                    <option value="+44">United Kingdom ( +44 ) </option>
                                    <option value="+1">United States Minor Outlying Islands ( +1 ) </option>
                                    <option value="+598">Uruguay ( +598 ) </option>
                                    <option value="+998">Uzbekistan ( +998 ) </option>
                                    <option value="+1-284">VIRGIN ISLANDS, BRITISH ( +1-284 ) </option>
                                    <option value="+1-340">VIRGIN ISLANDS, U.S. ( +1-340 ) </option>
                                    <option value="+678">Vanuatu ( +678 ) </option>
                                    <option value="+58">Venezuela ( +58 ) </option>
                                    <option value="+84">Viet Nam ( +84 ) </option>
                                    <option value="+681">Wallis and Futuna ( +681 ) </option>
                                    <option value="+212">Western Sahara ( +212 ) </option>
                                    <option value="+967">Yemen ( +967 ) </option>
                                    <option value="+260">Zambia ( +260 ) </option>
                                    <option value="+263">Zimbabwe ( +263 ) </option>
                                </select>
                                </div>
                                <div class="col-md-8"><input type="text" class="form-control" id="name" name="name" placeholder="Phone Number" >
                                <div class="help-block with-errors"></div>
                                </div>
                            </div>	
                                </div>	
                            </div>		
        
                            <div class="col-md-4">
                                <div class="form-group">
                                    <select class="select_dropdown form-control">
                                  <option>Contact Type</option>
                            <option value="">--None--</option>
                            <option value="Executive Sponsor">Executive Sponsor</option>
                            <option value="Marketing Staff">Marketing Staff</option>
                            <option value="Sales Staff">Sales Staff</option>
                            <option value="Technical Staff">Technical Staff</option>
                            <option value="Other">Other</option>
                                </select>
                                <div class="help-block with-errors"></div>
                                </div>	
                            </div>
        
                        <div class="col-md-4">
                            <div class="form-group">
                                <select id="country" name="country" class="select_dropdown form-control">
                                    <option>Select Country</option>
                                    <option value="Afganistan">Afghanistan</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bonaire">Bonaire</option>
                                    <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                    <option value="Brunei">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Canary Islands">Canary Islands</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Channel Islands">Channel Islands</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos Island">Cocos Island</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote DIvoire">Cote DIvoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Curaco">Curacao</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="East Timor">East Timor</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands">Falkland Islands</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Ter">French Southern Ter</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Great Britain">Great Britain</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Hawaii">Hawaii</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="India">India</option>
                                    <option value="Iran">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea North">Korea North</option>
                                    <option value="Korea Sout">Korea South</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Laos">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macau">Macau</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Midway Islands">Midway Islands</option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Nambia">Nambia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherland Antilles">Netherland Antilles</option>
                                    <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                    <option value="Nevis">Nevis</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau Island">Palau Island</option>
                                    <option value="Palestine">Palestine</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Phillipines">Philippines</option>
                                    <option value="Pitcairn Island">Pitcairn Island</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Republic of Montenegro">Republic of Montenegro</option>
                                    <option value="Republic of Serbia">Republic of Serbia</option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russia">Russia</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="St Barthelemy">St Barthelemy</option>
                                    <option value="St Eustatius">St Eustatius</option>
                                    <option value="St Helena">St Helena</option>
                                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                    <option value="St Lucia">St Lucia</option>
                                    <option value="St Maarten">St Maarten</option>
                                    <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                    <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                    <option value="Saipan">Saipan</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="Samoa American">Samoa American</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syria">Syria</option>
                                    <option value="Tahiti">Tahiti</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Erimates">United Arab Emirates</option>
                                    <option value="United States of America">United States of America</option>
                                    <option value="Uraguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Vatican City State">Vatican City State</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Vietnam</option>
                                    <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                    <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                    <option value="Wake Island">Wake Island</option>
                                    <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zaire">Zaire</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                 </select>
                                 
                            <div class="help-block with-errors"></div>
                            </div>	
                        </div>					
        
                        <div class="col-md-4">
                            <div class="form-group">
                            <button type="button" class="btn btn-common tech_btn">Submit</button>
                            </div>	
                        </div>	
        
                            </div>
                        </form>
                         </div>
                     </div>
                     
        
                               <!---Tab One End--->
                            
                            </div>
                            
                            </div>
                     
                    
                  </div>
                </div>
               
            </section>
            
            
<div class="clearfix"></div>
<app-footer></app-footer>