<header class="hero-area inner-page-header opscale ptb-c-100 main--header" data-stellar-background-ratio="0.5">
  <app-navbar></app-navbar>
  <div class="container">
    <div class="row justify-content-left">
      <div class="col-md-10 col-lg-10">
        <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
          <br><br><br>
          <h1 class="text-white mb-0 pt-3">Backup - OpScale</h1>
          <br><br>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="clearfix"></div>

<section class="section">
  <div class="container">
    <div class="row">
      <!-- left-side -->
      <div class="col-md-6 col-sm-12 pr-4">
        <h5 class="has-text-blue custom-heading">OpScale Backup & Recovery </h5>
        <div class="section-header mb-3">
          <hr class="lines ml-0">
        </div>
        <p class="section-subtitle pt-2 services-link">
          All-in-one Data Center Backup & Recovery solution.
          We deploy and manage an <a routerLink="/resources">award winning Unitrends solution</a> for you.
        </p>
        <p class="section-subtitle pt-2">
          Implement a cost effective 3-2-1 backup solution to put your BCP (Business Continuity Planning) into action.
        </p>

        <p class="section-subtitle pb-2">
          Our managed solution makes it easier for you to get it done.
        </p>

        <p class="section-subtitle pb-2">
          We also provide additional solutions for endpoint and Office 365 backup & recovery solutions.
        </p>

      </div>
      <!-- right-side -->
      <div class="col-md-6 col-sm-12 text-center">
        <img src="../assets/img/unitrends Recovery-Gen-8.png" class="img-fluid mt-3"/>
        <div class="row">
          <div class="col-4">
            <img src="../assets/img/123 backup.png" class="img-fluid"/>
            <p class="backup123">3 distinct data copies</p>
          </div>
          <div class="col-4">
            <img src="../assets/img/123 backup2.png" class="img-fluid"/>
            <p class="backup123">2 independent backups</p>
          </div>
          <div class="col-4">
            <img src="../assets/img/123 backup3.png" class="img-fluid"/>
            <p class="backup123">1 offsite backup</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="clearfix"></div>

<!-- Calculator2 Part start  -->
<div class="section black_c_wrap" id="OpscaleBackupCalc">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h5 class="has-text-blue custom-heading">Backup Cost Calculator</h5>
        <div class="section-header mb-3">
          <hr class="lines ml-0">
        </div>
      </div>

      <div class="col-md-5 col-sm-12 pr-0">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <th class="bg-info text-white">On Prem Backup Capacity needed in TB</th>
              <th class="bg-warning text-white" colspan="2" width="45%">
                <select class="custom-select" dir="rtl" (change)="backupCalculate($event)" [(ngModel)]="premBackupTb">
                  <option value="0" selected disabled>Select Capacity</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="16">16</option>
                  <option value="21">21</option>
                  <option value="27">27</option>
                  <option value="41">41</option>
                  <option value="55">55</option>
                  <option value="68">68</option>
                  <option value="82">82</option>
                </select>
              </th>
            </tr>
            <tr>
              <th>
                On Prem Backup Cost per annum
                <span class="expandToggle" (click)="isExpanded=!isExpanded">
                  <i class="fa fa-chevron-down" *ngIf="!isExpanded"></i>
                  <i class="fa fa-chevron-up" *ngIf="isExpanded"></i>
                </span>
              </th>
              <th class="text-right highlight" colspan="2">$ {{premBackupCost_string}}</th>
            </tr>
            <tr class="is-small" *ngIf="isExpanded">
              <td>Appliance - Unitrends</td>
              <td class="text-right" colspan="2">incl</td>
            </tr>
            <tr class="is-small" *ngIf="isExpanded">
              <td>Backup Software - Unitrends Enterprise Plus</td>
              <td class="text-right" colspan="2">incl</td>
            </tr>
            <tr class="is-small" *ngIf="isExpanded">
              <td>Opsara Backup Management Team</td>
              <td class="text-right" colspan="2">incl</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-1 col-sm-12">
        <div class="plus-symbol has-text-blue">
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 pl-0">
        <table class="table table-bordered mb-0">
          <tbody>
            <tr>
              <th class="bg-info text-white">Cloud Backup Required in TB</th>
              <th class="bg-warning text-white py-3" colspan="2">
                <input class="form-control text-right" type="number" min="0" [(ngModel)]="cloudBackupTb" #name="ngModel" (keypress)="cloudBackupCalculate($event)" (change)="cloudBackupCalculate($event)" (value)="cloudBackupTb"
                  placeholder="Enter storage needed in TB">
              </th>
            </tr>

            <tr>
              <th>Cloud Backup Cost Savings</th>
              <th class="text-right py-3" colspan="2">
                <span class=" highlight">{{cloudSavingPerc}} %</span>
                <p class="is-extra-small mb-0 text-right">(wrt to AWS S3)</p>
              </th>
            </tr>

          </tbody>
        </table>

        <table class="table table-bordered" style="font-size: 0.9rem;">
          <tbody>
            <tr>
              <th></th>
              <th class="text-right" width="32%">OpScale Cloud Backup</th>
              <th class="text-right" width="32%">AWS S3 Backup</th>
            </tr>

            <tr class="">
              <th>Cloud Backup Cost, 5 year Retention
              </th>
              <th class="text-right highlight">$ {{cloudCostOpscale_string}}</th>
              <th class="text-right highlight">$ {{cloudCostAWS_string}}</th>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-12">
        <p>
          <span class="is-small">
            Costs provided are estimates for hosting in the US and subject to change. 5 year retention assumed for cloud cost calculations.
          </span>
        </p>
      </div>
      <!-- Calculator2 Part end-->
    </div>
  </div>
</div>

<!-- OpScale Backup -->
<div class="section Resources">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 pt-5">
        <img src="../assets/img/OpScale Backup-new.png" class="mt-4 img-fluid" />
      </div>
      <div class="col-md-6 col-sm-12 pl-5">
        <h5 class="has-text-blue custom-heading pt-4">OpScale Backup </h5>
        <div class="section-header mb-3">
          <hr class="lines ml-0">
        </div>
        <h5 class="topic_heading">Solution for your Hybrid Cloud :</h5>
        <div class="service-sec-1 content-banner pt-0">
          <div class="desc">
            <ul>
              <li>Appliance in your data center.</li>
              <li>Virtual appliance for your public cloud environment.</li>
              <li>Offsite backup in our extended cloud.</li>
              <li>Default 5 year retention in cloud.</li>
              <li>Intuitive cloud management software.</li>
              <li>Team to manage your backup solution.</li>
              <li>Options for endpoint backup and Office 365 backup available.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Features -->
<div class="section black_c_wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 pr-4">
        <h5 class="has-text-blue custom-heading">Features</h5>
        <div class="section-header mb-3">
          <hr class="lines ml-0">
        </div>

        <div class="feature-item">
          <h5 class="topic_heading">
            <span class="icon has-text-red"><i class="mdi mdi-puzzle-check"></i></span>
            Integrated Solution</h5>
          <p>
            An integrated platform to manage backup, replication, deduplication, archives, and instant recovery.
          </p>
        </div>

        <div class="feature-item">
          <h5 class="topic_heading">
            <span class="icon has-text-red"><i class="mdi mdi-cellphone-link"></i></span>
            Multi-Platform Support</h5>
          <p>
            One solution to backup all your computing platforms. Supports over 250 versions of operating systems, hypervisors, and applications.
          </p>
        </div>

        <div class="feature-item">
          <h5 class="topic_heading">
            <span class="icon has-text-red"><i class="mdi mdi-script-text"></i></span>
            Policy Based Management</h5>
          <p>
            Easy to define and schedule backups as per your RPO (Recovery Point Objectives) and RTO (Recovery Time Objectives) needs.
          </p>
        </div>

        <div class="feature-item">
          <h5 class="topic_heading">
            <span class="icon  has-text-red"><i class="mdi mdi-wan"></i></span>
            WAN Optimized</h5>
          <p>
            Built in WAN optimization with deduplication, compression, and encryption capabilities. These provide secure and optimal way to back up to an offsite location.
          </p>
        </div>

      </div>

      <div class="col-md-6 col-sm-12">

        <div class="feature-item">
          <h5 class="topic_heading">
            <span class="icon has-text-red"><i class="mdi mdi-shield-lock"></i></span>
            Automated Ransomware Detection</h5>
          <p>
            Artificial Intelligence (AI) runs during every backup. Any file changes are analyzed to identify a ransomware attack. Upon detection alerts are immediately sent to administrators for further action.
          </p>
        </div>

        <div class="feature-item">
          <h5 class="topic_heading">
            <span class="icon has-text-red"><i class="mdi mdi-account-convert"></i></span>
            Long-Term Retention</h5>
          <p>
            Customize retention times to ensure compliance and efficient capacity utilization. This is an easy, cost-efficient way to comply with regulations such as HIPAA, SOX, and GDPR.
          </p>
        </div>

        <div class="feature-item">
          <h5 class="topic_heading">
            <span class="icon has-text-red"><i class="mdi mdi-history"></i></span>
            Instant Recovery</h5>
          <p>
            Instant Recovery for Windows, VMware vSphere, Microsoft Hyper-V and Unified Bare Metal Recovery.
          </p>
        </div>

        <div class="feature-item">
          <h5 class="topic_heading">
            <span class="icon has-text-red"><i class="mdi mdi-check-decagram"></i></span>
            Recovery Assurance</h5>
          <p>
            Automate recovery, run scheduled tests in the background, prove RTOs ahead of time, and get reports for stakeholders and auditors.
          </p>
        </div>

        <div class="feature-item">
          <h5 class="topic_heading">
            <span class="icon has-text-red"><i class="mdi mdi-tools"></i></span>
            Managed Solution</h5>
          <p>
            Our team works with you to understand your needs and build a solution. We take care of the deployment, ongoing testing and managing it.
          </p>
        </div>

      </div>
    </div>
  </div>
</div>


<!---- Footer Section---->
<div class="clearfix"></div>
<app-footer></app-footer>
