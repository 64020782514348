<header class="hero-area inner-page-header ptb-c-100 main--header" data-stellar-background-ratio="0.5">
  <app-navbar></app-navbar>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-6">
        <div class="page-header-content text-white pt-sm-5 pt-md-5 pt-lg-0">
          <br><br><br><br>
          <h1 class="text-white mb-0 ml-0">Careers</h1>
          <br>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="clearfix"></div>

<section class="inner__section">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="service-topic job_apply">
          <h4>Apply Now</h4>
          <p>Please upload your resume and fill in the fields below to apply for your desired position.</p>
          <div class="col-md-12 job_apply_box">
            <form id="Contact_form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group input_mrg_space">
                    <input type="text" class="form-control" id="name" name="name" placeholder="First Name">
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-md-6 input_mrg_space">
                  <div class="form-group">
                    <input type="text" class="form-control" id="email" name="email" placeholder="Email">
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-md-6 input_mrg_space">
                  <div class="form-group">
                    <input type="text" class="form-control" id="phone" name="phone" placeholder="Phone">
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-md-6 input_mrg_space">
                  <div class="form-group">
                    <input type="text" class="form-control" id="portfoli" name="portfolio" placeholder="Portfolio Link">
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="col-md-12 input_mrg_space">
                  <div class="form-group">
                    <textarea placeholder="Message" class="form-control"></textarea>
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group upload-btn-wrapper">
                    <button class="upload_btn">Upload Your Resume</button>
                    <input type="file" name="myfile" />
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="col-md-12" align="center">
                <a href="javascript:void(0);" class="btn btn-common mb-1">Submit</a>
              </div>
            </form>
          </div>

        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</section>

<div class="clearfix"></div>
<app-footer></app-footer>
