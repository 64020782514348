import { Component, OnInit,  ViewChild, ElementRef} from '@angular/core';
import * as $ from 'jquery';
declare const loadAllData:any;
declare const formDataSubmit:any;

@Component({
  selector: 'app-requestform',
  templateUrl: './requestform.component.html',
  styleUrls: ['./requestform.component.css']
})
export class RequestformComponent implements OnInit {
  @ViewChild('myDOMElement', { static: true }) MyDOMElement: ElementRef;
  constructor() { }

  ngOnInit(): void {
    loadAllData();

  }

  DataSubmit(){
    //var oForm = document.getElementById("infosecenforcer_form");
    event.preventDefault();
    var oForm  = this.MyDOMElement.nativeElement;
    this.sendData(oForm);
  }


   sendData(data) {
    var customerUUID = '9b2640a6-0618-4d6e-8a97-d40b7b179c98',
        hostUrl = "https://app.infosecenforcer.com/infosec",
        enablesOTP =  false,
        sendOTPUrl = hostUrl + '/consumer/request/otp/' + customerUUID,
        verifyOTPUrl = hostUrl + '/consumer/request/otp/verify/' + customerUUID;

    var XHR = new XMLHttpRequest();
    var FD = new FormData();
    var userId = this.randomString(30, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    var cookieId = this.randomString(30, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

    var dsarRequest = { "dsarDetails" : {
            "userId": userId,
            "cookieId": cookieId,
            "requestType": data["requestType"].value,
            "firstName": data["firstName"].value,
            "lastName": data["lastName"].value,
            "email": data["email"].value,
            "requesterType": data["requesterType"].value,
            "address": data["addressStreet"].value + "::" + data["addressLine2"].value + "::" + data["addressCity"].value + "::" + data["addressState"].value + "::" + data["addressPostal"].value,
            "phoneNumber": data["phoneNumber1"].value + data["phoneNumber2"].value + data["phoneNumber3"].value,
            "note":data["infosec_note"].value
        },
        'customerUUID': customerUUID
    };
//     // Define what happens on successful data submission
   XHR.addEventListener('load', function (event) {
    
      if (XHR.readyState === 4) {
        var msg =  JSON.parse(this.response);
        if(msg.success==true) {
              document.getElementById('success_message').style.display = "block";
              document.getElementById('error_message').style.display = "none";
              document.getElementById('form_container').style.display = "none";
          } else {
              document.getElementById('error_message').innerHTML = msg.message;
              document.getElementById('success_message').style.display = "none";
              document.getElementById('error_message').style.display = "block";
              document.getElementById('form_container').style.display = "block";
          }
      }
    
    //     if(event.target.readyState == 4) {
    //         var msg =  JSON.parse(event.target.response);
    //         if(msg.success) {
    //             document.getElementById('success_message').style.display = "block";
    //             document.getElementById('error_message').style.display = "none";
    //             document.getElementById('form_container').style.display = "none";
    //         } else {
    //             document.getElementById('error_message').innerHTML = msg.message;
    //             document.getElementById('success_message').style.display = "none";
    //             document.getElementById('error_message').style.display = "block";
    //             document.getElementById('form_container').style.display = "block";
    //         }
    //     }
    });

//     // Define what happens in case of error
    XHR.addEventListener('error', function (event) {
        alert('Failed to save the request');
    });

//     // Set up our request
    XHR.open('POST', hostUrl + '/consumer/request/save');
    XHR.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
//     // Send our FormData object; HTTP headers are set automatically
    XHR.send(JSON.stringify(dsarRequest));
 }

randomString(length, chars) {
  var result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}


}


