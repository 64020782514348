<header class="hero-area inner-page-header opscale ptb-c-100 main--header" data-stellar-background-ratio="0.5">
  <app-navbar></app-navbar>
  <div class="container">
    <div class="row justify-content-left">
      <div class="col-md-10 col-lg-10">
        <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
          <br><br><br>
          <h1 class="text-white mb-0 pt-3">Storage - OpScale</h1>
          <br><br>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="clearfix"></div>

<section class="section black_c_wrap">
  <div class="container">
    <div class="row">
      <!-- left-side -->
      <div class="col-md-6 col-sm-12">
        <h5 class="has-text-blue custom-heading">OpScale Storage </h5>
        <div class="section-header mb-3">
          <hr class="lines ml-0">
        </div>
        <p class="section-subtitle">
          Deploy OpScale Storage as a managed appliance in your data center or in our cloud platform.
        </p>
        <p class="section-subtitle">
          OpScale Storage is ideally suited for storage needs from 25 TB to Petabytes.
        </p>
        <p class="section-subtitle services-link">
          <a routerLink="/resources" fragment="SlashnextCaseStudy">Check how our client</a>, Slashnext is using it for their Petabyte scale storage needs.
        </p>
      </div>
      <!-- right-side -->
      <div class="col-md-6 col-sm-12 text-center">
        <img src="../assets/img/opsscale cabinet.png" class="img-fluid" style="width: 15rem;" />
      </div>
    </div>
  </div>
</section>
<div class="clearfix"></div>

<!-- Calculator1 Part start -->
<div class="section Resources" id="OpScaleStorageCalc">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h5 class="has-text-blue custom-heading">Storage Cost Calculator</h5>
        <div class="section-header mb-3">
          <hr class="lines ml-0">
        </div>
      </div>
      <div class="col-md-6 col-sm-12 pr-1">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <th class="bg-info text-white">
                Storage Needed in TB
                <p class="is-extra-small mb-0">Minimum 50 Terabytes</p>
              </th>
              <th class="bg-warning text-white" width="40%">
                <input class="form-control text-right" type="number" min="0" [(ngModel)]="tb" #name="ngModel" (keypress)="storageCalculate($event, 'tb')" (change)="storageCalculate($event, 'tb')" (value)="tb"
                  placeholder="Enter storage needed in TB">
              </th>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-6 col-sm-12">
        <table class="table table-bordered bg-white">
          <tbody>
            <tr>
              <th width="45%" class="has-text-blue">Savings %age</th>
              <th class="text-right" width="25%">
                <span class="highlight">{{wrtAWS}} % </span>
                <p class="is-extra-small mb-0 text-right">wrt to AWS S3</p>
              </th>
              <th class="text-right">
                <span class="highlight ">{{wrtTraditional}} % </span>
                <p class="is-extra-small mb-0 text-right">On Prem Storage</p>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 mt-1">
        <table class="table table-bordered bg-white">
          <tbody>
            <tr>
              <th></th>
              <th class="text-right" width="22%">OpScale Storage</th>
              <th class="text-right" width="22%">AWS S3</th>
              <th class="text-right" width="22%">On Prem Storage</th>
            </tr>
            <tr class="is-large">
              <th>Storage Costs per annum </th>
              <th class="text-right highlight">${{storageCostOpscale_string}}</th>
              <th class="text-right highlight">${{storageCostAWS_string}}</th>
              <th class="text-right highlight">${{storageCostTraditional_string}}</th>
            </tr>
            <tr class="is-small">
              <td>Equipment Depreciation</td>
              <td class="text-right">na</td>
              <td class="text-right">na</td>
              <td class="text-right">{{equipTraditional}}</td>
            </tr>
            <tr class="is-small">
              <td>Maintenance</td>
              <td class="text-right">na</td>
              <td class="text-right">na</td>
              <td class="text-right">{{maintenTraditional}}</td>
            </tr>
            <tr class="is-small">
              <td>Recurring Storage</td>
              <td class="text-right">{{recurrOpscale}}</td>
              <td class="text-right">{{recurrAWS}}</td>
              <td class="text-right">na</td>
            </tr>
          </tbody>
        </table>

        <p> <span class="is-small">Costs provided are estimates for hosting in the US and subject to change.</span></p>

      </div>
    </div>
  </div>
</div>
<div class="clearfix"></div>
<!-- Calculator1 Part end-->

<!-- OpScale on Prem -->
<div class="section black_c_wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-7 col-sm-12 pt-5">
        <img src="../assets/img/opscale on prem.png" class="mt-4 img-fluid" />
      </div>
      <div class="col-md-5 col-sm-12 pl-5">
        <h5 class="has-text-blue custom-heading">OpScale On Prem</h5>
        <div class="section-header mb-3">
          <hr class="lines ml-0">
        </div>
        <h5 class="topic_heading">Use cases include :</h5>
        <div class="service-sec-1 content-banner pt-0">
          <div class="desc">
            <ul>
              <li>Media production houses</li>
              <li>Data intensive AI Compute workloads</li>
              <li>Medical Imaging</li>
            </ul>
          </div>
        </div>
        <h5 class="topic_heading">Features :</h5>
        <div class="service-sec-1 content-banner pt-0">
          <div class="desc">
            <ul>
              <li>Ideal for storage from 50TB to Petabytes </li>
              <li>Optimized for high write %ages</li>
              <li>Wire speed network throughput</li>
              <li>Customization feasible</li>
              <li>Pricing based on 3 year contract.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="clearfix"></div>

<!-- OpScale on Cloud -->
<div class="section Resources">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <h5 class="has-text-blue custom-heading">OpScale on Cloud</h5>
        <div class="section-header mb-3">
          <hr class="lines ml-0">
        </div>
        <h5 class="topic_heading">Use cases include :</h5>
        <div class="service-sec-1 content-banner pt-0">
          <div class="desc">
            <ul>
              <li>IoT data storage</li>
              <li>Survelliance Video storage</li>
              <li>Content Streaming Source</li>
            </ul>
          </div>
        </div>
        <h5 class="topic_heading">Features :</h5>
        <div class="service-sec-1 content-banner pt-0">
          <div class="desc">
            <ul>
              <li>Ideal for 50TB plus to Petabyte scale storage needs</li>
              <li>Optimized for read & write %ages</li>
              <li>Customization feasible</li>
              <li>Dedicated reserved storage </li>
              <li>Pricing based in 3 year contracts.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 pt-4 pl-0">
        <img src="../assets/img/OpScaleonOpsaraCloud.png" class="mt-5 img-fluid" />
      </div>
    </div>
  </div>
</div>
<div class="clearfix"></div>

<!-- Opscale Images -->
<section class="section black_c_wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-sm-4">
        <img src="../assets/img/OpScale Components.png" class="mt-3 img-fluid pb-3" style="width: 97%; height: 28rem;" />
      </div>
      <div class="col-md-5 col-sm-8">
        <div class="service-sec-1 content-banner pt-0 text-center">
          <img src="../assets/img/opscale features.png" class="mt-3 img-fluid" width="90%"/>
        </div>
      </div>
      <div class="col-md-4 col-sm-8">
        <img src="../assets/img/opscale options.png" class="mt-3 img-fluid" />
      </div>
    </div>
  </div>
</section>
<div class="clearfix"></div>


<!---- Footer Section---->
<div class="clearfix"></div>
<app-footer></app-footer>
