import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
declare const loadAllData: any;
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-taas',
  templateUrl: './taas.component.html',
  styleUrls: ['./taas.component.css']
})

export class TaasComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit(): void {
    loadAllData();
    $(window).scrollTop(0);
  }

  ngAfterContentInit(): void {
    var scrollref = this.router.url;
    // console.log(scrollref.indexOf("#"))
    if(scrollref.indexOf("#") > -1){
      var myString = scrollref.split('#').pop()
      var scrollPoint = $("#"+myString).offset().top-100
      // console.log(scrollPoint)
      $(window).scrollTop(scrollPoint);
    }
  }

  s: number = 0; //no_of_server
  s_max = 10;
  ram = 0;
  ram_max = this.s * 384;
  hdd = 0;
  hdd_max = this.s * 9;
  ss = 0; //shared storage
  ss_max = 10000;
  egress = 0;
  egress_max = 100;

  serverOpsara = 0;
  sereverAws = 0;

  ramCostOpsara = 0;
  ramCostAws = 0;

  hddOpsara = 0;
  hddAws = 0;

  ssOpsara = 0;
  ssAws = 0;

  egressOpsara = 0;
  egressAws = 0;

  firewallOpsara = 0;
  firewallAws = 150;

  natOpsara = 0;
  natAws = 60;

  loadBalancerOpsara = 0;
  loadBalancerAws = 150;

  moniMetricesOpsara = 0;
  moniMetricesAws = 30;

  cloudMgmtCostAws = this.s * 8 * 75;

  opsaraCloudPM = 0;
  awsPM = 0;

  grandTotalOpsara = 0;
  grandTotalAws = 0;

  opsaraSaving = 0;

  public calculate(e, flag) {
    e.preventDefault()
    let key = ''
    if (e.type === 'change') {
      this.s = Number(`${this.s}${key}`)
    }
    if (e.type === 'keypress') {
      key = e.key
      const code = `Digit${key}`
      if (!(key.length && code === e.code)) {
        return;
      }
    }
    switch (flag) {
      case 's':
        this.s = Number(`${this.s || ''}${key}`);
        if (isNaN(this.s)) {
          this.s = 0;
        }
        if (this.s < 0) {
          this.s = 0;
        }
        if (this.s > 10) {
          this.s = 10;
        }
        this.ram = 0;
        this.hdd = 0;
        this.ss = 0;
        this.egress = 0;
        break;
      case 'ram':
        this.ram = Number(`${this.ram || ''}${key}`);
        if (isNaN(this.ram)) {
          this.ram = 0;
        }
        if (this.ram < 0) {
          this.ram = 0;
        }
        if (Number(this.ram) > (Number(this.s) * 384)) {
          this.ram = Number(this.s) * 384;
        }
        this.hdd = 0;
        this.ss = 0;
        this.egress = 0;
        break;
      case 'hdd':
        this.hdd = Number(`${this.hdd || ''}${key}`);
        if (isNaN(this.hdd)) {
          this.hdd = 0;
        }
        if (this.hdd < 0) {
          this.hdd = 0;
        }
        if (Number(this.hdd) > (Number(this.s) * 9)) {
          this.hdd = Number(this.s) * 9;
        }
        this.ss = 0;
        this.egress = 0;
        break;
      case 'ss':
        this.ss = Number(`${this.ss || ''}${key}`);
        if (isNaN(this.ss)) {
          this.ss = 0;
        }
        if (this.ss < 0) {
          this.ss = 0;
        }
        // if ((Number(this.ss) * 10) > 10000) {
        if ((Number(this.ss)) > 10000) {
          this.ss = 10000;
        }
        this.egress = 0;
        break;
      case 'egress':
        this.egress = Number(`${this.egress || ''}${key}`);
        if (isNaN(this.egress)) {
          this.egress = 0;
        }
        if (this.egress < 0) {
          this.egress = 0;
        }
        if (Number(this.egress) > 100) {
          this.egress = 100;
        }
        break;
      default:
      // Ignore
    }
    this.calculator();
  }

  public calculator() {
    this.serverOpsara = this.s * 775;
    this.sereverAws = this.s * 970;

    this.ramCostOpsara = this.ram * 1;
    this.ramCostAws = this.ram * 1.25;

    this.hddOpsara = this.hdd * 41;
    this.hddAws = this.hdd * 46;

    this.ssOpsara = this.ss * 10;
    this.ssAws = this.ss * 23;

    this.egressOpsara = this.egress * 72;
    this.egressAws = this.egress * 82;

    this.firewallOpsara = 0;
    this.firewallAws = 150;

    this.natOpsara = 0;
    this.natAws = 60;

    this.loadBalancerOpsara = 0;
    this.loadBalancerAws = 150;

    this.moniMetricesOpsara = 0;
    this.moniMetricesAws = 30;

    this.cloudMgmtCostAws = this.s * 8 * 75;

    this.opsaraCloudPM = (this.serverOpsara + this.ramCostOpsara + this.hddOpsara + this.ssOpsara + this.egressOpsara + this.firewallOpsara + this.natOpsara + this.loadBalancerOpsara + this.moniMetricesOpsara);
    this.awsPM = (this.sereverAws + this.ramCostAws + this.hddAws + this.ssAws + this.egressAws + this.firewallAws + this.natAws + this.loadBalancerAws + this.moniMetricesAws);

    this.grandTotalOpsara = this.opsaraCloudPM;
    this.grandTotalAws = this.awsPM + this.cloudMgmtCostAws;

    this.opsaraSaving = ((this.grandTotalAws - this.grandTotalOpsara) / this.grandTotalAws) * 100;
  }

  // serverChange(newServerValue) {
  //   console.log('this.s -----> ', this.s);
  //   this.s = newServerValue;
  //   this.calculator();
  // }
  //
  // ramChange(newRamValue) {
  //   this.ram = newRamValue;
  //   this.calculator();
  // }
  //
  // hddChange(newHddValue) {
  //   this.hdd = newHddValue;
  //   this.calculator();
  // }
  //
  // ssChange(newSsValue) {
  //   this.ss = newSsValue;
  //   this.calculator();
  // }
  //
  // egressChange(newEgressValue) {
  //   this.egress = newEgressValue;
  //   this.calculator();
  // }

  // opsaraSaving = 52;

  // opsaraCloudPM = 2767;
  // awsPM = 3981;

  // serverOpsara= this.s*775;
  // sereverAws= this.s*970;
  //
  // ramCostOpsara = this.ram*1 ;
  // ramCostAws= this.ram*1.25;
  //
  // hddOpsara = this.hdd*41;
  // hddAws = this.hdd*46;
  //
  // ssOpsara = this.ss * 10;
  // ssAws = this.ss * 23;
  //
  // egressOpsara = this.egress*72;
  // egressAws= this.egress*82;
  //
  // firewallOpsara = 0;
  // firewallAws= 150;
  //
  // natOpsara = 0;
  // natAws = 60;
  //
  // loadBalancerOpsara = 0;
  // loadBalancerAws = 150;
  //
  // moniMetricesOpsara = 0;
  // moniMetricesAws = 30;
  //
  // cloudMgmtCostAws = this.s*8*75;
  //
  // // grandTotalOpsara = 2667;
  // // grandTotalAws = 5488;
  //
  // // opsaraSaving = ((this.grandTotalAws - this.grandTotalOpsara)/this.grandTotalAws)*100
  //
  // opsaraCloudPM = (this.serverOpsara + this.ramCostOpsara + this.hddOpsara + this.ssOpsara + this.egressOpsara + this.firewallOpsara + this.natOpsara + this.loadBalancerOpsara + this.moniMetricesOpsara );
  // awsPM = (this.sereverAws + this.ramCostAws + this.hddAws + this.ssAws + this.egressAws + this.firewallAws + this.natAws + this.loadBalancerAws + this.moniMetricesAws);
  //
  // grandTotalOpsara = this.opsaraCloudPM;
  // grandTotalAws = this.awsPM + this.cloudMgmtCostAws;
  //
  // opsaraSaving = ((this.grandTotalAws - this.grandTotalOpsara)/this.grandTotalAws)*100;

}
