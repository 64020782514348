import { Directive } from '@angular/core';

@Directive({
  selector: '[appLinewaves]'
})
export class LinewavesDirective {

  constructor() { }

}
