<!-- ================ start footer Area ================= -->
<footer id="footer" class="footer-area section-gap">
  <div class="container">

    <div class="footer-bottom row  text-center text-lg-left text-white">
      <!--  <p class="footer-text m-0 col-lg-8 col-md-12"></p> -->
      <div class="m-0 col-lg-3 col-md-12 about--widget">
        <dl>
          <dt><i class="fa fa-envelope"></i>EMAIL</dt>
          <dd>
            <p>
              <span>Information:</span>
              <a href="mailto:info@opsara.com" class="btn-link">info@opsara.com</a>
            </p>
            <p>
              <span>Support:</span>
              <a href="mailto:support@opsara.com" class="btn-link">support@opsara.com</a>
            </p>
          </dd>
        </dl>

      </div>
      <div class="m-0 col-lg-3 col-md-12 about--widget">

        <dl>
          <dt><i class="fa fa-phone"></i>PHONE</dt>
          <dd>
            <p>
              <span>Support:</span>
              <span class="clr_white btn-link">+1 408-833-6100</span>
              <br>
              <span class="clr_white btn-link margin-23">+1 408-498-4600</span>
          </dd>
        </dl>
      </div>

      <div class="m-0 col-lg-3 col-md-12 about--widget">
        <h4 class="f_heading"><a target="_blank" class="text-white" href="http://www.opsara.com">Opsara, Inc.</a></h4>
        <div class="form-wrap" id="mc_embed_signup">
          <p>Suite 217, 691 S. Milpitas Blvd., <br /> Milpitas, CA 95035</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-12 text-center text-lg-right footer-social">
        <!-- <a href="#"><i class="fa fa-facebook"></i></a>
         <a href="#"><i class="fa fa-twitter"></i></a> -->
        <a href="https://www.linkedin.com/company/opsara" target="_blank"><i class="fa fa-linkedin"></i></a>
        <!-- <a href="#"><i class="fa fa-pinterest"></i></a>
         <a href="#"><i class="fa fa-google-plus"></i></a>
         <a href="#"><i class="fa fa-rss"></i></a> -->
      </div>
    </div>

  </div>

  <hr class="hr_brd">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12   pull-left m-txt-center">
        <p class="footer_txt_g">&copy; 2021 Opsara inc. All rights reserved.</p>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12  pull-right m-txt-center" align="right">
        <ul class="footer_list">
          <li><a routerLink="/location">Locations</a></li>
          <li><a routerLink="/joblist">Careers</a></li>
          <li><a routerLink="/privacy-policy">Privacy policy</a></li>
        </ul>
      </div>
    </div>
  </div>

</footer>
<!-- ================ End footer Area ================= -->
<a href="javascript:void(0);" class="back-to-top">
  <i class="fa fa-chevron-up"></i>
</a>
<!-- <a href="#navbar-header" class="back-to-top">
   <i class="fa fa-chevron-up"></i>
 </a> -->

<!-- <div id="loader">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>  -->