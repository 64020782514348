<header class="hero-area inner-page-header ptb-100" data-stellar-background-ratio="0.5">  
        <app-navbar></app-navbar>
        <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-9">
                        <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                            <h1 class="text-white mb-0"><span>Welcome to Technology Partner</span></h1>
                            </div>
                    </div>
                </div>
            </div>          
        </header>
        <div class="clearfix"></div>
	<section  class="inner__section">
	
            <div class="container">
              <div class="row">
                      
                        <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="tservice-pills-home-tab">
                           
                           <!---Tab One--->
                            <div class="col-md-12">
                     <div  class="form-topic-heading">
                        <h4>Self Registration</h4>
    <div class="clearfix"></div>
                            <form id="Contact_form">
                    <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <input type="text" class="form-control" id="name" name="name" placeholder="First Name" >
                            <div class="help-block with-errors"></div>
                          </div>  
                          </div>
                           <div class="col-md-4">
                            <div class="form-group">
                            <input type="text" class="form-control" id="name" name="name" placeholder="Last Name" >
                            <div class="help-block with-errors"></div>
                            </div>	
                        </div>
                        
                         <div class="col-md-4">
                            <div class="form-group">
                            <input type="text" class="form-control" id="name" name="name" placeholder="Job Title" >
                            <div class="help-block with-errors"></div>
                            </div>	
                        </div>
    
    <div class="col-md-4">
                            <div class="form-group">
                            <input type="text" class="form-control" id="name" name="name" placeholder="Business Email" >
                            <div class="help-block with-errors"></div>
                            </div>	
                        </div>
    
                        <div class="col-md-4">
                            <div class="form-group">
                            <div class="row">
                            <div class="col-md-3">
                            <select class="select_dropdown form-control">
                              <option>Phone</option>
                            </select>
                            </div>
                            <div class="col-md-9"><input type="text" class="form-control" id="name" name="name" placeholder="Email Address" >
                            <div class="help-block with-errors"></div>
                            </div>
                        </div>	
                            </div>	
                        </div>		
    
                        <div class="col-md-4">
                            <div class="form-group">
                                <select class="select_dropdown form-control">
                              <option>Contact Type</option>
                            </select>
                            <div class="help-block with-errors"></div>
                            </div>	
                        </div>
    
                    <div class="col-md-4">
                        <div class="form-group">
                        <select class="select_dropdown form-control">
                              <option>Country</option>
                            </select>
                        <div class="help-block with-errors"></div>
                        </div>	
                    </div>					
    
                    <div class="col-md-4">
                        <div class="form-group">
                        <button type="button" class="btn btn-common tech_btn">Submit</button>
                        </div>	
                    </div>	
    
                        </div>
                    </form>
                     </div>
                 </div>
                 
    
                           <!---Tab One End--->
                        
                        </div>
                        
                        </div>
                 
                
              </div>
            </div>
           
        </section>
        <div class="clearfix"></div>
        <app-footer></app-footer>