<header class="hero-area inner-page-header about-us ptb-c-100 main--header" data-stellar-background-ratio="0.5">
  <app-navbar></app-navbar>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10 col-lg-10">
        <div class="page-header-content text-white pt-sm-5 pt-md-5 pt-lg-0">
          <br><br><br><br>
          <h1 class="text-white mb-0">Hybrid Cloud Solutions for Startups & Mid-Sized Companies</h1>
          <br>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="clearfix"></div>

<section class="section">
  <div class="container">
    <div class="row">
      <!-- left-side -->
      <div class="col-md-6 col-xs-12">
        <h5 class="has-text-blue custom-heading">We Share the Bay Area Infrastructure Magic</h5>
        <div class="section-header mb-3">
          <hr class="lines wow zoomIn ml-0" data-wow-delay="0.3s">
        </div>
        <p class="section-subtitle pt-3">
          Akamai, Yahoo, Google, Facebook and many more Bay Area startups have built gigantic infrastructure platforms
          delivering significantly
          <b>lower unit cost of compute and storage.</b>
        </p>
        <div class="service-sec-1 content-banner pt-0">
          <p>The key building block for this seemingly magical infrastructure are:- </p>
          <div class="mb-2">
            <ul class="row">
              <li class="col-6"><span class="mr-2 has-text-red list-icon"><i class="mdi mdi-account-group"></i></span>
                Bold & Progressive Leaders</li>
              <li class="col-6"><span class="mr-2 has-text-red list-icon"><i class="mdi mdi-cogs"></i></span> Adapting
                open source tools</li>
              <li class="col-6"><span class="mr-2 has-text-red list-icon"><i
                    class="mdi mdi-check-network-outline"></i></span> Designing for unit failure</li>
              <li class="col-6"><span class="mr-2 has-text-red list-icon"><i
                    class="mdi mdi-account-multiple-plus"></i></span> Sourcing at scale</li>
              <li class="col-12"><span class="mr-2 has-text-red list-icon"><i class="mdi mdi-memory"></i></span>
                Building with commodity hardware</li>
            </ul>
          </div>
        </div>
        <p class="section-subtitle">
          Our founding team has been part of this drive having built the core infrastructure at Speedera, Skyhigh
          Networks, Aryaka Networks and Akamai.
        </p>
        <p class="section-subtitle">
          Our journey of innovation continues, working with Bold & Progressive Client Leaders.
        </p>
      </div>
      <!-- right-side -->
      <div class="col-md-5 offset-1 col-xs-11">
        <h5 class="has-text-blue custom-heading">Where we fit in</h5>
        <div class="section-header mb-3">
          <hr class="lines wow zoomIn ml-0" data-wow-delay="0.3s">
          <br>
        </div>
        <p class="section-subtitle pt-3">
          As companies continue on their Digital Transformation Roadmap, IT teams are faced with requests for more data
          storage, more compute to power high performance applications and agility to get things done faster.
        </p>
        <p class="section-subtitle">
          <b class="font-italic">"IDC predicts that the collective sum of the world’s data will grow from 33 Zettabytes
            in 2018 to 175ZB by 2025, for a compounded annual growth rate of 61 percent. "</b>
        </p>
        <p class="section-subtitle">
          The battle tested approach of buying blue chip name brand hardware solutions and or working with one public
          cloud provider is proving to be expensive in many cases.
        </p>
        <p class="section-subtitle">
          Our solutions can help meet your growth requirements cost effectively.
        </p>
      </div>
    </div>
  </div>
</section>
<div class="clearfix"></div>

<!-- Our Global PoPs and Office  -->
<div class="section black_c_wrap">
  <div class="container">
    <div class="section-header">
      <h2 class="section-title has-text-blue custom-heading">Our Global PoPs and Office</h2>
      <hr class="lines wow zoomIn" data-wow-delay="0.3s">
    </div>
    <div class="row">
      <div class="col-md-8 col-xs-12">
        <img src="../assets/img/Opsara Global PoPs - new.png" class="img-fluid" />
      </div>
      <div class="col-md-4 col-xs-12">
        <h5 class="topic_heading">Opsara global infrastructure platform consists of: </h5>
        <div class="service-sec-1 content-banner pt-0">
          <div class="PoPs-list">
            <ul>
              <li><span class="mr-2 has-text-red list-icon"><i class="mdi mdi-office-building-marker"></i></span> 10+
                PoPs with nearly 100 racks.</li>
              <li><span class="mr-2 has-text-red list-icon"><i class="mdi mdi-cloud-print"></i></span> Located in Data
                Centers like Equinix & INAP.</li>
              <li><span class="mr-2 has-text-red list-icon"><i class="mdi mdi-speedometer"></i></span> 600 Gbps of
                bandwidth.</li>
              <li><span class="mr-2 has-text-red list-icon"><i class="mdi mdi-cloud-check"></i></span> 100 Gbps public
                cloud connectivty options.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Our team -->
<div id="our-team" class="section Resources">
  <div class="container">
    <div class="section-header">
      <h2 class="section-title has-text-blue custom-heading">Our team</h2>
      <hr class="lines wow zoomIn" data-wow-delay="0.3s">
    </div>
    <div class="row">
      <!-- Team Member 1 -->
      <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border-0 shadow">
          <div class="card-image">
            <img src="../assets/img/team/Gopa.jpg" class="card-img-top" alt="Gopa Sheppillayar">
          </div>
          <div class="card-body text-center">
            <h5 class="card-title mb-0 has-text-blue">Gopa Sheppillayar</h5>
            <div class="card-text text-black-50">Founder, CEO</div>
            <div class="section-header mb-3">
              <hr class="lines wow zoomIn" data-wow-delay="0.3s">
            </div>
            <div class="team-desc">
              <p>Gopa has more than 25 years of experience in technology leadership roles at Skyhigh Networks, Aryaka
                Networks, Akamai and Speedera Networks.</p>
              <p>While at Speedera (acquired by Akamai), he helped build the entire CDN platform infrastructure. As Head
                of Global Operations at Skyhigh Networks (acquired by McAfee) and Aryaka Networks he built their team
                and practices from scratch.
              </p>
              <p>Gopa is also on the advisory board of multiple Silicon Valley startups.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Team Member 2 -->

      <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border-0 shadow">
          <div class="card-image">
            <img src="../assets/img/team/tanya.jpg" class="card-img-top" alt="Tanya Petrov">
          </div>

          <div class="card-body text-center">
            <h5 class="card-title mb-0 has-text-blue">Tanya Petrov </h5>
            <div class="card-text text-black-50">Director Customer Success</div>
            <div class="section-header mb-3">
              <hr class="lines wow zoomIn" data-wow-delay="0.3s">
            </div>
            <div class="team-desc">
              <p>Tanya has over 15 years of experience working in leadership roles spanning marketing, partnering and
                operations.</p>

              <p>She has worked at high growth SaaS companies like Apttus as well niche solution providers.</p>

              <p>Her persistence and top notch project management are key enablers in delighting customers.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Team Member 3 -->
      <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border-0 shadow">
          <div class="card-image">
            <img src="../assets/img/team/Niraj.jfif" class="card-img-top" alt="Niraj Vyas">
          </div>

          <div class="card-body text-center">
            <h5 class="card-title mb-0 has-text-blue">Niraj Vyas</h5>
            <div class="card-text text-black-50">Co-Founder, VP Tech-Ops</div>
            <div class="section-header mb-3">
              <hr class="lines wow zoomIn" data-wow-delay="0.3s">
            </div>
            <div class="team-desc">
              <p>Niraj has over fifteen years of experience in technical infrastructure management roles. He has worked
                in leading organizations like the Tata Group (India), Aryaka Networks.</p>

              <p>He brings rich experience in building and operating complex enterprise networks and systems including
                Public Cloud.</p>

              <p>His Specialties include Network Audit / IS Audit.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Team Member 4 -->
      <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border-0 shadow">
          <div class="card-image">
            <img src="../assets/img/team/SupriyoSanyal.jpg" class="card-img-top" alt="Supriyo Sanyal">
          </div>
          <div class="card-body text-center">
            <h5 class="card-title mb-0 has-text-blue">Supriyo Sanyal</h5>
            <div class="card-text text-black-50">Co-Founder, VP Marketing & Bus. Dev.</div>
            <div class="section-header mb-3">
              <hr class="lines wow zoomIn" data-wow-delay="0.3s">
            </div>
            <div class="team-desc">
              <p>Supriyo has over two & a half decades of experience bringing new services to market as well as leading
                enterprise program implementations.</p>

              <p>He has worked with startups as well enterprises like Stanford, Pearson, Cisco to name a few.</p>
               
              <p>He also mentors and advises a few technology startups.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Team Member 5 -->
      <!-- <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border-0 shadow">
          <div class="card-image">
            <img src="../assets/img/team/DaveGhosh.jpg" class="card-img-top" alt="Dave Ghosh">
          </div>
          <div class="card-body text-center">
            <h5 class="card-title mb-0 has-text-blue">Dave Ghosh</h5>
            <div class="card-text text-black-50">Member – Advisory Board</div>
            <div class="section-header mb-3">
              <hr class="lines wow zoomIn" data-wow-delay="0.3s">
            </div>
            <div class="team-desc">
              <p>Dave Ghosh is an accomplished industry veteran with more than 25 years in global sales, alliances and business development spanning IT and Software Applications.</p>

              <p>He currently leads the Global Alliances and Channel Sales for Eightfold - the undisputed leader in delivering Deep Learning AI for HR and Talent Transformation.</p>

              <p>Dave has played significant role in startups and industry leaders delivering untold value for their customers and partners.</p>

              <p>He lives in the San Francisco Bay Area with his wife and daughter and is passionate about senior dog rescue - with his own rescue - Pablo - adopted at eleven and now thirteen.</p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <div class="container pt-4">
    <div class="row">
      <div class="col-12 pt-3 pl-0 pr-0">
        <div class="section-header mb-0">
          <h5 class="has-text-blue">Proven build experience</h5>
        </div>
        <div class="service-sec-1 content-banner text-center pt-3">
          <div class="benefit_txt" style="padding-left: 1rem;font-size: 0.9rem;">
            <ul>
              <li class="mr-3"><span class="mr-2 has-text-red list-icon"><i
                    class="mdi mdi-office-building-marker"></i></span> 200+ PoPs globally</li>
              <li class="mr-3"><span class="mr-2 has-text-red list-icon"><i
                    class="mdi mdi-locker-multiple"></i></span>10,000+ Racks</li>
              <li class="mr-3"><span class="mr-2 has-text-red list-icon"><i
                    class="mdi mdi-server-network"></i></span>250,000 Servers deployed & managed</li>
              <li class="mr-0"><span class="mr-2 has-text-red list-icon"><i class="mdi mdi-factory"></i></span>At
                industry leaders like Akamai, Aryaka Networks, Skyhigh Networks</li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- team gallery -->
      <div class="col-12 pt-3">
        <div class="myGallery">
          <div class="item">
            <img src="../assets/img/team/Pratyusha.png"><span class="caption">Pratysuha V.,<br>
              DevOps&nbsp;Engineer </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/11.png"><span class="caption">Samatha S.,<br>
              DevOps&nbsp;Engineer</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/4.png"><span class="caption">Kartik Patel, <br>
              NOC&nbsp;Engineer</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/3.png"><span class="caption">Manish Kumar,<br> Solutions Architect - Windows
            </span>
          </div>
          <!-- <div class="item"><img src="../assets/img/team/13.png"><span class="caption">Suman Das,&nbsp;<br> Sys Admin
            </span></div> -->
          <!-- <div class="item"><img src="../assets/img/team/7.png"><span class="caption">Gopal More,<br> Sys Admin</span>
          </div> -->
          <div class="item">
            <img src="../assets/img/team/1.png"><span class="caption">Mayank Kadiya,<br> SysOps Engineer
            </span>
          </div>
          <!-- <div class="item"><img src="../assets/img/team/8.png"><span class="caption">Merry Chaudhuri,<br> Project
              Mgmt</span></div> -->
          <div class="item">
            <img src="../assets/img/team/6.png"><span class="caption"> Ishwari Patel,<br> Pre-Sales
              Engineer&nbsp;
            </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/9.png"><span class="caption"> Nahendraraj,<br> NOC Engineer
            </span>
          </div>
          <!-- <div class="item"><img src="../assets/img/team/BhavikaVyas.jpg"><span class="caption"> Bhavika Vyas,<br> Admin
            </span></div> -->
          <!-- <div class="item"><img src="../assets/img/team/Zaki.PNG"><span class="caption">Zaki Mohammad,<br> Operations
            </span></div> -->
          <!-- <div class="item"><img src="../assets/img/team/MehulGajjar.png"><span class="caption">Mehul Gajjar,<br> Member
              of TaaSkforce </span></div> -->
          <!-- <div class="item"><img src="../assets/img/team/DhavalGBhatt.png"><span class="caption">Dhaval G Bhatt,<br>
              Member of TaaSkforce </span></div> -->
          <!-- <div class="item"><img src="../assets/img/team/PraveenkumarAnbu.png"><span class="caption">Praveenkumar
              Anbu,<br> Member of TaaSkforce </span></div> -->
          <!-- <div class="item"><img src="../assets/img/team/SrinivasNallappa.png"><span class="caption">Srinivas
              Nallappa,<br> Lead – Infrastructure Engineer </span></div> -->
        <!--   <div class="item">
            <img src="../assets/img/team/ThanuNataraj.png"><span class="caption">Thanu Nataraj,<br>
              Technical Support Engineer </span>
          </div> -->
          <div class="item">
            <img src="../assets/img/team/SathyaBanu.png"><span class="caption">Sathya Banu,<br>
              HR & Admin  Executive </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/NaveenAnbu.png"><span class="caption">Naveen Anbu,<br> Member
              of TaaSkforce </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/AdarshVenkatesh.png"><span class="caption">Adarsh
              Venkatesh,<br> Member of TaaSkforce </span>
          </div>
          <!-- <div class="item"><img src="../assets/img/team/VeenaPBarkur.png"><span class="caption">Veena P Barkur,<br>
              Member of TaaSkforce </span></div> -->
          <div class="item">
            <img src="../assets/img/team/KarthikeyanM.png"><span class="caption">Karthikeyan M,<br>
              Member of TaaSkforce </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/PrashantKumarPrasad.png"><span class="caption">Prashant
              Kumar Prasad,<br> Member of TaaSkforce </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/PoojaLingawar.png"><span class="caption">Pooja Lingawar,<br>
              USA </span>
          </div>

          <div class="item">
            <img src="../assets/img/team/sanjay.jpg"><span class="caption">Sanjay Khati,<br>
              Solutions Architect - Security & Compliance </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/sreekant.jpg"><span class="caption">Sreekanth S N,<br>
              Solutions Architect - Security & Compliance </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/rahul.jpg"><span class="caption">Rahul A Kamble,<br>
              Tech Lead </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/thanigaivn.jpg"><span class="caption">Thanigaivelan Ranganathan,<br>
              Network Engineer </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/kartik.jpg"><span class="caption">Karthik Srinivas, <br>
              Consultant - NOC </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/viresh.jpg"><span class="caption">Viresh Vallabh Rao,  <br>
              Consultant - NOC </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/mohammad.jpg"><span class="caption">Mohammed Fazil M,  <br>
              Consultant - NOC </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/krishna.jpg"><span class="caption">Krishna Das MH,    <br>
              Project Manager </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/damodar.jpg"><span class="caption">Damodar Yadav,  <br>
              System Administrator </span>
          </div>
          <div class="item">
            <img src="../assets/img/team/kristapati.jpg"><span class="caption">Kristipati Praveen Kumar Reddy,  <br>
              Sr. DevOps Engineer</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/fei.jpg"><span class="caption">Fei Ren,   <br>
              Data Center Engineer</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/vanessa.jpg"><span class="caption">Vanessa Gong, <br>
              Executive Assistant</span>
          </div>

          <!--New -->
          <div class="item">
            <img src="../assets/img/team/sumit.jpg"><span class="caption">Sumit Ghosal, <br>
              Member of TaaSkforce</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/prasann.jpg"><span class="caption">Prasann R Patel, <br>
              Member of TaaSkforce</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/chetan.jpg"><span class="caption">Chetan N, <br>
              Member of TaaSkforce</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/raghvendra.jpg"><span class="caption">Raghavendra V Mujumdar, <br>
              Member of TaaSkforce</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/abhishek.jpg"><span class="caption">Abhishek S, <br>
              Member of TaaSkforce</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/kartheek.jpg"><span class="caption">Kartheek Kumar, <br>
              Member of TaaSkforce</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/kamalesh.jpg"><span class="caption">Kamalesh Patil, <br>
              Member of TaaSkforce</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/praveen.jpg"><span class="caption">Praveen DM, <br>
              Member of TaaSkforce</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/puneetha.jpg"><span class="caption">Puneetha H R, <br>
              Member of TaaSkforce</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/vikas.jpg"><span class="caption">Vikas Bijapur, <br>
              Member of TaaSkforce</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/pavan.jpg"><span class="caption">Pavan Chintham, <br>
              Software Solutions Lead</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/user-profile-1.jpg"><span class="caption">Paddy P, <br>
              Senior Infrastructure Architect</span>
          </div>
          <div class="item">
            <img src="../assets/img/team/user-profile-2.jpg"><span class="caption">Riny George, <br>
              DevOps & Cloud Engineer</span>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="clearfix"></div>

<app-footer></app-footer>