import { HomeComponent } from './modules/home/home.component';
import { CarouselSliderComponent } from './shared/components/carousel-slider/carousel-slider.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ContentsliderComponent } from './shared/components/contentslider/contentslider.component';
import { ClientlogoComponent } from './shared/components/clientlogo/clientlogo.component';
import { LinewavesDirective } from './shared/components/linewaves.directive';
import { TestimonialsliderComponent } from './shared/components/testimonialslider/testimonialslider.component';
import { OfferingsComponent } from './modules/offerings/offerings.component';
import { TechnologypartnersignupComponent } from './modules/technologypartnersignup/technologypartnersignup.component';
import { ChannelpartnersignupComponent } from './modules/channelpartnersignup/channelpartnersignup.component';
import { SupportloginComponent } from './modules/supportlogin/supportlogin.component';
import { HashLocationStrategy, LocationStrategy} from '@angular/common';
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JoblistComponent } from './modules/careers/joblist/joblist.component';
import { JobdetailsComponent } from './modules/careers/jobdetails/jobdetails.component';
import { JobapplyComponent } from './modules/careers/jobapply/jobapply.component';
import { LocationComponent } from './modules/location/location.component';
import { PrivacypolicyComponent } from './modules/privacypolicy/privacypolicy.component';
import { RequestformComponent } from './modules/requestform/requestform.component';
import { RequestformcontactComponent } from './modules/requestformcontact/requestformcontact.component';
import { AboutComponent } from './modules/about/about.component';
import { TaasComponent } from './modules/taas/taas.component';
import { ResourcesComponent } from './modules/resources/resources.component';
import { OpscaleComponent } from './modules/opscale/opscale.component';
import { MoaxyComponent } from './modules/moaxy/moaxy.component';
import { ContactModalComponent } from './shared/components/contact-modal/contact-modal.component';
import { OpscaleBackupComponent } from './modules/opscale-backup/opscale-backup.component';
// import { Taas1Component } from './modules/taas1/taas1.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    routingComponents,
    CarouselSliderComponent,
    HomeComponent,
    ContentsliderComponent,
    ClientlogoComponent,
    LinewavesDirective,
    TestimonialsliderComponent,
    OfferingsComponent,
    TechnologypartnersignupComponent,
    ChannelpartnersignupComponent,
    SupportloginComponent,
    JoblistComponent,
    JobdetailsComponent,
    JobapplyComponent,
    LocationComponent,
    PrivacypolicyComponent,
    RequestformComponent,
    RequestformcontactComponent,
    AboutComponent,
    TaasComponent,
    ResourcesComponent,
    OpscaleComponent,
    MoaxyComponent,
    ContactModalComponent,
    OpscaleBackupComponent
    // Taas1Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CarouselModule ,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      autoDismiss:true,
      timeOut:4000,
      maxOpened:1
    }),
  ],
  providers: [{provide: LocationStrategy, useClass:HashLocationStrategy}],
  // providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent],
})
export class AppModule { }
