import { Component, OnInit } from '@angular/core';
import {RoleListData} from '../../../shared/components/mock/mockjobrole';
import { Router } from '@angular/router';
import * as $ from 'jquery';
declare const loadAllData:any;
@Component({
  selector: 'app-joblist',
  templateUrl: './joblist.component.html',
  styleUrls: ['./joblist.component.css']
})
export class JoblistComponent implements OnInit {
   roleName;
   roleId;
  constructor(private route:Router) { }

  ngOnInit(): void {

    this.roleName = RoleListData.Rolelist.name;
    loadAllData();
    
   
    $(window).scrollTop(0); 
  }

  jobList(id){
    // console.log(id.target.id);
    this.roleId = id.target.id;
    this.route.navigate(['/jobdetails'], { queryParams: { Id: this.roleId } });

    
 }

}
