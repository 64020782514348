import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clientlogo',
  templateUrl: './clientlogo.component.html',
  styleUrls: ['./clientlogo.component.css']
})
export class ClientlogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
