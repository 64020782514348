import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
// import { SMTPClient } from 'emailjs';

declare const loadAllData:any;
declare const loadLineWaves: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  // contactForm: FormGroup;
  dropDown;
  contactForm = new FormGroup({
    Name: new FormControl(),
    Email: new FormControl(),
    Phone: new FormControl(),
    Website: new FormControl(),
    Message: new FormControl(),
  });

  constructor(private toastr: ToastrService, private http: HttpClient) {

  }


  ngOnInit(): void {
    loadAllData();
    loadLineWaves();
    $(window).scrollTop(0);
  }


  submitForm(){
        this.http.post('http://69.25.57.29/assets/rest/contactus', this.contactForm.value).subscribe(
        (response: any) => {
          if(response.status!="Failed"){
            this.dropDown = response.RECORDS;
            this.toastr.success(response.msg);
          }else{
            this.toastr.error(response.msg);
          }

        },
        (error: any) => {
        });

  }

  scrollBelow(el: HTMLElement){
     el.scrollIntoView();
  }

}
