import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';

declare const loadAllData:any;

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.css']
})
export class ContactModalComponent implements OnInit {

  // contactForm: FormGroup;
  dropDown;
  contactForm = new FormGroup({
    Name: new FormControl(),
    Email: new FormControl(),
    Phone: new FormControl(),
    Website: new FormControl(),
    Message: new FormControl(),
  });

  constructor(private toastr: ToastrService, private http: HttpClient) {

  }

  ngOnInit(): void {
    loadAllData();
  }

  submitForm(){
        console.log("inside submit form", this.contactForm.value);
        // this.http.post('http://69.25.57.29/assets/rest/contactus', this.contactForm.value).subscribe(
        // (response: any) => {
        //   if(response.status!="Failed"){
        //     this.dropDown = response.RECORDS;
        //     this.toastr.success(response.msg);
        //   }else{
        //     this.toastr.error(response.msg);
        //   }
        //
        // },
        // (error: any) => {
        // });

  }

}
