import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import * as $ from 'jquery';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  CurrentRoute
  constructor(private Route:Router) { }

  ngOnInit(): void {
   this.CurrentRoute = (this.Route.url).split('/')[1];
  }

}
