export const RoleListData={
    Rolelist:{
        name:[
            {role:"Windows Administrator", id:"1", Date:"July 10, 2020", btn:"More Details"},
            // {role:"Cloud Operations Administrator", id:"2", Date:"July 10, 2020", btn:"More Details"},
            // {role:"Lead - DevOps", id:"3", Date:"July 10, 2020", btn:"More Details"},
            {role:"NOC Engineer", id:"4", Date:"July 10, 2020", btn:"More Details"},
            {role:"Linux Administrator", id:"5", Date:"July 10, 2020", btn:"More Details"},
            {role:"Cloud Engineer", id:"6", Date:"July 10, 2020", btn:"More Details"},
            {role:"Network Engineer", id:"7", Date:"July 10, 2020", btn:"More Details"},
            {role:"Desktop Support Engineer", id:"8", Date:"July 10, 2020", btn:"More Details"}
        ]
    }

}
