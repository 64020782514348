import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
declare const loadAllData:any;
@Component({
  selector: 'app-jobapply',
  templateUrl: './jobapply.component.html',
  styleUrls: ['./jobapply.component.css']
})
export class JobapplyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    loadAllData();
    
   
    $(window).scrollTop(0); 
  }

}
