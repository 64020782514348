<header class="hero-area inner-page-header taas ptb-c-100 main--header" data-stellar-background-ratio="0.5">
  <app-navbar></app-navbar>
  <div class="container">
    <div class="row justify-content-left">
      <div class="col-md-10 col-lg-10">
        <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
          <br><br><br>
          <h1 class="text-white mb-0 pt-3">Managed Cloud - TaaS</h1>
          <br><br>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="clearfix"></div>
<!---- Content Section ---->
<section class="inner__section">
  <!-- All Services Section -->
  <section class="section Resources pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 Services-tab  item">
          <div class="folded-corner service_tab_1">
            <div class="text">
              <i class="mdi mdi-shield-lock"></i>
              <div class="item-title">
                <a class="section-title has-text-blue custom-heading smooth-goto" href="#secure_network"> Secure Network</a>
              </div>
              <p>
                Enterprise like security from day one for your entire team.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 Services-tab item">
          <div class="folded-corner service_tab_1">
            <div class="text">
              <i class="mdi mdi-server-network"></i>
              <div class="item-title">
                <a class="section-title has-text-blue custom-heading smooth-goto" href="#server_hosting"> Server Hosting </a>
              </div>
              <p>
                Affordable server hosting options for your VP of Engineering.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 Services-tab item">
          <div class="folded-corner service_tab_1">
            <div class="text">
              <i class="mdi mdi-cloud-check"></i>
              <div class="item-title">
                <a class="section-title has-text-blue custom-heading smooth-goto" href="#managed_cloud"> Managed Cloud </a>
              </div>
              <p>
                Scale your infrasructure with our Hybrid Cloud Platform and expertise.
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 Services-tab item">
          <div class="folded-corner service_tab_1">
            <div class="text">
              <i class="mdi mdi-desktop-classic"></i>
              <div class="item-title">
                <h3 class="section-title has-text-blue custom-heading"> Service Four</h3>
              </div>
              <p>
                They is the dummy text example for our Service four.
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>

  </section>

  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-xs-12" id="secure_network">
          <h5 class="has-text-blue custom-heading">Secure Network</h5>
          <div class="section-header mb-3">
            <hr class="lines wow zoomIn ml-0" data-wow-delay="0.3s">
          </div>

          <h5 class="topic_heading">Enterprise like security from day one for your entire team.</h5>
          <p class="section-subtitle wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s">
            Secure your team. Enable your remote and onsite teams to work securely. And most importantly secure your IP, no matter where your developers are. We provide you with a secure network.
          </p>
          <h5 class="topic_heading">Basic Network Setup :</h5>
          <div class="service-sec-1 content-banner pt-0">
            <div class="desc">
              <ul>
                <li>1 Public IP</li>
                <li>Segregated VLAN with 256 Dedicated Block of IP address</li>
                <li>VPN for 5 users. 5 devices per user</li>
                <li>256 bit encryption with IPSEC & OpenVPN protocol</li>
              </ul>
            </div>
          </div>
          <h5 class="topic_heading">$150 per month</h5>
        </div>
        <div class="col-md-6 col-xs-12" id="server_hosting">
          <h5 class="has-text-blue custom-heading">Server Hosting</h5>
          <div class="section-header mb-3">
            <hr class="lines wow zoomIn ml-0" data-wow-delay="0.3s">
          </div>
          <h5 class="topic_heading">Affordable server hosting options for your VP of Engineering.</h5>
          <p class="section-subtitle wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s">
            Sleep easier. We provide you with enterprise grade servers and host them in a secure, high availability data center.
            <br>
            <!-- <b>Save more than 60% </b> when compared to AWS Reserved Instances. Compare with AWS EC2 m5.8xlarge instance. -->
          </p>
          <h5 class="topic_heading">Bare Metal Server (Base Configuration):</h5>

          <div class="service-sec-1 content-banner pt-0">
            <div class="desc">
              <ul>
                <li>
                  Intel Xeon Processor E5 series
                </li>
                <li>
                  32 Core, 128GB RAM, 1 TB HDD Storage
                </li>
                <li>
                  OS Centos Red Hat/ Ubuntu/ VMware6
                </li>
                <li>
                  1 TB per month data transfer
                </li>
                <li>
                  10 Gbps network port
                </li>
                <li>
                  Optional IPMI Access for Out of Band Management
                </li>
              </ul>
            </div>
          </div>

          <h5 class="topic_heading mb-0 pb-0">
            $250 per month
          </h5>
          <p class="is-small mb-2"> <span>Costs provided are estimates for hosting in the US and subject to change.</span></p>

          <p class="is-small">
            We offer additional server configurations as well as GPU's. Contact us for more info.
          </p>
        </div>
      </div>
    </div>
  </section>

  <!---- Managed Cloud Section ---->
  <section class="section Resources" id="managed_cloud">
    <div class="container">
      <div class="section-header text-left">
        <h2 class="section-title has-text-blue custom-heading wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s">Managed Cloud</h2>
        <hr class="lines wow zoomIn ml-0" data-wow-delay="0.3s">
      </div>
      <div class="row">
        <div class="col-md-12">
          <h5 class="topic_heading">Scale your infrasructure with our Hybrid Cloud Platform and expertise.</h5>
          <p class="section-subtitle wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s">
            Keep costs under control. Public cloud providers take out the complexity of building your environment. But they surprise you with compicated invoices and runaway costs as you scale.

            Our team of experts, global platform and simpler pricing will deliver upwards of <b>30% </b>savings when compared to public cloud.

            Compare with AWS EC2 m5.8xlarge instances & other line items.
          </p>
        </div>
        <!-- //calculator part -->
        <div class="col-md-6 col-sm-12 pr-0" id="taas_calc">
          <br>
          <h5 class="topic_heading">Calculate your savings with Opsara</h5>

          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="bg-info text-white">No. of Servers Needed
                  <div class="tool-tip">
                    <i class="mdi mdi-information"></i>
                    <span class="tool-tiptext">Server (vCPU 32, 128GB RAM, 1TB HDD, 1TB Internet Outbound Data pm)</span>
                  </div>
                  <div class="is-small">
                    (Max. 10 servers for this simplified calculator)
                  </div>
                </th>
                <th width="21%" class="bg-warning text-white">
                  <input class="form-control" type="number" min="0" [(ngModel)]="s" #name="ngModel" (keypress)="calculate($event, 's')" (change)="calculate($event, 's')" (value)="s" placeholder="Enter no. of servers needed">
                </th>
              </tr>
              <tr>
                <th class="bg-info text-white">Add on RAM GB <span class="is-small ml-2">(Max: {{s * 384}}) </span> </th>
                <th class="bg-warning text-white">
                  <input class="form-control" type="number" min="0" [(ngModel)]="ram" #name="ngModel" (keypress)="calculate($event, 'ram')" (change)="calculate($event, 'ram')" (value)="ram" placeholder="Enter Additional RAM">
                </th>
              </tr>
              <tr>
                <th class="bg-info text-white">Add on HDD TB <span class="is-small ml-2">(Max: {{s * 9}}) </span> </th>
                <th class="bg-warning text-white">
                  <input class="form-control" type="number" min="0" [(ngModel)]="hdd" #name="ngModel" (keypress)="calculate($event, 'hdd')" (change)="calculate($event, 'hdd')" (value)="hdd" placeholder="Enter HDD">
                </th>
              </tr>
              <tr>
                <th class="bg-info text-white">Add on Shared Storage TB <span class="is-small ml-2">(Max: {{ss_max}}) </span> </th>
                <th class="bg-warning text-white">
                  <input class="form-control" type="number" min="0" [(ngModel)]="ss" #name="ngModel" (keypress)="calculate($event, 'ss')" (change)="calculate($event, 'ss')" (value)="ss" placeholder="Enter shared storage">
                </th>
              </tr>
              <tr>
                <th class="bg-info text-white">Add on Monthly Egress Data TB <span class="is-small ml-2">(Max: {{egress_max}}) </span> </th>
                <th class="bg-warning text-white">
                  <input class="form-control" type="number" min="0" [(ngModel)]="egress" #name="ngModel" (keypress)="calculate($event, 'egress')" (change)="calculate($event, 'egress')" (value)="egress" placeholder="Enter Monthly Egress Data">
                </th>
              </tr>

            </tbody>
          </table>
          <p> <span class="is-small">Costs shown are for deployments in US data center only and subject to change. </span></p>
        </div>
        <div class="col-md-6 col-sm-12">
          <br>
          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingOne">
                <h4 class="panel-first-heading"> <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne"> Opsara savings per month: &nbsp;&nbsp;<span *ngIf="s"
                      class="highlight">{{opsaraSaving | number : '1.2-2'}}%</span> </a> </h4>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingTwo">
                <h4 class="panel-title"> <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> AWS Spend per month: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span
                      *ngIf="s" class="highlight">$ {{grandTotalAws}}</span> </a> </h4>
              </div>
              <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                <div class="panel-body">
                  <table class="table table-bordered">
                    <tr>
                      <th>Cloud Management Costs</th>
                      <th width="100"> ${{cloudMgmtCostAws}}</th>
                    </tr>

                    <tr>
                      <th>
                        Cloud components costs
                      </th>
                      <th width="100">${{awsPM}}</th>
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Server (vCPU 32, 128GB RAM, 1TB HDD, 1TB Internet Outbound Data pm)</td>
                      <!-- <td>{{ sereverAws }}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Add on RAM costs</td>
                      <!-- <td>{{ ramCostAws }}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Add on HDD costs</td>
                      <!-- <td>{{hddAws}}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Add on Sahred Storage costs</td>
                      <!-- <td>{{ssAws}}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Add on Internet Egress costs</td>
                      <!-- <td>{{egressAws}}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Firewall </td>
                      <!-- <td>{{firewallAws}}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">NAT Gateway</td>
                      <!-- <td>{{natAws}}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Load Balancer</td>
                      <!-- <td>{{loadBalancerAws}}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Monitoring Metrics</td>
                      <!-- <td>{{moniMetricesAws}}</td> -->
                    </tr>

                  </table>
                </div>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingThree">
                <h4 class="panel-title"> <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Opsara Spend per month: &nbsp;&nbsp;&nbsp; <span *ngIf="s"
                      class="highlight">$ {{opsaraCloudPM}}</span> </a> </h4>
              </div>
              <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                <div class="panel-body">
                  <table class="table table-bordered">
                    <tr>
                      <th>Cloud Management Costs</th>
                      <th width="100">Incl</th>
                    </tr>
                    <tr>
                      <th>
                        Cloud components costs
                      </th>
                      <th width="100">${{opsaraCloudPM}} </th>
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Server (vCPU 32, 128GB RAM, 1TB HDD, 1TB Internet Outbound Data pm)</td>
                      <!-- <td>{{ serverOpsara }}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Add on RAM costs</td>
                      <!-- <td>{{ ramCostOpsara }}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Add on HDD costs</td>
                      <!-- <td>{{hddOpsara}}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Add on Sahred Storage costs</td>
                      <!-- <td>{{ssOpsara}}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Add on Internet Egress costs</td>
                      <!-- <td>{{egressOpsara}}</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Firewall </td>
                      <!-- <td>incl</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">NAT Gateway</td>
                      <!-- <td>incl</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Load Balancer</td>
                      <!-- <td>incl</td> -->
                    </tr>

                    <tr class="is-small">
                      <td colspan="2">Monitoring Metrics</td>
                      <!-- <td>incl</td> -->
                    </tr>

                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row cta-section mx-0 px-2">
            <div class="col">
              <h5 class="topic_heading mb-0 pb-0">Explore Hybrid Cloud Options!</h5>
              <p class="subtitle">Secure, simplify and save with Opsara...</p>
            </div>
            <div class="col-md-4 col-sm-5 text-right">
              <button type="button" name="button" class="btn btn-common mt-2" data-wow-duration="1000ms" data-wow-delay="400ms" data-toggle="modal" data-target="#exampleModal">
                Know more  <i class="fa fa-chevron-right ml-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="clearfix"></div>
</section>
<!---- Footer Section---->
<div class="clearfix"></div>
<app-footer></app-footer>
