import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
declare const loadAllData:any;
@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    loadAllData();
  }

}
