<header class="hero-area inner-page-header ptb-100 main--header" data-stellar-background-ratio="0.5">
  <app-navbar></app-navbar>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-7">
        <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
          <h1 class="text-white mb-0">Offerings</h1>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="clearfix"></div>
<!---- Content Section ---->
<section class="inner__section">

  <div class="container">
    <div class="col-md-12 col-xs-12 col-sm-12" align="center">
      <ul class="nav nav-pills mb-3 top-tab-content" id="pills-tab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="tservice-pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">TAAS – TECHNOLOGY AS A SERVICES</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="moaxy-pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">MOAXY</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="Link2Cloud-pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">LINK2CLOUD</a>
        </li>
      </ul>
    </div>
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="tservice-pills-home-tab">

        <!---Tab One--->
        <div class="row">
          <div class="col-md-6 col-xs-12 col-sm-12" align="center">
            <img src="../assets/img/o-1.jpg" class="img-fluid top_img_pos_o" />
          </div>

          <div class="col-md-6">

            <div class="service-topic">
              <h4>TaaS - Affordable Enterprise Grade Infrastructure &amp; Expertise</h4>
              <p>
                Your company’s success propels your business expansion. At the same time, it also
                poses a big challenge - to manage and maintain your company’s growing technology requirements. This demands your time and energy, with huge capital investment to
                expand your tech infrastructure that can support your growing business.
              </p>
            </div>

            <div class="service-topic-1">
              <h4>TaaS empowers you</h4>
              <p>
                As a future-focused IT services & solutions provider, we understand your requirements
                and deliver customized, cost-effective solutions to fit your organization’s specific needs.
                So you pay only for what you use – with the freedom to scale up/down as per your need.
              </p>

              <p>
                Our services include customer-configured cloud-services along with hardware solutions.
                We combine best-in-class DevOps and IT Infrastructure management practices, <br />to provide
                a secure, reliable platform built to scale with your technology needs, at an optimal cost.

              </p>
            </div>
          </div>

        </div>

        <div class="clearfix"></div>
        <!-- <div class="col-md-12">
					<p align="center" class="c_img_pos">
							<img src="../assets/img/s-icon.png"  class="img-fluid" />
					</p>
			 </div>
			 <div class="clearfix"></div> -->
        <div class="row">
          <div class="col-md-6">

            <div class="service-sec-1 content-banner">
              <h4>Benefits</h4>
              <div class="desc benefit_txt">
                <ul>
                  <li>Reduced ongoing cost</li>
                  <li>Improved business continuity</li>
                  <li>Swift disaster recovery</li>
                  <li>Better stability and reliability</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-6" align="center">
            <img src="../assets/img/o-2.jpg" class="img-fluid top_img_pos" />
          </div>

        </div>
        <div class="col-md-12 c_txt_img_h" align="center">
          <h4>Service Offerings</h4>
          <img src="../assets/img/s-o-1.jpg" class="img-fluid" />
        </div>

        <div class="clearfix"></div>
        <div class="inner-tab-menu">
          <div class="nav flex-column nav-pills left-tab-content-menu" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link active" id="v-pills-manage-tab" data-toggle="pill" href="#v-pills-manage" role="tab" aria-controls="v-pills-manage" aria-selected="true">Managed Services and consulting</a>
            <a class="nav-link" id="v-pills-security-tab" data-toggle="pill" href="#v-pills-security" role="tab" aria-controls="v-pills-security" aria-selected="false">Security as a Services</a>
            <a class="nav-link" id="v-pills-hypercloud-tab" data-toggle="pill" href="#v-pills-hypercover" role="tab" aria-controls="v-pills-hypercover" aria-selected="false">Hyper-Converged Cloud</a>
            <a class="nav-link" id="v-pills-managenoc-tab" data-toggle="pill" href="#v-pills-managenoc" role="tab" aria-controls="v-pills-managenoc" aria-selected="false">Managed NOC</a>
          </div>
        </div>

        <div class="inner-tab_wrap">
          <div class="tab-content left-tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-manage" role="tabpanel" aria-labelledby="v-pills-manage-tab">
              <div class="content-banner">
                <div class="img"><img src="../assets/img/c-bg.jpg" class="img-fluid" /></div>
                <h4>Managed Services and Consulting</h4>

                <p>
                  When you place your trust on us to manage your IT Infrastructure (server, network,
                  standard applications, virtual machines <br />and end user computing), we promise and
                  ensure zero compromise in operational efficiency and quality of service.<br /> This results
                  in cost advantages and optimal utilization of resources, which in turn delivers
                  enhanced efficiencies in your operations.
                </p>
                <div align="center"><img src="../assets/img/m-o-1.jpg" class="img-fluid" /> </div>
                <h4>Managed Services – Benefits</h4>
                <div class="desc benefit_txt col-md-7 pad-lft-rht-0">
                  <ul>
                    <li>Reduce costs</li>
                    <li>Increase efficiency</li>
                    <li>Minimize downtime</li>
                    <li>Minimize risk</li>
                    <li>Enjoy high level tech support</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="v-pills-security" role="tabpanel" aria-labelledby="v-pills-security-tab">
              <div class="content-banner">
                <div class="img"><img src="../assets/img/c-bg.jpg" class="img-fluid" /></div>
                <h4>Security as a Service</h4>

                <p>
                  Our SaaS expertise delivers yet another big relief for your IT team. Because, they
                  will no longer have to devote long hours to check and install virus protection
                  software and other security controls on each workstation, server or network.
                </p>
                <p>
                  Our SaaS specialists are security experts – with proven credentials in providing
                  end-to-end IT security services which includes VA/PT, Dark web monitoring and
                  end-user security services.
                </p>
                <h4>Service Offerings</h4>
                <div align="center"><img src="../assets/img/s-o-b-1.jpg" class="img-fluid" /> </div>
                <h4>Vulnerability Assessment</h4>
                <p>
                  A detailed, step-by-step assessment of risks involving your network assets, security systems, MS-Exchange Platform and SQL Server, and detect any potential threat.
                </p>

                <h4>DNS Security</h4>
                <p>
                  Detects and blocks malware or phishing attempts from malicious websites. Prevents web and non-web callbacks from compromised systems. Enables web filtering using 85+ domain content categories. Conducts deeper inspection of URLs and
                  files.
                </p>

                <h4>Virtual System Administrator</h4>
                <p>
                  Workstation and server support with remote access and integrated ticketing. Fully Automated Patching and updating for Windows, Mac, and third-party applications.
                  Antivirus, antimalware, and ransomware protection. Advanced network monitoring
                  Audit and compliance reporting.
                </p>

                <h4>Dark Web Monitoring</h4>
                <p>
                  A threat monitoring tool that provides 24x7x365 monitoring risks related to company’s email credentials on the Dark Web. Stringent vigil against millions of sources including botnets, criminal chat rooms, peer-to-peer networks,
                  malicious websites and blogs.
                </p>

                <h4>Data Leak Prevention</h4>
                <p>
                  World’s first & only 4th generation DLP solution providing automated and real-time
                  controls on visibility, prevention and compliance. Simple, fast and easy deployment.

                </p>
              </div>
            </div>
            <div class="tab-pane fade" id="v-pills-hypercover" role="tabpanel" aria-labelledby="v-pills-hypercloud-tab">
              <div class="content-banner">
                <div class="img"><img src="../assets/img/c-bg.jpg" class="img-fluid" /></div>
                <h4>Hyper-Converged Cloud</h4>

                <p>
                  Hyper-convergence is an IT framework that combines storage, computing and networking into a single system in an effort to reduce data center complexity and increase scalability.
                </p>
                <p>
                  Hyper-converged infrastructure delivers simplicity and flexibility when compared with legacy solutions. The integrated storage systems, servers and networking switches are designed to be managed as a single system. This enables ease
                  of use, greater scalability
                  and resource efficiency.

                </p>
                <h4>Service Offerings</h4>
                <div align="center"><img src="../assets/img/h-s-1.jpg" class="img-fluid" /> </div>
                <h4>Hyperconvergence – Benefits</h4>
                <div class="desc benefit_txt col-md-7 pad-lft-rht-0">
                  <ul>
                    <li>Managed as a single system</li>
                    <li>Greater scalability</li>
                    <li>Better resource capability</li>
                    <li>Cost savings on space and labor</li>
                  </ul>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="tab-pane fade" id="v-pills-managenoc" role="tabpanel" aria-labelledby="v-pills-managenoc-tab">
              <div class="content-banner">
                <div class="img"><img src="../assets/img/c-bg.jpg" class="img-fluid" /></div>
                <h4>Managed NOC</h4>

                <p>
                  Our Managed NOC is a fully staffed and secured Network Operations Centre that provides proactive device and network monitoring/alerting for your organization.
                </p>
                <p>
                  As a 24/7 dedicated and hybrid Network Operating Centre, we function as an extension of your support team, staffed round-the-clock by network management professionals with over 20 years of experience in managing critical networks.
                </p>
                <h4>Managed NOC – Service Offerings</h4>
                <div align="center"><img src="../assets/img/m-noc-s-1.jpg" class="img-fluid" /> </div>
                <h4>Managed NOC – Benefits</h4>
                <h4 class="sub_head_txt">24/7/365 physical NOC</h4>
                <p>
                  An extension of your support team, staffed round-the-clock by network management professionals with over 20 years of experience in managing critical networks.
                </p>

                <h4 class="sub_head_txt">ITSM Trouble Ticketing System</h4>
                <p>
                  Easy ticket creation, escalation, and resolution in support of incident management.
                </p>

                <h4 class="sub_head_txt">Cost Savings</h4>
                <p>
                  No need to hire, train, or retain expensive network management staff. Make more informed, proactive decisions for greater operational and capital efficiencies.
                </p>

                <h4 class="sub_head_txt">Proactive Prioritization</h4>
                <p>
                  A team of NOC professionals that will pre-emptively assess alarms and alerts for severity. Minimizing the need for an organization’s resources to be engaged for minor, non-disruptive instances.
                </p>

              </div>
            </div>
            <div class="tab-pane fade" id="v-pills-reselling" role="tabpanel" aria-labelledby="v-pills-reselling-tab">5</div>
          </div>
        </div>

        <!---Tab One End--->

      </div>
      <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="moaxy-pills-profile-tab">
        <!---Tab Two--->

        <div class="row">
          <div class="col-md-7" align="center">
            <img src="../assets/img/o-1.jpg" class="img-fluid top_img_pos_o_m" />
          </div>

          <div class="col-md-5">
            <div class="service-topic">
              <h4>MOAXY – Managed Appliance for the Edge</h4>
              <p>
                As your business grows to multiple offices, the challenges to keep the infrastructure securely and reliably connected also grows.
              </p>
            </div>

            <div class="service-topic-1">
              <!-- <h4>TaaS empowers you</h4> -->
              <p>
                Our MOAXY is a combination of services which comprises features like SD WAN, End-user protection, Optimization and acceleration, Firewall, IDS, IPS and many more services.
              </p>
            </div>

          </div>
        </div>

        <div class="content-banner">
          <div align="center"><img src="../assets/img/moaxy.jpg" class="img-fluid" /> </div>

          <div class="row">

            <div class="col-md-6 col-xs-12 col-sm-6">
              <h4>Our MOAXY (Mother of all ProXYies) solution provides you with an on-premise solution
                in a single box to address:</h4>
              <div class="m_list_point">
                <p>Managed Security by implementing DLP, phishing, malware & virus protection, managed firewall & UTM gateways (Unified Threat Management)</p>
                <p>Secure Connectivity including enterprise/cloud VPN, Wi-Fi, load balancing,
                  SD WAN and visibility plus control of network traffic.
                </p>
                <p>
                  Access and authorization control using ACLs and policies. We provide this as an appliance with subscription pricing. <br />This eliminating the need to procure and set up separate servers and licenses for managing these different
                  aspects.
                </p>

              </div>

            </div>

            <div class="col-md-6  col-xs-12 col-sm-6" align="center">
              <img src="../assets/img/m-o-1-1.jpg" class="img-fluid" />
            </div>

          </div>



          <h4 align="center">MOAXY Services</h4>
          <div align="center"><img src="../assets/img/moaxy-img.jpg" class="img-fluid" /> </div>

          <div class="col-md-12 bg_m_list hide_xs">
            <div class="row bg_w_m">
              <div class="col-md-4">
                <h4><span><img src="../assets/img/icon-m-1.png"></span>Chief Financial Officer(CFO)</h4>
              </div>
              <div class="col-md-4">
                <h4><span><img src="../assets/img/icon-m-2.png"></span>Chief Information Officer(CIO)</h4>
              </div>
              <div class="col-md-4">
                <h4><span><img src="../assets/img/icon-m-3.png"></span>Chief Executive Officer(CEO)</h4>
              </div>
            </div>
            <div class="row pad_bot_30">
              <div class="col-md-4">
                <ul class="m_list_wrap">
                  <li>Zero Capex</li>
                  <li>Reduce TCO</li>
                  <li>Pay as you Grow/ On-Demand</li>
                  <li>Simplify your operations</li>
                  <li>Simplify your infrastructure</li>
                  <li>Standardize your infrastructure across all Geographical regions (branches/campuses etc.)</li>
                  <li>Eliminate integration costs</li>
                </ul>
              </div>
              <div class="col-md-4">
                <ul class="m_list_wrap remove_dot">
                  <li>Single interface to manage your complex network, compute and security
                  </li>
                  <li>Provide advanced functionality in the areas of:</li>
                  <li>
                    <ul>
                      <li>Security</li>
                      <li>Performance</li>
                      <li>Visibility</li>
                      <li>Control</li>
                      <li>Storage</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">

                <ul class="m_list_wrap">
                  <li>Scalable & adaptable infrastructure</li>
                  <li>Reduce solution implementation times</li>
                  <li>Reduce technology refresh costs</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="d-md-none col-md-12 bg_m_list">
            <div class="row pad_bot_30">
              <div class="col-md-4">
                <div class="col-md-12">
                  <h4><span><img src="../assets/img/icon-m-1.png"></span>Chief Financial Officer(CFO)</h4>
                </div>
                <ul class="m_list_wrap">
                  <li>Zero Capex</li>
                  <li>Reduce TCO</li>
                  <li>Pay as you Grow/ On-Demand</li>
                  <li>Simplify your operations</li>
                  <li>Simplify your infrastructure</li>
                  <li>Standardize your infrastructure across all Geographical regions (branches/campuses etc.)</li>
                  <li>Eliminate integration costs</li>
                </ul>
              </div>

              <div class="col-md-4">
                <div class="col-md-12">
                  <h4><span><img src="../assets/img/icon-m-2.png"></span>Chief Information Officer(CIO)</h4>
                </div>
                <ul class="m_list_wrap remove_dot">
                  <li>Single interface to manage your complex network, compute and security
                  </li>
                  <li>Provide advanced functionality in the areas of:</li>
                  <li>
                    <ul>
                      <li>Security</li>
                      <li>Performance</li>
                      <li>Visibility</li>
                      <li>Control</li>
                      <li>Storage</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <div class="col-md-12">
                  <h4><span><img src="../assets/img/icon-m-3.png"></span>Chief Executive Officer(CEO)</h4>
                </div>
                <ul class="m_list_wrap">
                  <li>Scalable & adaptable infrastructure</li>
                  <li>Reduce solution implementation times</li>
                  <li>Reduce technology refresh costs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!---Tab Three--->
      </div>
      <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="Link2Cloud-pills-contact-tab">

        <div class="row">
          <div class="col-md-6" align="center">
            <img src="../assets/img/o-1.jpg" class="img-fluid top_img_pos_o_m" />
          </div>

          <div class="col-md-6">
            <div class="service-topic">
              <h4>Link2Cloud</h4>
              <p>
                Enabling your Hybrid Cloud Cost Savings Strategy
              </p>
              <p>
                Take advantage of our global PoPs located at enterprise class Data Centers and our experts to provision and manage your infrastructure.
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-12 c_txt_img_h serv-off" align="center">
          <h4>Service Offerings</h4>
          <ul class="list-unstyled tech-feature-list">
            <li>Hybrid Cloud with Our Private Cloud</li>
            <li>Cloud Backup as a Service</li>
            <li>VDI (Virtual Desktop Infrastructure)</li>
          </ul>

        </div>
        <div class="content-banner">
          <h4>Benefits</h4>
          <div class="desc benefit_txt col-md-9 pad-lft-rht-0">
            <ul>
              <li>Save costs with Hybrid cloud </li>
              <li>Eliminate your private cloud set up costs with our managed offering</li>
              <li>Increase business continuity</li>
              <li>Pay for only the resources you use</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <!---Tab Three End--->
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</section>
<div class="clearfix"></div>
<app-footer></app-footer>
