import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-carousel-slider',
  templateUrl: './carousel-slider.component.html',
  styleUrls: ['./carousel-slider.component.css']
})
export class CarouselSliderComponent implements OnInit {
  slidesStore:any = [];
  constructor() { }

  ngOnInit(): void {
    this.slidesStore = [
      {
        src:'../assets/img/b11.jpg',
        id:'1',
        alt:'1',
        // heading:'We build your Cloud <br/> from the ground up.',
        heading:'<br/><br/> Hybrid Cloud Solutions',
        subheading:'High Performance | Secure | Lower Costs'

    },
//     {
//       src:'../assets/img/banner4.jpg',
//       id:'2',
//       alt:'2',
//       title:'2',
//       heading:'We power your business<br/>to be future-ready.',
//       subheading:'We’ve got what it <span class="italic_txt">techs.</span>'
//
//   },
//   {
//     src:'../assets/img/banner-1.jpg',
//     id:'3',
//     alt:'3',
//     title:'3',
//     heading:'We slash your risks<br/>hiding in the Cloud.',
//     subheading:'With multiple silver linings.'
//
// }, {
//   src:'../assets/img/banner-2.jpg',
//   id:'4',
//   alt:'4',
//   title:'4',
//   heading:'We simplify your need<br/>for complex infrastructure.',
//   subheading:'<span class="italic_txt">Tech</span> IT easy.'
//
// }
];

  }



  customOptions: OwlOptions = {
    loop: true,
    autoplay:true,
    autoplaySpeed:1500,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    smartSpeed:2000,
    navSpeed: 1500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }

}
