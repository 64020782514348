<header class="hero-area inner-page-header ptb-100" data-stellar-background-ratio="0.5">  
    <app-navbar></app-navbar>
    <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-9">
                    <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                        <h1 class="text-white mb-0"><span>Support Login</span></h1>
                        </div>
                </div>
            </div>
        </div>          
    </header>
    <div class="clearfix"></div>

    <section class="inner__section">
	
        <div class="container">
          <div class="row">
                <iframe width="100%" height="600" src="https://bms.kaseya.com/gateway.aspx?enc=7YLgCdibNhPecC4fpwzGJq19RMVJOxq9jSWU%2bebRKiZuAzYga8k4%2bMZn4GKkYqTC">
                  
                </iframe>
          </div>
        </div>
    </section>
  <!-- Header Section Start -->
    <!-- Header Section End --> 
	<!-- <div class="login-wrapper">
            <div class="left-section">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12"><a routerLink="/home" class="navbar-brand login_logo"><img class="img-fulid" src="../assets/img/logo-TaaS.png" alt=""></a></div>
                  
                  <div class="login-banner">
                    <img src="../assets/img/login-1.jpg" />
                  </div>
                  
                  <p class="login_copy_wr">2020 © Opsara inc. All rights reserved.</p>
                </div>
              </div>
            </div>
            
            <div class="right-section">
               <h1>Login to the support portal</h1>
               
                   <form id="Contact_form" >
                     <div class="row">
                     
                     <div class="col-md-12"  align="center">
                         <div class="col-md-7">
                               <div class="form-group">
                                 <input type="text" class="form-control" id="name" name="name" placeholder="Email" autocomplete="off" >
                                 <div class="help-block with-errors"></div>
                               </div>  
                         </div>
                         
                         <div class="col-md-7">
                               <div class="form-group">
                                 <input type="password" class="form-control" id="name" name="name" placeholder="Password" autocomplete="off" >
                                 <div class="help-block with-errors"></div>
                               </div>  
                         </div>
                         
                         <div class="col-md-7">
                               <div class="form-group">
                                 <button  type="button" class="btn disable_btn btn-common login_btn">Login</button>
                               </div>  
                               <a href="javascript:void(0);" class="forget_pas pull-right">Forgot your password?</a>
                         </div>
                     </div>
                         
                     </div>
                 </form>
                 <div class="clearfix"></div>
                 <div class="container">
                   <div class="row">
                      <div class="col-md-4">
                         <ul class="l_social_icon">
                          <li>Login With&nbsp;:</li>
                            <li><a href="javascript:void(0);"><i class="fa fa-google" aria-hidden="true"></i></a></li>
                            <li><a href="javascript:void(0);"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        </ul>
                      </div>
                      
                        <div class="col-md-8 pull-right" align="right"><span class="signup_link">Do not have an account?<a href="javascript:void(0);">&nbsp;Sign up</a></span></div>
                   </div>
                 </div>
            </div>
         </div> -->