import { RequestformcontactComponent } from './modules/requestformcontact/requestformcontact.component';
import { RequestformComponent } from './modules/requestform/requestform.component';
import { PrivacypolicyComponent } from './modules/privacypolicy/privacypolicy.component';
import { LocationComponent } from './modules/location/location.component';
import { JobapplyComponent } from './modules/careers/jobapply/jobapply.component';
import { JobdetailsComponent } from './modules/careers/jobdetails/jobdetails.component';
import { JoblistComponent } from './modules/careers/joblist/joblist.component';
import { SupportloginComponent } from './modules/supportlogin/supportlogin.component';
import { ChannelpartnersignupComponent } from './modules/channelpartnersignup/channelpartnersignup.component';
import { TechnologypartnersignupComponent } from './modules/technologypartnersignup/technologypartnersignup.component';
import { OfferingsComponent } from './modules/offerings/offerings.component';
import { WhatwedoComponent } from './modules/whatwedo/whatwedo.component';
import { AboutComponent } from './modules/about/about.component';
import { TaasComponent } from './modules/taas/taas.component';
import { OpscaleComponent } from './modules/opscale/opscale.component';
import { OpscaleBackupComponent } from './modules/opscale-backup/opscale-backup.component';
import { MoaxyComponent } from './modules/moaxy/moaxy.component';
import { ResourcesComponent } from './modules/resources/resources.component';
import { HomeComponent } from './modules/home/home.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { Routes, RouterModule } from '@angular/router';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent
  // children: [
  //   {path: '', component: RepoBrowserComponent, data: { breadcrumb: 'subHomes'}}]
 },
  {path: 'whatwedo', component: WhatwedoComponent},
  {path: 'offerings', component: OfferingsComponent},
  {path: 'technologypartnersignup', component: TechnologypartnersignupComponent},
  {path: 'channelpartnersignup', component: ChannelpartnersignupComponent},
  {path: 'supportlogin', component: SupportloginComponent},
  {path: 'joblist', component: JoblistComponent},
  {path: 'jobdetails', component: JobdetailsComponent},
  {path: 'jobapply', component: JobapplyComponent},
  {path: 'location', component: LocationComponent},
  {path: 'about-us', component: AboutComponent},
  {path: 'taas', component: TaasComponent},
  {path: 'opscalestorage', component: OpscaleComponent},
  {path: 'opscalebackup', component: OpscaleBackupComponent},
  {path: 'moaxy', component: MoaxyComponent},
  {path: 'resources', component: ResourcesComponent},
  {path: 'privacy-policy', component: PrivacypolicyComponent},
  {path: 'request-form', component: RequestformComponent},
  {path: 'request-form-contact', component: RequestformcontactComponent},
];


@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [CommonModule, RouterModule.forRoot(routes, routerOptions)],
  // imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})



export class AppRoutingModule { }

export const routingComponents=[HomeComponent, WhatwedoComponent];
