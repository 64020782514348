<!-- Navbar Start -->
<nav class="navbar navbar-expand-lg fixed-top scrolling-navbar" name="navbar-header">
  <div class="container">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <a routerLink="/home" class="navbar-brand"><img class="img-fulid" src="../assets/img/Opsara.png" alt="" width="200" height="48"></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar" aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
        <i class="lnr lnr-menu"></i>
      </button>
    </div>
    <div class="collapse navbar-collapse" id="main-navbar">
      <ul class="navbar-nav mr-auto w-100 justify-content-end">

        <li class="nav-item">
          <a class="nav-link" routerLink="/home" routerLinkActive="active">Home</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" href="#" id="navbarDropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Solutions &nbsp;
            <i class="fa fa-chevron-down"></i>
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
            <a class="dropdown-item" routerLink="/opscalestorage" routerLinkActive="active">Storage - OpScale</a>
            <a class="dropdown-item" routerLink="/opscalebackup" routerLinkActive="active">Backup - OpScale</a>
            <a class="dropdown-item" routerLink="/moaxy" routerLinkActive="active">Edge Compute - Moaxy</a>
            <a class="dropdown-item" routerLink="/taas" routerLinkActive="active">Managed Cloud - TaaS</a>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link" routerLink="/resources">Resources</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" routerLink="/about-us" routerLinkActive="active">About Us</a>
        </li>

        <li class="nav-item">
          <!-- <button type="button" name="button" class="btn btn-common mx-2" data-toggle="modal" data-target="#exampleModal">
            Contact Us <i class="fa fa-envelope ml-1"></i>
          </button> -->
          <!-- <a class="btn btn-common mx-2" href="#footer">
            Contact Us <i class="fa fa-envelope ml-1"></i>
          </a> -->
          <button type="button" name="button" class="btn btn-common mx-2 go-to-bottom" >
            Contact Us <i class="fa fa-envelope ml-1"></i>
          </button>
        </li>

        <li class="nav-item">
          <a class="nav-link page-scroll" href="javascript:void(0);"><i class="fa fa-search search_icon"></i></a>
        </li>

      </ul>
    </div>
  </div>

  <!-- Mobile Menu Start -->
  <ul class="mobile-menu">
    <li class="nav-item submenu">
      <a class="page-scroll" routerLink="/home" routerLinkActive="active">Home</a>
    </li>
    <li class="nav-item submenu dropdown">
      <a class="page-scroll dropdown-toggle" href="#" id="navbarDropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Solutions &nbsp;
        <i class="fa fa-chevron-down"></i>
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
        <a class="dropdown-item" routerLink="/opscalestorage" routerLinkActive="active">Storage - OpScale</a>
        <a class="dropdown-item" routerLink="/opscalebackup" routerLinkActive="active">Backup - OpScale</a>
        <a class="dropdown-item" routerLink="/moaxy" routerLinkActive="active">Edge Compute - Moaxy</a>
        <a class="dropdown-item" routerLink="/taas" routerLinkActive="active">Managed Cloud - TaaS</a>
      </div>
    </li>

    <li class="nav-item submenu">
      <a class="page-scroll" routerLink="/resources" routerLinkActive="active">Resources</a>
    </li>

    <li class="nav-item">
      <a class="page-scroll" routerLink="/about-us" routerLinkActive="active">About Us</a>
    </li>

    <li class="nav-item">
      <button type="button" name="button" class="btn btn-common mx-2" data-toggle="modal" data-target="#exampleModal">
        Contact Us <i class="fa fa-envelope ml-1"></i>
      </button>
    </li>

  </ul>
  <!-- Mobile Menu End -->

</nav>
<!-- Navbar End -->

<!-- contact modal -->
<app-contact-modal></app-contact-modal>
