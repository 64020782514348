<header class="hero-area inner-page-header ptb-100" data-stellar-background-ratio="0.5">
<app-navbar></app-navbar>
<div class="container">
		<div class="row justify-content-center">
			<div class="col-md-8 col-lg-7">
				<div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
					<h1 class="text-white mb-0">What We Do</h1>
				</div>
			</div>
		</div>
	</div>
</header>
<div class="clearfix"></div>
<section class="inner__section">
	    <div class="container">
		  <div class="row">

			 <div class="col-md-12">
				 <div  class="service-topic">
					<h4>The Cloud. Our home turf.</h4>
					<p>
						Born in the Cloud Era, we are a team of  technology experts with hands-on
						experience in supporting organizations to build their Cloud & IT Infrastructure
						from the ground up.
				   </p>
				   <p>
						We specialize in providing intelligent technology services and solutions to a wide
						range of organizations - be it a startup or a company that has operations across
						the globe.<br/>We provide them with expertise-on-demand to design, build and
						maintain their technology platform – making it secure, scalable and manageable.

				   </p>
				 </div>
			 </div>

			 <div class="col-md-12">
				 <div  class="service-topic-1">
					<h4>We go the extra mile for you</h4>
					<p>
						As your tech partner, we give you the distinct advantage of a global footprint
						of highly reliable enterprise-class data centers in 200+ locations worldwide.
				   </p>
				   <p>
						Our team combines the best-in-class DevOps and IT infrastructure management
						practices, and provides a secure platform built-to-scale with your <br/>growing
						requirements, at an optimal cost.
				   </p>
				 </div>
						 <div class="content-banner">
						  <h4 class="r_mrg_top">How we support your business:</h4>
							   <div class="desc">
							   <ul>
							   <li>Designing & building a secure, high-performance hybrid cloud infrastructure</li>
							   <li>Managing infrastructure and DevOps</li>
							   <li>Building & managing multiple PoPs</li>
								<li>Providing cost-effective & efficient AI/ML compute solutions</li>
								 <li>Efficiently managing multi-office on premise compute requirements</li>
							   </ul>
							   </div>
					   </div>

			 </div>

		  </div>
		</div>

	</section>
<div class="clearfix"></div>
<app-footer></app-footer>
