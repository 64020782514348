<header class="hero-area inner-page-header ptb-c-100 main--header" data-stellar-background-ratio="0.5">
  <app-navbar></app-navbar>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-6">
        <div class="page-header-content text-white pt-sm-5 pt-md-5 pt-lg-0">
          <br><br>
          <h1 class="text-white mb-0">Careers</h1>
          <p class="pos_banner_txt pb-3 services-link">
            If you don’t see anything that fits your interest right now, reach out to us directly at
            <a href="mailto:careers@opsara.com">careers@opsara.com</a>.
            We’re always interested in speaking with talented people.
          </p>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="clearfix"></div>

<section class="inner__section">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="service-topic job_title">
          <h4>Open Job Positions</h4>

          <ng-container *ngFor="let item of roleName">
            <div class="col-md-12 job_list">
              <div class="row">
                <div class="col-md-2 brd-rght hide_xs" align="center"><img src="../assets/img/j.png" /></div>
                <div class="col-md-10 pad-lft-30">
                  <h3>{{item.role}}</h3>
                  <!-- <span class="joblist_date">{{item.Date}}</span> -->
                  <a class="btn btn-common mb-1 pull-right" (click)="jobList($event);" [id]="item.id">{{item.btn}}</a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</section>

<div class="clearfix"></div>
<app-footer></app-footer>
