<header class="hero-area inner-page-header moaxy ptb-c-100 main--header" data-stellar-background-ratio="0.5">
  <app-navbar></app-navbar>
  <div class="container">
    <div class="row justify-content-left">
      <div class="col-md-10 col-lg-10">
        <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
          <br><br><br>
          <h1 class="text-white mb-0 pt-3">Edge Compute - Moaxy</h1>
          <br><br>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="clearfix"></div>
<!---- Content Section ---->
<section class="inner__section">

  <section class="section Resources">
    <div class="container">
      <div class="row">
        <div class="col-6 col-xs-12" id="secure_network">
          <h5 class="has-text-blue custom-heading">Moaxy Edge Compute</h5>
          <div class="section-header mb-3">
            <hr class="lines wow zoomIn ml-0" data-wow-delay="0.3s">
          </div>

          <p class="section-subtitle mb-2">
            Simplify your edge compute infrastructure and management challenges.
          </p>

          <p class="section-subtitle">
            Moaxy provides edge compute, networking, and security services for your remote locations. It comes in 1U form factor and others as well.
          </p>

          <h5 class="topic_heading">Use Case</h5>
          <p class="section-subtitle mb-2">
            It is ideally suited for customers with low-latency or local data processing needs.
          </p>

          <p class="section-subtitle">
            Branch offices, retail stores, medical facilities, gas stations, restaurants are ideally suited for deploying Moaxy and reducing your management complexity.
          </p>

          <h5 class="topic_heading">Standard Configuration</h5>
          <p class="section-subtitle">
            Our standard configuration is designed with typical branch office workloads in mind. It includes functionalities like firewall, LDAP, DNS, DHCP, and file services.
          </p>

          <h5 class="topic_heading">Customizations</h5>
          <p class="section-subtitle">
            However, we can customize the configurations as needed in line with specific requirements during the sign-up phase. For example, we can provide GPU based hardware to meet your edge AI/ ML workloads.
          </p>

        </div>
        <div class="col-md-6 col-xs-12 pl-5">
          <img src="../assets/img/moaxy appliance.png" class="img-fluid mt-5 w-100"/>

          <h5 class="topic_heading mt-5">Deployment</h5>
          <p class="section-subtitle">
            We ship pre-configured Moaxy appliances to your remote locations. You only provide power and network connectivity to get started. Get locations up and running in days.
          </p>
          <p class="section-subtitle services-link">
            <a routerlink="/resources" href="#/resources">Check how our client</a>, Griddable used Moaxy to secure their global footprint.
          </p>
        </div>
      </div>
    </div>
  </section>


  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-sm-12">
          <img src="../assets/img/Moaxy Edge Inference.png" class="mt-4 img-fluid" />
        </div>
        <div class="col-md-5 col-sm-12 pl-5">
          <h5 class="has-text-blue custom-heading pt-4">Moaxy for Edge Inference</h5>
          <div class="section-header mb-3">
            <hr class="lines ml-0">
          </div>
          <h5 class="topic_heading">Use cases include :</h5>
          <p class="section-subtitle pb-2">
            Deploy Moaxy with GPU to enable your AI/ML applications to infer at the edge. Common use cases include generating actionable insights from video surveillance:-
          </p>
          <div class="service-sec-1 content-banner pt-0">
            <div class="desc">
              <ul>
                <li>Retail stores</li>
                <li>Parking garages</li>
                <li>Bank branches</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  <section class="section Resources">
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-sm-12 pr-5">
          <h5 class="has-text-blue custom-heading pt-4">Moaxy - Reduce WAN costs</h5>
          <div class="section-header mb-3">
            <hr class="lines ml-0">
          </div>

          <p class="section-subtitle pb-2">
            Moaxy with SD-WAN built-in is ideal to help optimize your WAN costs.
          </p>
          <p class="section-subtitle pb-2">
            For time sensitive and critical enterprise applications organizations rely on a MPLS network.  However this is an expensive and cumbersome legacy approach.
          </p>
          <p class="section-subtitle pb-2">
            Moaxy with SD-WAN, provides an option to use broadband internet connectivity. Reduce or eliminate costs associated with MPLS.
          </p>
        </div>
        <div class="col-md-7 col-sm-12">
          <img src="../assets/img/moaxy sdwan1.png" class="mt-4 img-fluid" />
        </div>
      </div>

    </div>
  </section>

  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-sm-9">
          <img src="../assets/img/Moaxy detail.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="../assets/img/moaxy picture.png" class="img-fluid" />
        </div>
        <div class="col-md-5 col-sm-12 pl-5">

          <div class="service-sec-1 content-banner pt-0">
            <div class="mt-5 pl-4">
              <ul>
                <li class="mb-4">Utility stack</li>
                <li class="mb-4">Optimization</li>
                <li class="mb-4">Performance</li>
                <li class="mb-4">Security</li>
                <li class="mb-4">Networking</li>
                <li class="mb-4">Visibility</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  <section class="section Resources">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <h5 class="has-text-blue custom-heading pt-4">Standard Functionalities :</h5>
          <div class="section-header mb-3">
            <hr class="lines ml-0">
          </div>
          <div class="service-sec-1 content-banner pt-0">
            <div class="desc">
              <ul>
                <li>Firewall</li>
                <li>LDAP</li>
                <li>DNS</li>
                <li>DHCP</li>
                <li>Basic Infrastructure Monitoring</li>
                <li>File Server</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <h5 class="has-text-blue custom-heading pt-4">Optional Functionalities :</h5>
          <div class="section-header mb-3">
            <hr class="lines ml-0">
          </div>
          <!-- <h5 class="topic_heading">Optional Functionalities :</h5> -->
          <div class="service-sec-1 content-banner pt-0">
            <div class="desc">
              <ul>
                <li>AI / ML compute</li>
                <li>OEM Network/ Security appliances</li>
                <li>Windows Servers / Applications</li>
                <li>3rd Party Applications hosting</li>
                <li>Data Backup Platforms</li>
                <li>Public Cloud Gateway </li>
                <li>Addon local storage</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  <div class="clearfix"></div>
</section>
<!---- Footer Section---->
<div class="clearfix"></div>
<app-footer></app-footer>
