import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
declare const loadAllData:any;
@Component({
  selector: 'app-whatwedo',
  templateUrl: './whatwedo.component.html',
  styleUrls: ['./whatwedo.component.css']
})
export class WhatwedoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    loadAllData();
    $(window).scrollTop(0); 
  }

  

}
